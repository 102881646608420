import { templateApi as api } from "../../services/Api";
import { toast } from "react-toastify";

export const getTemplates = async () => {
  try {
    const {
      data: { data },
    } = await api.getTemplates();

    return data;
  } catch (error) {
    toast.error(error.message);
    return [];
  }
};

export const getTemplateById = async (id) => {
  try {
    const {
      data: { data },
    } = await api.getTemplateById(id);

    return data;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};

export const createTemplate = async (post) => {
  try {
    const { data } = await api.createTemplate(post);

    if (data.message === "Success") {
      toast.success("Added Successfully!");
      return data;
    }
  } catch (error) {
    toast.error(error.message);
  }
  return null;
};

export const editTemplate = async (id, post) => {
  try {
    const { data } = await api.editTemplate(id, post);

    if (data.message === "Success") {
      toast.success("Updated Successfully!");
      return data;
    }
  } catch (error) {
    toast.error(error.message);
  }
  return null;
};

export const deleteTemplate = async (id) => {
  try {
    const { data } = await api.deleteTemplate(id);

    if (data.message === "Success") {
      toast.success("Deleted Successfully!");
      return data;
    }
  } catch (error) {
    toast.error(error.message);
  }
  return -1;
};
