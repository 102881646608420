import { malwareBazaarApi as api } from "../../services/Api";
import { toast } from "react-toastify";

export const getAllMalwareBazaar = async (page) => {
  try {
    const {
      data: data,
    } = await api.getAllMalwareBazaar(page);

    return data;
  } catch (error) {
    toast.error(error.message);
    return [];
  }
};

export const getAllMalwareBazaarbyAntiPhishing = async (id) => {
  try {
    const {
      data: data,
    } = await api.getAllMalwareBazaarbyAntiPhishing(id);

    return data;
  } catch (error) {
    toast.error(error.message);
    return [];
  }
};
