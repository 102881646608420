// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.html-custom-flex {
  flex: 1 1;
}

.html-button-flex {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/UI/HtmlParser/index.css"],"names":[],"mappings":"AAAA;EACE,SAAO;AACT;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,mBAAmB;AACrB","sourcesContent":[".html-custom-flex {\r\n  flex: 1;\r\n}\r\n\r\n.html-button-flex {\r\n  display: flex;\r\n  flex-direction: row;\r\n  gap: 15px;\r\n  align-items: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
