import { combineReducers } from 'redux';
import auth from './auth';
import email from './email';
import landing from './landing';
import smtp from './smtp';
import event from './event';
import dashboard from './dashboard';
import timeline from './timeline';
import audit from './audit';
import template from './template';
import leak from './leak';
import malwareBazaar from './malwareBazaar';
import inventory from './inventory';
import bruteForce from './bruteForce';
import attachment from './attachment';
import password from './auth'

export default combineReducers({
  authData: auth,
  emails: email,
  landings: landing,
  smtps: smtp,
  events: event,
  dashboardData: dashboard,
  timelineData: timeline,
  auditData: audit,
  templates: template,
  leaks: leak,
  malwareBazaars: malwareBazaar,
  inventories: inventory,
  bruteForces: bruteForce,
  attachments: attachment,
  passwords: password
});
