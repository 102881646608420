import React from "react";
import PropTypes from "prop-types";

const CustomFormErrorMessage = ({ name, errors }) => {
  return errors[name] ? (
    <small className="p-error">{errors[name].message}</small>
  ) : (
    ""
  );
};

CustomFormErrorMessage.propTypes = {
  name: PropTypes.string,
  errors: PropTypes.object,
};

CustomFormErrorMessage.defaultProps = {
  name: "",
  errors: {},
};

export default CustomFormErrorMessage;
