import React from 'react';
import { useHistory } from 'react-router-dom';
import AppMenu from './AppMenu';
import { classNames } from 'primereact/utils';
import { useDispatch } from 'react-redux';
import { authActionTypes } from './store/actionTypes';

const AppTopbar = (props) => {
  const dispatch = useDispatch();

  const onTopbarSubItemClick = (event) => {
    event.preventDefault();
  };

  const logout = () => {
    dispatch({ type: authActionTypes.LOGOUT });
    history.push('/signIn');
  };

  const setting = () => {
    history.push('/settings');
  }

  const history = useHistory();

  return (
    <>
      <div className='layout-topbar'>
        <div className='layout-topbar-left'>
          <button className='topbar-menu-button p-link' onClick={props.onMenuButtonClick}>
            <i className='pi pi-bars'></i>
          </button>

          

          
        </div>

        <AppMenu
          model={props.items}
          menuMode={props.menuMode}
          colorScheme={props.colorScheme}
          menuActive={props.menuActive}
          activeInlineProfile={props.activeInlineProfile}
          onSidebarMouseOver={props.onSidebarMouseOver}
          onSidebarMouseLeave={props.onSidebarMouseLeave}
          toggleMenu={props.onToggleMenu}
          onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
          onMenuClick={props.onMenuClick}
          onRootMenuItemClick={props.onRootMenuItemClick}
          onMenuItemClick={props.onMenuItemClick}
        />

        <div className='layout-topbar-right'>
          <ul className='layout-topbar-right-items'>
            <li
              id='profile'
              className={classNames('profile-item', {
                'active-topmenuitem': props.topbarMenuActive,
              })}
            >
              {/* <button
                //  style={{ backgroundColor: "#b9b9c8"}}
                className='p-link -mr-1' onClick={props.onTopbarItemClick} >
                <i style={{ fontSize: "24px", alignHeader: "center", color: "#44486D"}} className='pi pi-palette '></i>

                 <img src='assets/layout/images/dashboard/kaylynn.png' alt='profile' /> 
              </button> */}
              <button
                //  style={{ backgroundColor: "#b9b9c8"}}
                className='p-link -mr-1' onClick={props.onTopbarItemClick} >
                <i style={{ fontSize: "24px", alignHeader: "center", color: "#28da37" }} className='pi pi-fw pi-user '></i>

                {/* <img src='assets/layout/images/dashboard/kaylynn.png' alt='profile' /> */}
              </button>

              <ul className='fadeInDown border-solid shadow-6 surface-border'>
                
                <li role='menuitem'>
                  <button className='p-link' onClick={logout}>
                    <i className='pi pi-fw pi-power-off '></i>
                    <span>Logout</span>
                  </button>
                </li>
              </ul>
            </li>
            <li
              id="notification"
              className={classNames('profile-item', {
                'active-topmenuitem': props.topbarNotificationMenuActive,
              })}
            >
              {/* bildirim zili */}
              {/* <button className='p-link' onClick={props.onTopbarNotificationClick}>
                <i className='topbar-icon pi pi-fw pi-bell'></i>
                <span className='topbar-badge'>1</span>
                <span className='topbar-item-name'>Notifications</span>
              </button>

              <ul className='fadeInDown border-solid shadow-6 surface-border' style={{minWidth: '25rem'}}>
                <li role='menuitem' className='p-3'>
                  <div className='flex flex-column'>
                    <div className='flex align-item-center justify-content-between mb-1'>
                      <span className='text-base font-bold'>Vulnerability</span>
                      <small>1 day ago</small>
                    </div>
                    <span className='text-sm'>
                      CVE-2022-21846 - Microsoft Exchange Server Remote Code Execution
                    </span>
                  </div>
                </li>
              </ul> */}
            </li>
            {/*<li>
              <button className='p-link'>
                <i className='topbar-icon pi pi-fw pi-comment'></i>
                <span className='topbar-badge'>5</span>
                <span className='topbar-item-name'>Messages</span>
              </button>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default AppTopbar;
