import { defaultActionTypes, attachmentActionTypes } from '../actionTypes';

const initialState = { isAttachmentLoading: true, attachments: [] };

const reducers = (state = { ...initialState }, action) => {
  switch (action.type) {
    case defaultActionTypes.START_LOADING:
      return { ...state, isAttachmentLoading: true };
    case defaultActionTypes.END_LOADING:
      return { ...state, isAttachmentLoading: false };

    case attachmentActionTypes.GET_ALL_ATTACHMENT_DATA:
      return {
        ...state,
        attachments: action.payload,
      };
    default:
      return state;
  }
};

export default reducers;
