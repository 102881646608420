import React, { useState, useEffect } from "react";
import axios from 'axios';
import './reporting.css'


// Örnek ülke verileri

const CountryList = ({ veri }) => {
    const [countriesData, setCountriesData] = useState([]);
    const [topCountries, setTopCountries] = useState([]);

    useEffect(() => {
        axios.get('/country.json')
            .then((response) => {
                setCountriesData(response.data.objects.world.geometries);
            })
            .catch((error) => {
                console.error("Error fetching the data", error);
            });
        setTopCountries(veri?.top_countries)
    }, []);
    return (
        <div style={{ padding: "5px", maxWidth: "400px", margin: "0 auto" ,height:'280px' }}>
            {/* Başlık */}
            <h6 className="title" >
                Countries with the most attacks
            </h6>


            {topCountries?.map((country, index) => {
                if (!country.geolocation__countryCode) {
                    return null;
                }

                const ulkeKod = country.geolocation__countryCode.toLowerCase();
                const countryCode = country.geolocation__countryCode;
                const countryInfo = countriesData.find(item => item.id === countryCode); // JSON dosyasından ülke bilgilerini alıyoruz
                const countryName = countryInfo ? countryInfo.properties.name : "Unknown Country";
                const flagPath = `/assets/layout/images/flags/${ulkeKod}.png`; // Bayrak yolunu ülke kodu ile oluşturun

                return (
                    <div
                        key={index}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "3px",
                            padding: "7px",
                            borderRadius: "8px",
                            backgroundColor: "#f9f9f9",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            width: "100%",
                            transition: "transform 0.3s, box-shadow 0.3s",
                            cursor: "pointer",
                            ":hover": {
                                transform: "translateY(-3px)",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                            }
                        }}
                    >
                        {/* Bayrak Görüntüsü */}
                        <img
                            src={flagPath}
                            alt={`${countryName} flag`}
                            style={{
                                width: "36px",
                                height: "22px",
                                marginRight: "10px",
                                borderRadius: "3px",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                            }}
                        />

                        {/* Ülke Adı */}
                        <div style={{ flex: 1 }}>
                            <strong style={{ fontSize: "12px", color: "#333" }}>{countryName}</strong>
                        </div>

                        {/* Atak Sayısı */}
                        <div>
                            <span style={{ fontSize: "12px", color: "#666" }}>
                                {country.attack_count} attack
                            </span>
                        </div>
                    </div>
                );
            })}

            {/* Açıklama */}
            <p style={{ textAlign: "left", marginTop: "10px", color: "#666", fontSize: "11px" }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.            </p>
        </div>
    );
};

export default CountryList;
