import api from "../../api";

export const getSpf = () => api.get("/mail-settings-spf");
export const insertSpf = (model) =>
    api.post(`/mail-settings-spf`, model, {
        headers: {
            "Content-Type": "application/json",
        },
    });

export const updateSpf = (id) =>
    api.put(`/mail-settings-spf/${id}`,{
        headers: {
            "Content-Type": "application/json",
        },
    });
