import React, { useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import RaporDeneme from './RaporDeneme';
import { useDispatch } from "react-redux";
import { getReportData } from '../../store/actions/reporting';
import { getData } from '../../store/actions/dashboard';

function ReportingPage() {

    const [dates, setDates] = useState({ start_date: null, end_date: null });
    const [reportData, setReportData] = useState({ start_date: null, end_date: null });
    const [queryResult, setQueryResult] = useState([]);
    const [verdict, setVerdict] = useState('');
    const [type, setType] = useState('');
    const [raporVeri, setRaporVeri] = useState(null);
    const [dashboardVeri, setDashboardVeri] = useState(null);
    const [success, setSuccess] = useState(false);

    const dispatch = useDispatch();



    const verdicts = [
        { label: 'Spam', value: 'spam' },
        { label: 'Phishing', value: 'phishing' },
        { label: 'Malicious', value: 'malware' },
        { label: 'Suspicios', value: 'suspicious' },
        { label: 'Content Filter', value: 'content' },
        { label: 'Domain Filter', value: 'domain' },
        { label: 'Attachmetn Filter', value: 'attachment' },
    ];
    const types = [
        { label: 'Mail', value: 'mail' },
        { label: 'File', value: 'file' },
        { label: 'Domain', value: 'domain' },
        { label: 'Url', value: 'url' }

    ];
    const onDateChange = (e) => {
        let date = e.target.value
        let tarih = null;
        const day = date.getDate();
        const dayLast = date.getDate() + 1;
        const month = date.getMonth() + 1; // Aylar 0'dan başlar, bu yüzden 1 ekliyoruz
        const year = date.getFullYear();
        console.log("e.target.name",e.target.name,dayLast);
        tarih = (e.target.name === 'end_date' ? `${dayLast}.${month}.${year}` : `${day}.${month}.${year}`);

        setDates({ ...dates, [e.target.name]: e.target.value });
        setReportData({ ...reportData, [e.target.name]: tarih })
    };

    const onVerdictChange = (e) => {
        setVerdict(e.value);
    };

    const onUpload = (e) => {
        console.log('Uploaded:', e.files);
    };

    const createReport = () => {
        dispatch(getReportData(reportData)).then((res) => {
            console.log("res241", res);
            setRaporVeri(res)
            setSuccess(true)
        })
        dispatch(getData(reportData)).then((res) => {
            console.log("resDash", res?.data.data);
            setDashboardVeri(res.data.data)
        })
    };

    return (
        <>
            <div className="card">
                <div className="p-col-12">
                    <h6>Reporting Service</h6>

                    <div style={{ border: '2px solid', borderRadius: '10px' }} className="flex justify-content-start ">
                        <div style={{ width: '400px' }} className="card flex flex-column gap-2">
                            <label htmlFor="startDate">Start Date <i style={{ color: 'red' }}>*</i></label>
                            <Calendar dateFormat='dd/mm/yy' id="start_date" name="start_date" value={dates.startDate} onChange={onDateChange} showButtonBar />
                            <small id="username-help">
                                Enter the starting date of report.
                            </small>
                        </div>
                        <div style={{ width: '400px' }} className="card flex flex-column gap-2">
                            <label htmlFor="endDate">End Date <i style={{ color: 'red' }}>*</i> </label>
                            <Calendar dateFormat='dd/mm/yy' id="end_date" name="end_date" value={dates.endDate} onChange={onDateChange} showButtonBar />
                            <small id="username-help">
                                Enter the last date of report.
                            </small>
                        </div>
                        {/* <div className="card flex flex-column gap-2">
                            <label htmlFor="verdict">Verdict <i style={{ color: 'red' }}>*</i></label>
                            <MultiSelect value={verdict} onChange={(e) => setVerdict(e.value)} options={verdicts} optionLabel="label" display="chip"
                                placeholder="Select Verdict" className="w-full md:w-20rem p-multiselect-item" />
                            <small id="username-help">
                                Select verdict.
                            </small>
                        </div>
                        <div className="card flex flex-column gap-2">
                            <label htmlFor="verdict">Service Type (Optional)</label>
                            <MultiSelect value={type} onChange={(e) => setType(e.value)} options={types} optionLabel="label" display="chip"
                                placeholder="Select Type" className="w-full md:w-20rem p-multiselect-item" />
                            <small id="username-help">
                                Select type.
                            </small>
                        </div> */}

                        {/* <div className="card flex flex-column gap-2">
                        <label htmlFor="logo">Logo Yükle</label>
                        <FileUpload name="logo" auto mode="basic" accept="image/*" maxFileSize={1000000} onUpload={onUpload} />
                    </div> */}
                        <div style={{ width: '300px' }} className="card flex flex-column gap-2">
                            <label>&zwnj;</label>
                            <Button label="Create Report" onClick={createReport} className="p-button-outlined " />

                        </div>

                    </div>
                </div>

            </div>
            <div className="card">
                <h6>Report List</h6>
                {
                    success && <RaporDeneme veri={raporVeri} dashboardVeri={dashboardVeri} reportData={reportData} />
                }

                <div style={{ border: '2px solid', borderRadius: '10px' }} className="p-col-12 mt-2">
                    <DataTable
                        value={queryResult}
                        tableStyle={{ minWidth: '%100', minHeight: '10rem' }}
                        emptyMessage='There is no report.'
                    >
                        <Column field="date" header="Report Date"></Column>
                        <Column field="type" header="Report Type"></Column>
                        <Column field="score" header="Score"></Column>
                        <Column field="report" header="Report"></Column>
                    </DataTable>
                </div>
            </div>
        </>
    );
}

export default ReportingPage;
