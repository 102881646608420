import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  getAllBruteForce,
  startBruteForce,
} from "../../store/actions/bruteForce";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import Loading from "../UI/Loading";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { createBruteForce } from "../../services/Api/bruteForce";
import CustomInputText from "../UI/CustomInputs/CustomInputText";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { emailPattern } from "../../constants/regex";
import { Badge } from "primereact/badge";
import { Toolbar } from "primereact/toolbar";
import ExcelExport from "../ExcelExport";
import { Sidebar } from 'primereact/sidebar';
import './BruteForce.css'
import { setRef } from "@mui/material";
import { Toast } from 'primereact/toast';

const BruteForce = () => {
  const [refetchInterval, setRefetchInterval] = useState(600000);
  const [turkce, setTurkce] = useState(true);
  const [items, setItems] = useState([]);
  const [selectItems, setSelectItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [visibleHelp, setVisibleHelp] = useState(false)
  const [createItem, setCreateItem] = useState(false)
  const toast = useRef(null);
  const dispatch = useDispatch();
  const dt = useRef(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const { isLoading, data } = useQuery("bruteforce", () => getAllBruteForce(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchInterval,
    onError: () => setRefetchInterval(0),
  });


  useEffect(() => {
    if (refetchInterval === 1000) {
      setRefetchInterval(30000)
    }
    let newData = [];
    data?.map((dt) => {
      let _data = { ...dt, resultPass: "*****************" }
      newData.push(_data)
    })
    setItems(newData);
    return () => { };
  }, [data, selectedItems]);


  useEffect(() => {
    if (selectedItems.length > 0) {
      selectedItems.forEach((select) => {
        let rowItem = items.find(item => item.id === select.id)
        rowItem.resultPass = rowItem.result
        setSelectItems(items)
      })
    } else {
      let newData = [];
      data?.map((dt) => {
        let _data = { ...dt, resultPass: "****************" }
        newData.push(_data)
      })
      setItems(newData);
      setSelectItems([])
      return () => { };
    }
  }, [selectedItems])

  const [newItemDialog, setNewItemDialog] = useState(false);

  const toggleNewItemDialog = () => {
    setNewItemDialog(!newItemDialog);
  };

  const formDefaults = {
    email_address: "",
  };

  const handleClose = (event, reason) => {
    // if (reason && reason == "backdropClick" && "escapeKeyDown")
    //   return;
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm(formDefaults);

  const OnSubmit = async (values, e) => {
    e.preventDefault();

    let _items = [...items];

    const result = await createBruteForce({ ...values });
    if (result !== null) {
      // _items.push({ id: result.id, status: "New", ...values });
      setCreateItem(true)
      toggleNewItemDialog();
      setRefetchInterval(1000)
    }

    setItems(_items);
    toast.current.show({ severity: 'info', summary: 'İnfo', detail: 'Select the record with new status in the list and start the start process.', life: 3000 });

  };

  const selectItemList = (e) => {
    setSelectedItems(e.value)
  }

  const renderHeader = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Brute Force Results</h5>
        <div>
          <span className="mt-2 md:mt-0 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => {
                const value = e.target.value;
                let _filters = { ...filters };
                _filters["global"].value = value;

                setFilters(_filters);
                setGlobalFilterValue(value);
              }}
              value={globalFilterValue}
              placeholder="Search..."
            />
          </span>
          <Button
            label="Export"
            icon="pi pi-download"
            className="ml-3"
            onClick={() => dt.current.exportCSV()}
          />
        </div>
      </div>
    );
  };


  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status</span>


        <Badge
          value={rowData.status}
          style={{ backgroundColor: "#44486D" }}
        />
      </>
    );
  };
  const resultBodyTemplate = (rowData) => {

    return (
      <>

        {
          rowData.result === "NOT FOUNDED" || rowData.result === "-" ?
            (
              <>
                <Badge
                  value={rowData.result}
                  style={{ backgroundColor: "#fff7ab", color: "black" }}
                />
              </>
            ) : (
              <>
                <Badge
                  value={rowData.resultPass}
                  style={{ backgroundColor: "#7625c2" }}
                />
              </>
            )
        }
      </>
    )
  };

  const StartBruteForce = () => {
    const emails = selectedItems.map((mail) => mail.id);
    // const resp =await dispatch(startBruteForce({ email_address: emails }));
   startBruteForce({ email_address: emails })

    // console.log("resp233",resp);
    setRefetchInterval(selectedItems.length * 1000)
    setSelectedItems([])
    setItems((its) =>
      its.map((val) => {
        if (emails.includes(val.id)) {
          val.status = "Runnig";
          return val;
        } else {
          return val;
        }
      })
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  const customIcons = (
    <React.Fragment>
      <Button
        className="p-button-rounded p-button-outlined p-button-sm mr-2"
        style={{ fontSize: "10px", color: "#44486D" }}
        label={turkce ? "EN" : "TR"}
        onClick={() => setTurkce(!turkce)}
      />
    </React.Fragment>
  );

  const bruteForceClass = (rowData) => {
    return {

      ' scalein animation-duration-1000 animation-iteration-infinite': rowData.status === "NEW"
    }
  }
  return (
    <>
      <div id="btn">
        <Toast ref={toast} />

        <Toolbar
          className="mb-4 "
          left={() => (
            <>
              <div className="flex ">
                <Button
                  label="New"
                  icon="pi pi-plus"
                  className="p-button mr-2"
                  style={{ backgroundColor: "#44486D", borderColor: "#44486D" }}
                  onClick={() => toggleNewItemDialog()}
                />
                {
                  selectedItems.length > 0 && (
                    <Button
                      label="Start"
                      icon="pi pi-play"
                      className="p-button mr-2"
                      onClick={StartBruteForce}
                      style={{ backgroundColor: "#d77b02", borderColor: "#d77b02" }}
                    ></Button>
                  )}
              </div>
            </>
          )}
          right={() => (
            <>
              <Button
                icon="pi pi-question"
                className="p-button-rounded p-button-outlined"
                style={{ color: "#44486D", borderColor: "#44486D" }}
                onClick={() => setVisibleHelp(true)}
              ></Button>
            </>
          )}
        ></Toolbar>
        <DataTable
          ref={dt}
          header={() => renderHeader()}
          value={selectItems.length > 0 ? selectItems : items}
          selection={selectedItems}
          onSelectionChange={(e) => selectItemList(e)}
          dataKey="id"
          paginator
          stripedRows
          rows={10}
          rowsPerPageOptions={[10,25,50]}
          className="datatable-responsive"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="{first} - {last} of {totalRecords}"
          emptyMessage="No data found."
          responsiveLayout="scroll"
          filters={filters}
          rowClassName={bruteForceClass}
        >
          <Column
            selectionMode="multiple"
          ></Column>
          <Column
            field="email_address"
            header="Mail Address"
            sortable
          ></Column>
          <Column
            field="start_time"
            header="Start Time"
            sortable
          ></Column>
          <Column
            field="finish_time"
            header="Finish Time"
            sortable
          ></Column>
          <Column
            // field="resultPass"
            header="Result"
            body={resultBodyTemplate}
            sortable
          ></Column>
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            sortable


          ></Column>
          {/* <Column body={(rowData) => {
      return <span className={rowData.status === "New" ? "scalein animation-duration-1000 animation-iteration-infinite flex align-items-center justify-content-center" : null}>
        
      </span>
  }} 
  /> */}
        </DataTable>

        <Dialog
          visible={newItemDialog}
          style={{ width: "450px" }}
          className="p-fluid"
          header="Details"
          onHide={toggleNewItemDialog}
          modal
          dismissableMask
        >
          <form onSubmit={handleSubmit(OnSubmit)}>
            <CustomInputText
              title="Email*"
              name="email_address"
              rules={{
                required: "required",
                pattern: {
                  value: emailPattern,
                  message: "Invalid email address. E.g. example@email.com",
                },
              }}
              properties={{ autoFocus: true }}
              control={control}
              errors={errors}
            />
            <Button label="Add To List" className="mt-2" type="submit" style={{ backgroundColor: "#44486D" }} />
          </form>
        </Dialog>
      </div>
      <Sidebar
        className="p-sidebar-sm"
        visible={visibleHelp}
        position="right"
        icons={customIcons}
        onHide={() => setVisibleHelp(false)}>
        {
          !turkce ? (
            <>
              <h3 style={{ fontSize: "24px" }}>Brute Force Yardım</h3>
              <ol className="m-0" style={{ lineHeight: "1.6" }}>
                <li>Şirketinizdeki kullanıcı maillerini tarar ve <b>güvenliksiz parolaları</b> size detayları ile gösterir.</li>
                <li>E-postaları tek tıkla içe aktarabilirsiniz, kuruluşunuz Outlook kullanıyorsa bu işlem çok kolay, sadece simgeye tıklamanız yeterli.<b>(önceden kullanıcı bilgilerini tanımlamayı unutmayın)</b></li>
                <li>Listeden bir mail seçin ve sol üst köşede açılan start  <Button className="p-button-raised" style={{ backgroundColor: "#d77b02", borderColor: "#d77b02" }}
                  icon="pi pi-play" label="Start" /> butonuna tıklayın.</li>
              </ol>
            </>
          ) : (<>
            <h3 style={{ fontSize: "24px" }}>Brute Force Help</h3>
            <ol className="m-0" style={{ lineHeight: "1.6" }}>
              <li>xGuard scans and analyzes users' emails and displays the <b>unsafe password</b> with details.</li>
              <li>You can import emails inside with one-click, if your organization using Outlook, this process so easy just click icon.<b>(don't forget to define user information before)</b></li>
              <li>Select an email from the list and click the start button <Button className="p-button-raised" style={{ backgroundColor: "#d77b02", borderColor: "#d77b02" }}
                icon="pi pi-play" label="Start" /> in the upper left corner.</li>
            </ol>
          </>)
        }

      </Sidebar>
    </>
  );
};

export default BruteForce;
