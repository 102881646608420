import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "../../store/actions/dashboard";
import { LinearProgress, Container } from "@mui/material";

import { ToastContainer } from 'react-toastify';
import { Tooltip } from 'primereact/tooltip';

import '../Setting/settingStyle.css'

import DashboardCard from "./DashboardCard";
import SecurityEvents from "./SecurityEvents";
import Loading from "../UI/Loading";
import EarthReactSimple from "./EarthReactSimple"
import LineChart from "./LineChart";
import './dashboard.css'
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import UserActionLogs from "./UserActionLogs";

const Dashboard = ({ dashboardType, setDashboardType, visible, setVisible }) => {

  const { authData: { authData } } = useSelector((state) => state);
  const [eartData, setEartData] = useState([])
  const [loading, setLoading] = useState(true)
  const [securityEventsData, setSecurityEventData] = useState([])
  const [tarihAralik, setTarihAralik] = useState(null);
  const dispatch = useDispatch();

  function getPastDates() {
    const today = new Date();

    // Bugünün tarihi
    const todayDate = formatDate(today);
    //Yarın
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const tomorrowDate = formatDate(tomorrow);

    // 1 hafta öncesinin tarihi
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 6);
    const oneWeekAgoDate = formatDate(oneWeekAgo);

    // 1 ay öncesinin tarihi
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    const oneMonthAgoDate = formatDate(oneMonthAgo);

    // 1 yıl öncesinin tarihi
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    const oneYearAgoDate = formatDate(oneYearAgo);

    return {
      today: todayDate,
      tomorrow: tomorrowDate,
      oneWeekAgo: oneWeekAgoDate,
      oneMonthAgo: oneMonthAgoDate,
      oneYearAgo: oneYearAgoDate
    };
  }

  function formatDate(date) {
    const day = date.getDate();
    const dayLast = date.getDate() + 1;
    const month = date.getMonth() + 1; // Aylar 0'dan başlar, bu yüzden 1 ekliyoruz
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  const dates = getPastDates();


  useEffect(() => {
    if (authData !== null) {

      let modelDay = {
        start_date: dates.today,
        end_date: dates.tomorrow
      }
      let modelWeek = {
        start_date: dates.oneWeekAgo,
        end_date: dates.tomorrow
      }
      let modelMonth = {
        start_date: dates.oneMonthAgo,
        end_date: dates.tomorrow
      }

      if (dashboardType === 0) {
        dispatch(getData(modelDay));
        setTarihAralik(modelDay)
      } else if (dashboardType === 1) {
        console.log("modelWeek", modelWeek);
        dispatch(getData(modelWeek));
        setTarihAralik(modelWeek)
      } else {
        dispatch(getData(modelMonth));
        setTarihAralik(modelMonth)
      }
    }
  }, [authData, dispatch, dashboardType]);

  useEffect(() => {
    if (eartData.length === 0) {
      setLoading(true)
      console.log("11loading true");

    }
    console.log("11loading false");
    setLoading(false)
  }, [eartData])


  const { dashboardData: { dashboardData } } = useSelector((state) => state);
  const { isDashboardLoading } = useSelector((state) => state.dashboardData);

  console.log("dashboardDatadashboardData 242", dashboardData);

  useEffect(() => {
    if (dashboardData?.eml_analysis) {
      setSecurityEventData(dashboardData?.eml_analysis)
    }
  }, [dashboardData])

  useEffect(() => {
    if (Object.keys(dashboardData).length !== 0) {
      let veri = dashboardData?.email_analysis_count?.country;
      const threatData = [];
      for (const country in veri) {
        let maxVerdict = '';
        let maxValue = -1;

        for (const verdict in veri[country]) {
          const formattedVerdict = verdict.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
          if (verdict !== 'count' && veri[country][verdict] > maxValue) {
            maxValue = veri[country][verdict];
            maxVerdict = formattedVerdict;
          }

          if (verdict === 'no_threat_found' && veri[country][verdict] === veri[country]['count']) {
            maxVerdict = 'No Threat Found';
          }
        }

        if (maxVerdict) {
          const newEntry = {
            Country: country.charAt(0).toUpperCase() + country.slice(1),
            Verdict: maxVerdict
          };

          for (const key in veri[country]) {
            const formattedKey = key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
            newEntry[formattedKey] = veri[country][key];
          }

          threatData.push(newEntry);
        }
      }
      console.log("threatData", threatData);
      setEartData(threatData);
    }
  }, [dashboardData]);




  if (isDashboardLoading)
    return (
      <Container maxWidth="xl" sx={{ margin: 5 }}>
        <center>
          <LinearProgress color="error" />
        </center>
      </Container>
    );


  const totals = {};

  if (dashboardData && dashboardData?.email_analysis_count) {
    for (const dayDataKey in dashboardData?.email_analysis_count) {
      const dayData = dashboardData?.email_analysis_count[dayDataKey];

      if (dayData && typeof dayData === 'object') {
        for (const key in dayData) {
          const value = dayData[key];
          if (totals[key]) {
            totals[key] += value;
          } else {
            totals[key] = value;
          }
        }
      }
    }
  }

  const totalsFileAnalysis = {};

  if (dashboardData && dashboardData.file_analysis_count) {
    for (const dayDataKey in dashboardData.file_analysis_count) {
      const dayData = dashboardData.file_analysis_count[dayDataKey];

      if (dayData && typeof dayData === 'object') {
        for (const key in dayData) {
          const value = dayData[key];
          if (totalsFileAnalysis[key]) {
            totalsFileAnalysis[key] += value;
          } else {
            totalsFileAnalysis[key] = value;
          }
        }
      }
    }
  }


  return (
    <>
      <ToastContainer position="top-right" autoClose={2000} />
      <Tooltip target=".tooltip-target" position="top" />


      <div className="grid layout-dashboard">
        <div className="col-12 md:col-3">
          <DashboardCard title="Phishing" total={totals?.phishing || 0} completion="100" icon='pi pi-shield' />
        </div>
        <div className="col-12 md:col-3">
          <DashboardCard title="Malware" total={totalsFileAnalysis?.malicious || 0} completion="100" icon='pi pi-prime' />
        </div>
        <div className="col-12 md:col-3">
          <DashboardCard title="Spam" total={totals?.spam || 0} completion="100" icon='pi pi-ban' />
        </div>
        <div className="col-12 md:col-3">
          <DashboardCard title="Content Filter" total={totals?.content_words_in_blacklist || 0} completion="100" icon='pi pi-inbox' />
        </div>

        {
          dashboardData?.email_analysis_count && (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1px' }}>
              <div style={{ flex: '1 1 45%', maxHeight: '100%' }} className="col-12 md:col-6 m-0">
                <SecurityEvents dashboardData={securityEventsData} />
              </div>

              <div style={{ flex: '1 1 45%', maxHeight: '100%' }} className="col-12 md:col-6">
                <UserActionLogs />
              </div>

              <div style={{ flex: '1 1 45%', maxHeight: '100%' }} className="col-12 md:col-6">
                {
                  loading ? (
                    <>
                      <Loading />
                    </>
                  ) : (
                    <>
                      <EarthReactSimple threatData={eartData} />
                    </>
                  )
                }
              </div>

              <div style={{ flex: '1 1 45%', maxHeight: '100%' }} className="col-12 md:col-6">
                <LineChart dashboardData={dashboardData} tarihAralik={tarihAralik} dashboardType={dashboardType} />
              </div>
            </div>

          )
        }

      </div >
      <Dialog
        visible={visible}
        className="layout-config"
        onHide={() => setVisible(false)}
        modal
        dismissableMask
      >

        <h5>Dashboard Type</h5>
        <p className="dashboardPragraph">
          You can use the buttons to edit the statistical data on the Dashboard on a daily, weekly or monthly basis.
        </p>
        <hr />
        <div className='flex align-items-center'>
          <Button
            disabled={dashboardType === 0} style={{ borderRadius: '4px', width: '155px' }}
            icon='pi pi-calendar' iconPos="left" label='Daily'
            className="layout-rightmenu-button"
            onClick={() => setDashboardType(0)}
          />

        </div>
        <div className='flex align-items-center'>
          <Button
            disabled={dashboardType === 1} style={{ borderRadius: '4px', width: '155px' }}
            icon='pi pi-calendar' iconPos="left" label='Weekly'
            className="layout-rightmenu-button mt-2 mb-2"
            onClick={() => setDashboardType(1)}
          />

        </div>
        <div className='flex align-items-center'>
          <Button
            disabled={dashboardType === 2}
            style={{ borderRadius: '4px', width: '155px' }} icon='pi pi-calendar'
            iconPos="left" label='Monthly' className="layout-rightmenu-button"
            onClick={() => setDashboardType(2)}
          />
        </div>

      </Dialog>

    </>
  );
};

export default Dashboard;
