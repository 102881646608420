import { defaultActionTypes, malwareBazaarActionTypes } from '../actionTypes';

const initialState = { isMalwareBazaarLoading: true, malwareBazaars: [] };

const reducers = (state = { ...initialState }, action) => {
  switch (action.type) {
    case defaultActionTypes.START_LOADING:
      return { ...state, isMalwareBazaarLoading: true };
    case defaultActionTypes.END_LOADING:
      return { ...state, isMalwareBazaarLoading: false };

    case malwareBazaarActionTypes.GET_ALL_MALWARE_BAZAAR_DATA:
      return {
        ...state,
        malwareBazaars: action.payload,
      };
    default:
      return state;
  }
};

export default reducers;
