import React, { useState } from 'react'
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import { Button } from "primereact/button";
import './MailEditor.css';
import Attachment from './MailAnalysisFolder/Attachment';
import SenderDomain from './MailAnalysisFolder/SenderDomain';
import Url from './MailAnalysisFolder/Url';
import EmailContent from './MailAnalysisFolder/EmailContent';
import { Message } from 'primereact/message';
import { Dialog } from 'primereact/dialog';


const AntiPhishingTimeLine = ({ data }) => {

    const [code, setCode] = useState();
    const [items, setItems] = useState('');
    const [showDialog, setShowDialog] = useState(false);


    const events = [
        { code: 1, status: 'Attachment', date: '15/10/2020 10:30', icon: 'pi pi-file', color: '#ef0000' },
        { code: 2, status: 'Sender Domain', date: '15/10/2020 14:00', icon: 'pi pi-cloud', color: '#673AB7' },
        { code: 3, status: 'Url Analyzer', date: '15/10/2020 16:15', icon: 'pi pi-bolt', color: '#FF9800' },
        { code: 4, status: 'Email Content', date: '16/10/2020 10:00', icon: 'pi pi-envelope', color: '#607D8B' }
    ];

    const customizedMarker = (item) => {

        return (
            <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" style={{ backgroundColor: item.color }}>
                <i className={item.icon}></i>
            </span>
        );
    };


    const getCardClassName = (item, data) => {
        if (item.code === 1) {
            if (data?.attachment_verdict === 'malicious' ||
                data?.verdict === 'attachment_extension_in_blacklist' ||
                data?.verdict === "attachment_bigger_than_max_size") {
                return 'card-timeline-zararli';
            }
        }

        if (item.code === 2) {
            if (data?.sender_domain_verdict === 'malicious' || data?.sender_domain_verdict === 'spam') {
                return 'card-timeline-zararli';
            } else if (data?.sender_domain_verdict === 'suspicious') {
                return 'card-timeline-tehlikeli';
            }
        }

        if (item.code === 3) {
            if (data?.urls_verdict === 'malicious') {
                return 'card-timeline-zararli';
            } else if (data?.urls_verdict === 'suspicious') {
                return 'card-timeline-tehlikeli';
            }
        }

        if (item.code === 4) {
            if (data?.verdict === 'content_words_in_blacklist' || data?.message?.spam_score > 5) {
                return 'card-timeline-zararli';
            }
        }

        return 'card-timeline-zararsiz'; // Eğer hiçbir koşul sağlanmazsa
    };

    const handleItemClick = (item) => {
        if (item.code === 4) {
            setShowDialog(true);
            setItems(item.code)
        } else {
            setCode(item.code);
        }
    };

    const handleDialogHide = () => {
        setShowDialog(false);
    };

    const handleConfirm = () => {
        setCode(items);
        setShowDialog(false);
    };

    
    const customizedContent = (item) => {
        return (
            <div className='card'>
                <Card
                    key={item.code}
                    className={getCardClassName(item, data)}
                    title={item.status}
                >
                    {
                        item.code === 4 && data?.message?.spam_score > 5 &&
                        <>
                            <Message className='mr-2' severity="success" text="Spam Checker Caught" />
                        </>
                    }
                    {
                        item.code === 1 && data?.verdict === "attachment_bigger_than_max_size" &&
                        <>
                            <Message className='mr-2' severity="error" text="Higher than the maximum file size " />
                        </>
                    }
                    {
                        item.code === 1 && data?.verdict === "attachment_bigger_than_max_size" ? (
                            <>
                            </>
                        ) : (
                            <>
                                <Button
                                    key={item.code}
                                    size="small"
                                    label="Read more"
                                    // className="p-button-text "
                                    className={
                                        item.code === 1 && data?.attachment_verdict === 'malicious' ? 'readMoreZararli ' :
                                            item.code === 2 && (data?.sender_domain_verdict === 'malicious' || data?.sender_domain_verdict === 'spam') ? 'readMoreZararli' :
                                                item.code === 2 && (data?.sender_domain_verdict === 'suspicious') ? 'readMoreTehlikeli' :
                                                    item.code === 3 && data?.urls_verdict === 'malicious' ? 'readMoreZararli' :
                                                        item.code === 3 && data?.urls_verdict === 'suspicious' ? 'readMoreTehlikeli' :
                                                            item.code === 4 && data?.verdict === 'content_words_in_blacklist' ? 'readMoreZararli' :
                                                                item.code === 1 && data?.verdict === 'attachment_extension_in_blacklist' ? 'readMoreZararli' :
                                                                    item.code === 4 && data?.message?.spam_score > 5 ? 'readMoreZararli no-hover-border p-button' :
                                                                        'readMoreZararsiz'

                                        // item.code === 4 && data?.urls_verdict === 'malicious' ? 'card-timeline-zararli' :
                                    } onClick={() => handleItemClick(item)}
                                ></Button>
                            </>
                        )
                    }

                </Card >


            </div>
        )
    };



    return (
        <div className="card flex justify-content-center">
            <Timeline style={{ maxWidth: '70rem' }} value={events} align="alternate" marker={customizedMarker} content={customizedContent} />

            {
                code === 1 ? (

                    <Attachment setCode={setCode} data={data} />
                ) : code === 2 ? (
                    <>
                        <SenderDomain setCode={setCode} data={data} />
                    </>
                ) : code === 3 ? (
                    <>
                        <Url setCode={setCode} data={data} />
                    </>
                ) : code === 4 ? (
                    <>
                        <EmailContent setCode={setCode} data={data} />
                    </>
                ) : (
                    <>

                    </>
                )
            }
            <Dialog header="Confirmation" visible={showDialog} style={{ width: '20vw' }} onHide={handleDialogHide}>
            <i style={{fontSize:'50px'}} className='pi pi-exclamation-triangle flex justify-content-center p-2'></i>

                <p style={{fontSize:'18px'}}>Are you sure you want to read the content of the email?</p>
                <div className="flex justify-content-center p-2">
                    <Button  label="No" icon="pi pi-times" onClick={handleDialogHide} className="p-button-outlined  mr-2" />
                    <Button label="Yes" icon="pi pi-check" onClick={handleConfirm} className="p-button-outlined " />
                </div>
            </Dialog>
        </div>
    )
}

export default AntiPhishingTimeLine