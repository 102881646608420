import { senderDomainAnalyzer as api } from "../../services/Api"; 
import { toast } from "react-toastify";

export const getAllSenderDomainAnalysis = async (page) => {
    try {
        const {
            data: data,
        } = await api.getAllSenderDomainAnalysis(page);

        return data;
    } catch (error) {
        toast.error(error.message);
        return[];
    }
};

export const getAllSenderDomainAnalysisAntiPhishing = async (id) => {
    try {
        const {
            data: data,
        } = await api.getAllSenderDomainAnalysisAntiPhishing(id);

        return data;
    } catch (error) {
        toast.error(error.message);
        return[];
    }
};