import { urlAnalyzerApi as api } from "../../services/Api";
import { toast } from "react-toastify";

export const getAllUrlAnalysis = async (page) => {
  try {
    const {
      data: data,
    } = await api.getAllUrlAnalysis(page);

    return data;
  } catch (error) {
    toast.error(error.message);
    return [];
  }
};

export const getAllUrlAnalysisbyAntiPhishing = async (id) => {
  try {
    const {
      data: data,
    } = await api.getAllUrlAnalysisbyAntiPhishing(id);

    return data;
  } catch (error) {
    toast.error(error.message);
    return [];
  }
};
