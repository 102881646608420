import React, { useEffect, useState } from "react";
import { Chart } from "primereact";

const RadarChart = ({ dashboardData }) => {

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    console.log("dashboardData42", dashboardData)


    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const data = {
            labels: ['Phishing', 'Spam', 'No Threat Found', 'Domain Blacklist', 'Extension Blacklist'],
            datasets: [
                {
                    label: 'Mail Analysis',
                    borderColor: documentStyle.getPropertyValue('--cyan-400'),
                    pointBackgroundColor: documentStyle.getPropertyValue('--cyan-400'),
                    pointBorderColor: documentStyle.getPropertyValue('--cyan-400'),
                    pointHoverBackgroundColor: textColor,
                    pointHoverBorderColor: documentStyle.getPropertyValue('--cyan-400'),
                    // data: [dashboardDatas[2].total, dashboardDatas[0].total,dashboardDatas[1].total,10, 12, 14, 15, 16]
                    data: [
                        dashboardData?.email_analysis_count?.phishing ,
                        dashboardData?.email_analysis_count?.spam,
                        dashboardData?.email_analysis_count?.no_threat_found ,
                        dashboardData?.email_analysis_count?.sender_domain_in_blacklist ,
                        dashboardData?.email_analysis_count?.attachment_extension_in_blacklist ,
                    ]
                }
                ,
                {
                    label: 'File Analysis',
                    borderColor: documentStyle.getPropertyValue('--purple-500'),
                    pointBackgroundColor: documentStyle.getPropertyValue('--purple-500'),
                    pointBorderColor: documentStyle.getPropertyValue('--purple-500'),
                    pointHoverBackgroundColor: textColor,
                    pointHoverBorderColor: documentStyle.getPropertyValue('--purple-500'),
                    // data: [dashboardDatas[2].total, dashboardDatas[0].total,dashboardDatas[1].total,10, 12, 14, 15, 16]
                    data: [
                        dashboardData?.file_analysis_count?.malicious ,
                        
                        dashboardData?.email_analysis_count?.no_threat_found ,
                        
                    ]
                },


            ]
        };
        const options = {
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                r: {
                    grid: {
                        color: textColorSecondary
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [dashboardData]);


    return (
        <div style={{ height: '31rem' }} className="card flex justify-content-center">
            <Chart style={{ width: '30rem' }} type="radar" data={chartData} options={chartOptions} />
        </div>

    )
}

export default React.memo(RadarChart)
