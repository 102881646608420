import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Badge } from "primereact/badge";
import { getAllUrlAnalysisbyAntiPhishing } from "../../../store/actions/urlanalyzer";
import Colors from '../../Colors';
import { Messages } from 'primereact/messages';
import '../MailEditor.css'
import DateFormat from '../../DateFormat';

const Url = ({ setCode, data }) => {


    const msgs = useRef(null);
    const [displayUrl, setDisplayUrl] = useState(false);
    const [selectUrlAnalyze, setSelectUrlAnalyze] = useState(null);
    const [urlAnalysisbyAntiPhishing, setUrlAnalysisbyAntiPhishing] = useState(null);

    useEffect(() => {
        getAllUrlAnalysisbyAntiPhishing(data?.id).then((res) => {
            console.log("ressss123", res);
            setUrlAnalysisbyAntiPhishing(res?.data)
            if (res) {
                setDisplayUrl(true)

            }
        })
    }, [data])

    useEffect(() => {
        if (msgs.current && selectUrlAnalyze) {
            msgs.current.show({ life: 2000, severity: 'info', detail: selectUrlAnalyze?.url });
        }
    }, [selectUrlAnalyze]);

    const onHideURL = (e) => {
        setDisplayUrl(false)
        setSelectUrlAnalyze(null)
        setCode(null)


    }

    const analyzeTimeTemplate = (rowData) => {

        return (
            <>
        <DateFormat dateString={rowData?.analyze_time} />

            </>
        )
        // return (
        //     <>

        //         {(new Date(rowData.analyze_time).toLocaleString())}
        //     </>
        // )

    };
    const urlTemplate = (rowData) => {

        return (
            <>
                {rowData.url.substr(0, 50) + "..."}
            </>

        )

    };

    const resultBodyVirustotalStatusTemplate = (rowData) => {
        let data = rowData?.verdict?.replace(/_/g, ' ');

        if (data !== "-") {
            return (
                <>
                    {/* <span className="p-column-title">Status</span> */}
                    <Badge
                        value={data.replace(/\b\w/g, char => char.toUpperCase())}
                        // style={{
                        //     backgroundColor: data === "malicious" ? "#ffcdd2"
                        //         : data === "clean" ? "#0bd18a" : "#b3e5fc",
                        //     color: data === "clean" ? "#256029" : "#23547b"
                        // }}
                        style={data === "malicious" ? { color: Colors[0].yazi, backgroundColor: Colors[0].malicious, boxShadow: `0 0 10px ${Colors[0].malicious}` }

                            : data === "suspicious" ? { color: Colors[0].yazi, backgroundColor: Colors[0].suspicious, boxShadow: `0 0 10px ${Colors[0].suspicious}` }


                                : { backgroundColor: Colors[0].ntf, color: Colors[0].yazi, boxShadow: `0 0 10px ${Colors[0].ntf}` }}
                    />
                </>
            );
        } else {
            return ('-');
        }
    };

    return (
        <Dialog
            header="Analyzed URLs"
            visible={displayUrl}
            style={{ width: '80vw', height: "40vw" }}
            onHide={() => onHideURL()}
            modal
            dismissableMask
        >
            <div className="card" >
                <DataTable
                    value={urlAnalysisbyAntiPhishing}
                    className="p-datatable"
                    selection={selectUrlAnalyze}
                    onSelectionChange={(e) => setSelectUrlAnalyze(e.value)}
                    selectionMode="single"
                    dataKey="id"
                >
                    <Column
                        header="URL"
                        style={{ textAlign: 'left' }}
                        alignHeader={'center'}
                        body={urlTemplate}
                    ></Column>
                    <Column
                        field="anaylze_time"
                        header="Anaylze Time"
                        style={{ textAlign: 'center' }}
                        alignHeader={'center'}
                        body={analyzeTimeTemplate}
                    ></Column>
                    <Column
                        field="verdict"
                        header="Verdict"
                        style={{ textAlign: 'center' }}
                        alignHeader={'center'}
                        body={resultBodyVirustotalStatusTemplate}

                    ></Column>


                </DataTable>
                <Messages className="centered-messages" ref={msgs} />
            </div>
        </Dialog>
    )
}

export default Url