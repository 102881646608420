import api from "../../api";

export const contentFilterGet = () => api.get("/content-blacklist-words");
export const contentFilterInsert = (model) =>
    api.put(`/content-blacklist-words`, model, {
        headers: {
            "Content-Type": "application/json",
        },
    });
export const contentFilterDelete = (model) => {
    console.log("modell2", model);
    api.put("/content-blacklist-words", model, {
        headers: {
            "Content-Type": "application/json",
        },
    })
};