export * as authApi from "./auth";
export * as emailApi from "./email";
export * as landingApi from "./landing";
export * as smtpApi from "./smtp";
export * as simulationApi from "./simulation";
export * as eventApi from "./event";
export * as templateApi from "./template";
export * as dashboardApi from "./dashboard";
export * as leakApi from "./leak";
export * as malwareBazaarApi from "./malwarebazaar";
export * as urlAnalyzerApi from "./urlanalyzer";
export * as senderDomainAnalyzer from "./senderdomainanalyzer"
export * as antiphishingApi from "./antiphishing";
export * as apikeyapi from "./apikey";
export * as inventoryApi from "./inventory";
export * as bruteForceApi from "./bruteForce";
export * as attachmentApi from "./attachment";
export * as privateIntelligenceApi from "./privateintelligence";
export * as  extensionsApi from "./extensions";
export * as  yaraApi from "./yaraRule";

