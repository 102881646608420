import { defaultActionTypes, landingActionTypes } from '../actionTypes';

const initialState = { isLandingLoading: true, landings: [] };

const reducers = (state = { ...initialState }, action) => {
  switch (action.type) {
    case defaultActionTypes.START_LOADING:
      return { ...state, isLandingLoading: true };
    case defaultActionTypes.END_LOADING:
      return { ...state, isLandingLoading: false };

    case landingActionTypes.DELETE_LANDING:
      return {
        ...state,
        landings: state.landings.filter((landing) => landing.id !== action.payload),
      };

    case landingActionTypes.GET_LANDINGS:
      return {
        ...state,
        landings: action.payload,
      };

    case landingActionTypes.EDIT_LANDING:
      return {
        ...state,
        landings: state.landings.map((landing) =>
          landing.id === action.payload.id ? action.payload : landing,
        ),
      };

    case landingActionTypes.CREATE_LANDING:
      return { ...state, landings: [...state.landings, action.payload] };

    default:
      return state;
  }
};

export default reducers;
