
import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { Tag } from 'primereact/tag';
import Colors from '../Colors';
const LineChart = ({ dashboardData, tarihAralik }) => {

   

    const total = dashboardData.email_analysis_count.total;
    const totals = {};

    Object.values(dashboardData.email_analysis_count).forEach((dayData) => {
        Object.entries(dayData).forEach(([key, value]) => {
            if (totals[key]) {
                totals[key] += value;
            } else {
                totals[key] = value;
            }
        });
    });

    const noThreatFound = totals?.no_threat_found || 0;
    const noReport = totals?.no_report || 0;

    const totalDetected = total - (noThreatFound + noReport);



    let tarihAraliği = `${tarihAralik?.start_date} - ${tarihAralik?.end_date} `
   

    const isDateKey = (key) => {
        return /^\d{4}-\d{2}-\d{2}$/.test(key);
    };

    const filteredData = Object.keys(dashboardData?.email_analysis_count)
        .filter(isDateKey) 
        .reduce((acc, key) => {
            acc[key] = dashboardData?.email_analysis_count[key]; 
            return acc;
        }, {});

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year}`; 
    };

    const labels = Object.keys(filteredData)
        .sort((a, b) => new Date(a) - new Date(b)) 
        .map(date => formatDate(date)); 

    const categories = ["content_words_in_blacklist", "no_report", "no_threat_found", "phishing", "spam"];
    const capitalizeAndFormatCategory = (category) => {
        return category
            .split('_') 
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
            .join(' '); 
    };
    const categoryColors = {
        content_words_in_blacklist: Colors[0].cwb,  
        no_report: Colors[0].nr,                   
        no_threat_found: Colors[0].ntf,             
        phishing: Colors[0].phishing,                    
        spam: Colors[0].spam,
        attachment_bigger_than_max_size : Colors[0].ams,
        suspicious: Colors[0].suspicious,
    };
    const datasets = categories.map(category => {
        return {
            label: capitalizeAndFormatCategory(category), 
            data: Object.keys(filteredData)
                .sort((a, b) => new Date(a) - new Date(b)) 
                .map(date => filteredData[date][category] || 0), 
            fill: false,
            borderColor: categoryColors[category], 
            tension: 0.1 
        };
    });

    
    const chartData = {
        labels: labels, 
        datasets: datasets 
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
        },
        
    };


    // useEffect(() => {

    //     // Kontrol edilen veriler
    //     if (totals.spam) {
    //         stringArray.push("Spam");
    //         valueArray.push(totals.spam)
    //     }

    //     if (totals.phishing) {
    //         stringArray.push("Phishing");
    //         valueArray.push(totals.phishing)
    //     }

    //     if (totals.attachment_bigger_than_max_size) {
    //         stringArray.push("Attachment Filter");
    //         valueArray.push(totals.attachment_bigger_than_max_size)
    //     }
    //     if (totals.content_words_in_blacklist) {
    //         stringArray.push("Content Filter");
    //         valueArray.push(totals.content_words_in_blacklist)
    //     }
    //     if (dashboardData?.sender_domain_analysis_count?.blacklist) {
    //         stringArray.push("Domain Filter");
    //         valueArray.push(dashboardData?.sender_domain_analysis_count?.blacklist)
    //     }
    // }, [dashboardData])

    // useEffect(() => {
    //     const documentStyle = getComputedStyle(document.documentElement);
    //     const textColor = documentStyle.getPropertyValue('--text-color');
    //     const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    //     const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    //     const data = {
    //         labels: stringArray,
    //         // [dashboardData?.email_analysis_count?.attachment_bigger_than_max_size ? 'Attachment Filter' :
    //         //     dashboardData?.email_analysis_count?.content_words_in_blacklist ? 'Content Filter' :
    //         //         dashboardData?.email_analysis_count?.content_words_in_blacklist ? 'Content Filter' :
    //         //             dashboardData?.email_analysis_count?.phishing ? 'Phishing' :
    //         //                 dashboardData?.email_analysis_count?.spam ? 'Spam' : ''
    //         // ],

    //         datasets: [
    //             {
    //                 label: 'Detected',
    //                 data: valueArray,
    //                 fill: false,
    //                 borderColor: documentStyle.getPropertyValue('--pink-500'),
    //                 tension: 0.4
    //             }
    //         ]
    //     };
    //     const options = {
    //         maintainAspectRatio: false,
    //         aspectRatio: 0.6,
    //         plugins: {
    //             legend: {
    //                 labels: {
    //                     color: textColor
    //                 }
    //             }
    //         },
    //         scales: {
    //             x: {
    //                 ticks: {
    //                     color: textColorSecondary
    //                 },
    //                 grid: {
    //                     color: surfaceBorder
    //                 }
    //             },
    //             y: {
    //                 ticks: {
    //                     color: textColorSecondary
    //                 },
    //                 grid: {
    //                     color: surfaceBorder
    //                 }
    //             }
    //         }
    //     };

    //     setChartData(data);
    //     setChartOptions(options);
    // }, []);

    return (
        <div style={{ height: '31rem' }} className="card">
            <h6 className='flex justify-content-center'>Line Chart </h6>

            <div className='flex justify-content-center'>
                <h6> {tarihAraliği}</h6>
                {/* <Tag style={{ backgroundColor: 'transparent', color: 'black', width: '300px' }} value={tarihAraliği} /> */}
            </div>
            <div className='flex justify-content-center'>
                <Tag className='m-2' style={{ backgroundColor: '#dfe6e6', color: 'black', width: '150px' }} value={`Total Traffic ${dashboardData?.email_analysis_count?.total}`} />
                <Tag className='m-2' style={{ backgroundColor: '#dfe6e6', color: 'black', width: '150px' }} value={`Total Detected ${totalDetected}`} />

            </div>
            <Chart style={{ maxHeight: '65%' }} type="line" data={chartData} options={chartOptions} />

        </div>
    )
}


export default React.memo(LineChart)