import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { Button } from "primereact/button";
import DateFormat from "../DateFormat";

const ExcelExport = ({ data }) => {
    console.log("data2424", data);




    const createDownLoadData = () => {
        handleExport().then((url) => {
            console.log("urlneurl", url);
            console.log(url);
            const downloadAnchorNode = document.createElement("a");
            downloadAnchorNode.setAttribute("href", url);
            downloadAnchorNode.setAttribute("download", 'Mail Analysis.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        });
    };

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
        };

        const wbout = XLSX.write(workbook, wopts);

        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
        });

        return blob;
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);

        console.log(buf);

        const view = new Uint8Array(buf);

        for (let i = 0; i !== s.length; ++i) {
            console.log(s.charCodeAt(i));
            view[i] = s.charCodeAt(i);
        }

        return buf;
    };

    const verdict = (param) => {
        let data = param?.replace(/_/g, ' ');

        switch (data) {
            case 'spam':
                return 'Spam'
            case 'content words in blacklist':
                return 'Content Filter'
            case 'sender domain in blacklist':
                return 'Domain Filter'
            case 'malicious':
                return 'Malicious'
            case 'phishing':
                return 'Phishing'
            case 'attachment extension in blacklist':
                return 'Extensions Filter'
            case 'attachment bigger than max size':
                return 'Attachment Max Size'
            case 'suspicious':
                return 'Suspicious'
            default:
                break;
        }
    }

    const dateFormat = (dateString) => {
        console.log("datestr",dateString);
        const date = new Date(dateString);
        const year = date.getUTCFullYear(); // Yılı al
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Ayı al, 0'dan başladığı için 1 ekleyip, iki basamak yap
        const day = date.getUTCDate().toString().padStart(2, '0'); // Günü iki basamak yap
        const hours = date.getUTCHours().toString().padStart(2, '0'); // Saati iki basamak yap
        const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // Dakikayı iki basamak yap
        const seconds = date.getUTCSeconds().toString().padStart(2, '0'); // Saniyeyi iki basamak yap

        return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;


    }


    const handleExport = () => {
        const title = [{
            A: `Mail Analysis Details / Report Date : ${new Date().toLocaleDateString()}`
        }, {}];



        let table1 = [
            {
                A: "Analyze Time",
                B: "Sender",
                C: "Receiver",
                D: "Verdict"
            },
        ];

        console.log("table1", table1);
        console.log("data99data", data);
        data.forEach((row) => {
            table1.push({
                A: dateFormat(row.analyze_time),
                B: row.sender,
                C: row.receiver,
                // D: row.email_content.sender_ip_address,
                D: verdict(row.verdict)
            });
        });

        table1 = []
            .concat(table1)
            .concat([""])

        const finalData = [...title, ...table1];

        console.log("finaldatane", finalData);

        //create a new workbook
        const wb = XLSX.utils.book_new();

        const sheet = XLSX.utils.json_to_sheet(finalData, {
            skipHeader: true,
        });
        XLSX.utils.book_append_sheet(wb, sheet, 'AntiPhishingList')

        // binary large object
        // Since blobs can store binary data, they can be used to store images or other multimedia files.

        const workbookBlob = workbook2blob(wb);

        var headerIndexes = [];
        finalData.forEach((data, index) =>
            data["A"] === "Mail Address" ? headerIndexes.push(index) :
                data["A"] === "Analyze Time" ? headerIndexes.push(index) :
                    data["A"] === "Sender Domain" ? headerIndexes.push(index) :
                        data["A"] === "URL" ? headerIndexes.push(index) :
                            data["A"] === "File Name" ? headerIndexes.push(index) : null
        );
        console.log("headerIndexesheaderIndexes", headerIndexes);
        const totalRecords = data.length;

        const dataInfo = {
            titleCell: "A2",
            titleRange: "A1:F2",
            tbodyRange: `A3:F${finalData.length}`,
            theadRange:
                headerIndexes?.length >= 1
                    ? `A${headerIndexes[0] + 1}:F${headerIndexes[0] + 1}`
                    : null,
            theadRange1:
                headerIndexes?.length >= 2
                    ? `A${headerIndexes[1] + 1}:F${headerIndexes[1] + 1}`
                    : null,
            tFirstColumnRange:
                headerIndexes?.length >= 1
                    ? `A${headerIndexes[0] + 1}:A${totalRecords + headerIndexes[0] + 1}`
                    : null,
            tLastColumnRange:
                headerIndexes?.length >= 1
                    ? `G${headerIndexes[0] + 1}:F${totalRecords + headerIndexes[0] + 1}`
                    : null,

            tFirstColumnRange1:
                headerIndexes?.length >= 1
                    ? `A${headerIndexes[1] + 1}:A${totalRecords + headerIndexes[1] + 1}`
                    : null,
            tLastColumnRange1:
                headerIndexes?.length >= 1
                    ? `E${headerIndexes[0] + 1}:F${totalRecords + headerIndexes[1] + 1}`
                    : null,
        };
        console.log("dataInfodataInfo", dataInfo);
        return addStyle(workbookBlob, dataInfo);
    };

    const addStyle = (workbookBlob, dataInfo) => {
        console.log("workbookBlobworkbookBlob", workbookBlob);
        return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
            console.log("workbook55", workbook);
            workbook.sheets().forEach((sheet) => {

                sheet.usedRange().style({
                    fontFamily: "Arial",
                    verticalAlignment: "center",


                });
                console.log("sheet4545", sheet);

                sheet.column("A").width(35);
                sheet.column("B").width(35);
                sheet.column("C").width(35);
                sheet.column("D").width(35);
                sheet.column("E").width(35);
                sheet.column("F").width(35);

                sheet.range(dataInfo.titleRange).merged(true).style({
                    bold: true,
                    horizontalAlignment: "center",
                    verticalAlignment: "center",

                });

                if (dataInfo.tbodyRange) {
                    sheet.range(dataInfo.tbodyRange).style({
                        horizontalAlignment: "center",
                    });
                }

                sheet.range(dataInfo.theadRange).style({
                    fill: "49df27",
                    bold: true,
                    horizontalAlignment: "center",
                });

                if (dataInfo.theadRange1) {
                    sheet.range(dataInfo.theadRange1).style({
                        fill: "808080",
                        bold: true,
                        horizontalAlignment: "center",
                        fontColor: "ffffff",
                    });
                }

                if (dataInfo.tFirstColumnRange) {
                    sheet.range(dataInfo.tFirstColumnRange).style({
                        bold: true,
                        horizontalAlignment: "center",
                    });
                }

                if (dataInfo.tLastColumnRange) {
                    sheet.range(dataInfo.tLastColumnRange).style({
                        bold: true,
                    });
                }

                // if (dataInfo.tFirstColumnRange1) {
                //   sheet.range(dataInfo.tFirstColumnRange1).style({
                //     bold: true,
                //   });
                // }

                // if (dataInfo.tLastColumnRange1) {
                //   sheet.range(dataInfo.tLastColumnRange1).style({
                //     bold: true,
                //   });
                // }

            });

            console.log("workbookworkbook2", workbook);
            return workbook
                .outputAsync()
                .then((workbookBlob) => URL.createObjectURL(workbookBlob));
        });
    };

    return (
        <Button
            label="Export"
            icon="pi pi-upload"
            className="ml-3 button-export"
            style={{ backgroundColor: "transparent" }}
            onClick={() => {
                createDownLoadData();
            }}

        />
    );
};

export default ExcelExport;