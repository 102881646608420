import { defaultActionTypes } from "../actionTypes";

const initialState = { isTimelineLoading: true, timelineData: [] };

const reducers = (state = { ...initialState }, action) => {
  switch (action.type) {
    case defaultActionTypes.START_LOADING:
      return { ...state, isTimelineLoading: true };
    case defaultActionTypes.END_LOADING:
      return { ...state, isTimelineLoading: false };

    case defaultActionTypes.GET_TIMELINE_DATA:
      return {
        ...state,
        timelineData: action.payload,
      };

    default:
      return state;
  }
};

export default reducers;
