import React, { useEffect, useState } from 'react';
import { Container, Grid, Grow } from '@mui/material';
import useStyles from '../Dashboard/styles';
import Template from './Template';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplates } from '../../store/actions/template';
import { CircularProgress } from '@material-ui/core';
import { Button } from 'primereact/button';

const TemplateList = () => {
  const { templates, isTemplateLoading } = useSelector((state) => state.templates);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  // const data = [{ name: "E-Arşiv Faturanız Hakkında" }, { name: "TTNet Faturanız Hakkında" }];

  const [data, setData] = useState(templates);
  useEffect(() => {
    dispatch(getTemplates());
  }, [dispatch]);

  useEffect(() => {
    setData([...templates]);
  }, [templates]);

  if (isTemplateLoading)
    return (
      <Container maxWidth='xl' sx={{ margin: 5 }}>
        <center>
          <CircularProgress />
        </center>
      </Container>
    );

  return (
    <Grow in>
      <Container maxWidth='xl'>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid item className={classes.templateContainer}>
            <Button
              label='Add'
              icon='pi pi-plus'
              className='p-button-success mr-2'
              onClick={() => history.push('/template/new')}
            />
          </Grid>
          <Grid container className={classes.templateContainer} spacing={3}>
            {data.map((template) => (
              <Template key={template.id} {...template} />
            ))}
          </Grid>
        </Grid>
      </Container>
    </Grow>
  );
};

export default TemplateList;
