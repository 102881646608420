import api from "../../api";

export const yaraGet = () => api.get("/yara-rule");
export const yaraInsert = (model) =>
    api.post(`/yara-rule`, model, {
        headers: {
            "Content-Type": "application/json",
        },
    });
export const yaraUpdate = (id, model) =>
    api.put(`/yara-rule/${id}`, model, {
        headers: {
            "Content-Type": "application/json",
        },
    });

export const yaraDelete = (id) => api.delete(`/yara-rule/${id}`)
