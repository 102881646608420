import React, { useState, useRef, useEffect } from 'react'
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog';

import { yaraGet, yaraInsert, yaraUpdate, yaraDelete } from '../../services/Api/yaraRule'
import { QueryClient, useQuery } from "react-query";
import { useDispatch } from 'react-redux';

const YaraRules = () => {

    const defaultRule = `rule ExampleRule {
        meta:
            author = "example_author"
            description = "Example rule for demonstration purposes"
        strings:
            $string1 = "example"
            $string2 = "test" ascii nocase
            $string3 = "demo" ascii nocase
            $string4 = "sample" ascii nocase
            $string5 = "data" ascii nocase
        condition:
            uint32(0) == 0x504b0304 and $string1 and (3 of ($string*) or ($string5))
    }`
    const [dataYara, setDataYara] = useState(null);
    const [yaraList, setYaraList] = useState([]);
    const [rule, setRule] = useState('');
    const [ruleDesc, setRuleDesc] = useState('');
    const [updateRuleId, setUpdateRuleId] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const [visible, setVisible] = useState(false);
    const [updated, setUpdated] = useState(null);
    const [deleteModel, setDeleteModel] = useState(null);
    const [deleteDialog, setDeleteDialog] = useState(false)

    const toast = useRef(null);
    const queryClient = new QueryClient();
    const dispatch = useDispatch();


    const { data } = useQuery(['yaraInsert', dataYara], () => {
        if (updated === false && dataYara) {
            yaraInsert(dataYara)
        }
    });

    console.log("data 231", data);

    const { data: updateYaraRes } = useQuery(['yaraUpdate', updateRuleId, dataYara], () => {
        if (updateRuleId && dataYara) {
            return yaraUpdate(updateRuleId, dataYara)
        }
    });
    console.log("yaraUpdate", yaraUpdate);
    const { data: deleteData } = useQuery(['yaraDelete', deleteModel], () => {
        if (deleteModel) {
            return yaraDelete(deleteModel)
        }
    });



    useEffect(() => {
        console.log("data 23", data);

        dispatch(yaraGet).then(res => {
            console.log("res1", res);
            setYaraList(res.data.data)
        });
        setUpdated(null)
        setUpdateRuleId(null)
    }, [data, updateYaraRes, deleteData]);

    console.log("yaraList", yaraList);
    const handleSave = () => {
        console.log("rule desc", rule, ruleDesc);
        if (rule && ruleDesc) {
            const model = {
                rule: rule,
                description: ruleDesc
            }
            setDataYara(model)
            console.log("modelll", model);
            if (updated) {
                queryClient.invalidateQueries(['yaraUpdate', updateRuleId, model]);

            } else {
                setUpdated(false)
                queryClient.invalidateQueries(['yaraInsert', model]);

            }
            setRule('')
            setRuleDesc('')
            setVisible(false)

        }
    };


    const deleteRules = (rowData) => {
        console.log("rowData22", rowData);
        setDeleteDialog(true)
        setDeleteId(rowData.id)
    }
    const detailsRules = (rowData) => {
        console.log("rowData", rowData);
        setUpdateRuleId(rowData.id)
        setRule(rowData.rule);
        setRuleDesc(rowData.description);
        setUpdated(true)
        setVisible(true)

    }
    const updateSilBody = (rowdata) => {
        return (
            <>
                <Button tooltip='Details' tooltipOptions={{ position: 'top' }} icon='pi pi-search' className="p-button-rounded p-button-success p-button-outlined" onClick={() => detailsRules(rowdata)} ></Button>
                <Button tooltip='Delete Rules' tooltipOptions={{ position: 'top' }} icon='pi pi-trash' className="p-button-rounded p-button-danger p-button-outlined ml-2" onClick={() => deleteRules(rowdata)} ></Button>
            </>
        )
    }

    const contentRules = (rowdata) => {
        return (
            rowdata.data2.slice(0, 100) + '...'
        )
    }

    const footerCreateRules = (
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-outlined p-button-danger" />
            <Button label={updated ? "Update" : "Save"} icon="pi pi-check" onClick={() => handleSave()} className="p-button-outlined p-button-success" />
        </div>
    );
    const dialogClose = () => {
        setVisible(false)

    }

    const deleteYaraRule = () => {
        console.log("çalıştı");
        setDeleteModel(deleteId)
        queryClient.invalidateQueries(['yaraDelete', deleteModel]);
        toast.current.show({ severity: 'success', summary: 'Delete', detail: 'Yara Rule deleted' });

    }

    const handleReject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected' });
    };
    return (


        <>
            <Toast ref={toast} />

            <div className="grid p-fluid mt-2">
                <div className="col-12 md:col-6 ml-2">
                    <div className="p-inputgroup ">
                        <Button
                            className='p-button-m p-button-success p-button-outlined'
                            style={{ width: "200px" }}
                            label='New Rules'
                            icon="pi pi-plus"
                            onClick={() => setVisible(true)}
                        >
                        </Button>

                    </div>
                </div>
            </div>
            <hr />
            {
                yaraList?.length > 0 && (
                    <div className="card">
                        <DataTable
                            // header='Yara Rules'
                            value={yaraList}
                            responsiveLayout="scroll"
                            className='p-datatable-gridlines'
                            dataKey='id'
                        >
                            <Column style={{ width: '20%' }} align={'center'} field="description" header="Description"></Column>
                            <Column style={{ width: '65%' }} align={'center'} field="rule" header="Rule"></Column>

                            {/* <Column align={'center'} body={contentRules} header="Rules Content"></Column> */}
                            <Column align={'center'} body={updateSilBody} header="Action Menu"></Column>

                        </DataTable>
                    </div>
                )
            }

            {/* <ConfirmDialog
                    visible={deleteDialog}
                    onHide={() => setDeleteDialog(false)}
                    message="The selected extension will be deleted. Would you like to continue ?"
                    header="Confirmation"
                    icon="pi pi-exclamation-triangle"
                    accept={() => deleteDomainFunction()}
                    reject={() => {
                        handleReject();
                    }}
                /> */}
            <Dialog header={updated ? "Details Yara Rule" : "Create Yara Rule"} visible={visible} footer={footerCreateRules} style={{ width: '50vw' }} onHide={() => dialogClose()}>
                {/* <div className="col-12 md:col-12 ml-3">
                    <i className="block mb-1 ml-1" style={{ fontSize: "14px", fontFamily: "sans-serif", fontStyle: "normal" }}>YARA Rule <i style={{ color: "red" }}>*</i> </i>
                    <div className="p-inputgroup">
                        <InputText
                            type='text'
                            placeholder='Rule'
                            value={input1}
                            onChange={(e) => setInput1(e.target.value)} />
                    </div>
                </div> */}
                <div className="col-12 md:col-12 ml-3">
                    <i className="block mb-1 ml-1" style={{ fontSize: "14px", fontFamily: "sans-serif", fontStyle: "normal" }}>Rule Description <i style={{ color: "red" }}>*</i> </i>
                    <div className="p-inputgroup">
                        <InputText
                            type='text'
                            placeholder='Rule Description'
                            value={ruleDesc}
                            maxLength={200}
                            onChange={(e) => setRuleDesc(e.target.value)} />
                    </div>
                </div>

                <div className="col-12 md:col-12 ml-3">
                    <i className="block mb-1 ml-1" style={{ fontSize: "14px", fontFamily: "sans-serif", fontStyle: "normal" }}>Rule <i style={{ color: "red" }}>*</i> </i>
                    <div className="p-inputgroup">
                        <InputTextarea
                            style={{ minHeight: '400px' }}
                            value={rule}
                            onChange={(e) => setRule(e.target.value)}
                            placeholder={defaultRule}
                            maxLength={2000} />

                    </div>
                </div>


            </Dialog>

            <ConfirmDialog
                visible={deleteDialog}
                onHide={() => setDeleteDialog(false)}
                message="The selected Yara Rule will be deleted. Would you like to continue ?"
                header="Confirmation"
                icon="pi pi-exclamation-triangle"
                accept={() => deleteYaraRule()}
                reject={() => {
                    handleReject();
                }}
            />
        </>

    )
}

export default YaraRules