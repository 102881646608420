import React, { useState, useEffect, useRef } from 'react'
import { getEmlViewer } from "../../../store/actions/antiphishing";
import { Dialog } from 'primereact/dialog';
import '../MailEditor.css'
import { Button } from 'primereact/button';
const EmailContent = ({ setCode, data }) => {

    const [emlViewer, setEmlViewer] = useState(null);
    const [emlKontrol, setEmlKontrol] = useState()
    const [displayEmail, setDisplayEmail] = useState(false);

    console.log("data424", data?.message?.spam_score);
    useEffect(() => {
        setEmlKontrol(data?.file_name)
    }, [data])

    
    
    function HighlightedText({ text, searchWord }) {
        const regex = new RegExp(`(${searchWord})`, 'gi');
        const parts = text?.split(regex);
        return (
            <span className={'clear-text'} >
                {parts?.map((part, index) =>
                    part?.toLowerCase() === searchWord?.toLowerCase() ?
                        <span key={index} style={{ color: 'red', fontWeight: 'bold', cursor: 'not-allowed' }}
                        >{part}</span> :
                        part
                )}

            </span>
        );
    }

    useEffect(() => {
        if (emlKontrol !== '') {
            getEmlViewer(data?.id).then((res) => {
                setEmlViewer(res)
                setDisplayEmail(true)

            })
        }

    }, [emlKontrol])

    console.log("data?.message?.matches_word?.[0]", data?.message?.matches_word?.[0]);

    const onHideEmail = (e) => {
        setDisplayEmail(false)
        setCode(null)


    }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap">E-Mail Content</span>
            {
                data?.message?.spam_score > 5 ?
                    <>
                        <small style={{ color: 'red', fontSize: 11 }}>( The content of this email has been detected as spam by spam checker ) </small>

                    </>
                    :
                    <>
                    </>
            }        </div>
    );

    const tarih = (rowData) => {
        let tarihKontrol = rowData
        const formattedDate = new Date(rowData).toLocaleDateString();
        const formattedTime = new Date(rowData).toLocaleTimeString('en-GB', { timeZone: 'UTC' });
        let tarih = formattedDate + ' ' + formattedTime
        console.log("124", tarihKontrol, tarih);



        return (
            <>
                {tarihKontrol ? (tarih) : '-'}

            </>
        )
    }


    return (
        <>


            <Dialog
                header={headerElement}
                visible={displayEmail}
                style={{ width: '80vw', height: "40vw" }}
                onHide={() => onHideEmail()}
                modal
                dismissableMask
            >
                <div className="card" >
                    <div style={{ border: "2px solid black", boxShadow: "0 0 2px white", padding: "5px", borderRadius: "15px" }} className="eml-card">
                        <div className="eml-header">
                            <div className="eml-sender"><b>From </b> &nbsp; {emlViewer?.email_content?.from || "Not Available"}</div>
                            <div className="eml-recipient">
                                {

                                }
                                <b>To </b> &nbsp; {
                                    Array.isArray(emlViewer?.email_content?.to) ? emlViewer?.email_content?.to?.map((item, index) => {
                                        return (
                                            <span key={index}>{item}</span>
                                        );
                                    }) :
                                        <span>{emlViewer?.email_content?.to}</span>

                                }
                            </div>
                        </div>

                        <div className="eml-headerKonu">
                            <>
                                <div className="eml-sender"><b>Subject </b> &nbsp;
                                    <HighlightedText text={emlViewer?.email_content?.subject} searchWord={data?.message?.matches_words?.[0]} />
                                </div>
                                <div className="eml-recipient"><b>Date </b> &nbsp;{tarih(emlViewer?.email_content.date)}</div>
                            </>
                        </div>

                        <div className="eml-content" style={{ overflow: 'hidden' }}><b>Content </b>&nbsp;
                            {
                                <HighlightedText text={emlViewer?.email_content?.message_body} searchWord={data?.message?.matches_words?.[0]} />

                                || <span style={{ color: 'red', fontWeight: "bold" }}>
                                    Not Available </span>
                            }
                        </div>

                        <div className="eml-recipient">
                            {
                                emlViewer?.email_content?.attachment?.map((item, index) => {

                                    return (
                                        <>
                                            <ul>
                                                <li className='p-2' style={{
                                                    listStyleType: "none", borderRadius: '4px',
                                                    border: '2px solid red'
                                                }} key={index}>
                                                    <b>Attachment {index + 1}</b>
                                                    <div
                                                        style={{
                                                            width: "200px",
                                                            wordWrap: "break-word",
                                                            overflow: "hidden",
                                                            whiteSpace: "normal",

                                                        }}
                                                    >
                                                        <small style={{ color: 'red', fontWeight: 'bold' }}>Filename:</small> {item?.filename}
                                                    </div>
                                                    <div><small style={{ color: 'orange', fontWeight: 'bold' }}>Size:</small>  {item?.size}</div>
                                                </li>
                                            </ul>


                                        </>

                                    );

                                })
                            }
                        </div>




                    </div>
                </div>
                
            </Dialog>
        </>
    )

}

export default EmailContent