import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React from "react";
import { Controller } from "react-hook-form";
import CustomFormErrorMessage from "./CustomFormErrorMessage";
import PropTypes from "prop-types";

const CustomInputText = ({
  title,
  name,
  rules,
  properties,
  errors,
  control,
}) => {
  return (
    <div className="field">
      <label htmlFor={name} className={classNames({ "p-error": errors[name] })}>
        {title}
      </label>
      <Controller
        name={name}
        control={control}
        rules={{ ...rules }}
        render={({ field, fieldState }) => (
          <InputText
            id={field.name}
            {...field}
            {...properties}
            className={classNames({ "p-invalid": fieldState.invalid })}
          />
        )}
      />
      <CustomFormErrorMessage errors={errors} name={name} />
    </div>
  );
};

CustomInputText.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  properties: PropTypes.object,
  rules: PropTypes.object,
  errors: PropTypes.object,
  control: PropTypes.object,
};

CustomInputText.defaultProps = {
  title: "",
  name: "",
  properties: {},
  rules: {},
  errors: {},
  control: {},
};

export default CustomInputText;
