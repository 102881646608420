import React from 'react';
import './reporting.css'; // CSS dosyasını içe aktarıyoruz (aşağıda açıklayacağım)
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faFilter, faExclamationTriangle, faEnvelope, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const DataCards = ({ dashboardVeri }) => {
   
    const totals = {};

    if (dashboardVeri && dashboardVeri?.email_analysis_count) {
        for (const dayDataKey in dashboardVeri?.email_analysis_count) {
            const dayData = dashboardVeri?.email_analysis_count[dayDataKey];
            console.log("dayDat", dayData);

            if (dayData && typeof dayData === 'object') {
                for (const key in dayData) {
                    const value = dayData[key];
                    if (totals[key]) {
                        totals[key] += value;
                    } else {
                        totals[key] = value;
                    }
                }
            }
        }
    }
    console.log('totals', totals);
    const totalsFileAnalysis = {};

    if (dashboardVeri && dashboardVeri.file_analysis_count) {
        for (const dayDataKey in dashboardVeri.file_analysis_count) {
            const dayData = dashboardVeri.file_analysis_count[dayDataKey];

            if (dayData && typeof dayData === 'object') {
                for (const key in dayData) {
                    const value = dayData[key];
                    if (totalsFileAnalysis[key]) {
                        totalsFileAnalysis[key] += value;
                    } else {
                        totalsFileAnalysis[key] = value;
                    }
                }
            }
        }
    }

    return (
        <div style={{ padding: "5px", maxWidth: "400px", margin: "0 auto", height: '280px' }}>
            <h6 className="title mt-1 mb-2" >
                CARD'LAR İÇİN BAŞLIK
            </h6>
            <div className="card-container">
                <div className="cardAnaliz">
                    <div className="icon-container">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
                    </div>
                    <div className="text-container">
                        <h6>Phishing</h6>
                        <p>{totals.phishing}</p>
                    </div>
                </div>
                <div className="cardAnaliz">
                    <div className="icon-container">
                        <FontAwesomeIcon icon={faFileAlt} size="2x" />
                    </div>
                    <div className="text-container">
                        <h6>Malware</h6>
                        <p>{totalsFileAnalysis.malicious}</p>
                    </div>
                </div>
                <div className="cardAnaliz">
                    <div className="icon-container">
                        <FontAwesomeIcon icon={faEnvelope} size="2x" />
                    </div>
                    <div className="text-container">
                        <h6>Spam</h6>
                        <p>{totals.spam}</p>
                    </div>
                </div>
                <div className="cardAnaliz">
                    <div className="icon-container">
                        <FontAwesomeIcon icon={faFilter} size="2x" />
                    </div>
                    <div className="text-container">
                        <h6>Content</h6>
                        <p>{totals.content_words_in_blacklist}</p>
                    </div>
                </div>

            </div>
            <p style={{ textAlign: "left", marginTop: "80px", color: "#666", fontSize: "11px" }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.            </p>
        </div>

    );
};

export default DataCards;
