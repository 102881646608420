import { ButtonBase, Card, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import useStyles from '../../Dashboard/styles';
import './index.css';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

const HtmlParser = (props) => {
  const [isEditting, setIsEditting] = useState(false);
  const [text, setText] = useState('');
  const classes = useStyles();

  useEffect(() => {
    if (props.initialValue) setText(`${props.initialValue}`);
    else setText('Click to Add some HTML');
  }, [props.initialValue]);

  const onClickHandler = () => {
    setIsEditting(true);
    props.onHtmlEditting(true);
  };

  const onChangeHandler = (e) => {
    setText(e.target.value);
  };

  const onEditClickHandler = () => {
    setIsEditting(false);
    props.onHtmlEditting(false);
    props.onEdit(text);
  };

  const onCancelClickHandler = () => {
    setIsEditting(false);
    props.onHtmlEditting(false);
  };

  return (
    <div style={{ display: 'flex', gap: '15px', maxWidth: '100%' }}>
      {!isEditting && (
        <div className='form-group' style={{ display: 'flex', flexDirection: 'row' }}>
          <Card
            raised
            elevation={6}
            className={classes.card}
            sx={{ alignItems: 'center', width: '100%', padding: 5 }}
            onClick={onClickHandler}
          >
            <ButtonBase component='div' name='test'>
              <Typography gutterBottom variant='h5' component='h2'>
                {parse(text)}
              </Typography>
            </ButtonBase>
          </Card>
        </div>
      )}

      {isEditting && (
        <>
          <div className='html-custom-flex'>
            <InputTextarea value={text} cols={120} rows={1} autoResize onChange={onChangeHandler} />
          </div>
          <div className='html-button-flex'>
            <Button
              label={props.isAddMode ? 'Add' : 'Edit'}
              className={props.isAddMode ? 'p-button-success' : 'p-button-info '}
              onClick={onEditClickHandler}
            />
            <Button
              label='Cancel'
              className='p-button-warning'
              type='button'
              onClick={onCancelClickHandler}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default HtmlParser;
