import api from '../../api';

export const signin = (formData) => api.post('/signIn', formData);
export const signup = (formData) => api.post('/signUp', formData);
export const refresh = (refreshToken) => api.post('/token/refresh', refreshToken);
export const passwordChange = (deger, passwordObj) => api.put(`/change-password/${deger}`, passwordObj);
export const whiteBlackListAdd = (model) => api.post('/senderdomain-whitelist-blacklist', model)
export const whiteBlackListUpdate = (model) => api.put('/senderdomain-whitelist-blacklist', model)
export const whiteBlackListDelete = (id) => api.delete(`/senderdomain-whitelist-blacklist/${id}`)
export const whiteBlackListGet = () => api.get('/senderdomain-whitelist-blacklist')
