import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { whiteBlackListGet, whiteBlackListAdd, whiteBlackListDelete } from '../../services/Api/auth';
import { QueryClient, useQuery } from "react-query";
import { useDispatch } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Tag } from "primereact/tag";
import Loading from '../UI/Loading';

const SenderDomainFilter = () => {
    console.log("geldik domain");
    const typeDomain = [
        {
            label: 'Blacklist', code: 'BL'
        },
        {
            label: 'Whitelist', code: 'WL'
        }
    ]
    const dispatch = useDispatch();
    const queryClient = new QueryClient();
    const toast = useRef(null);

    const [domain, setDomain] = useState("");
    const [domainType, setDomainType] = useState(null);
    const [domainModel, setDomainModel] = useState(null)
    const [deleteDomain, setDeleteDomain] = useState(null)
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [domainList, setDomainList] = useState([])
    const [deleteId, setDeleteId] = useState(null)
    const [loading, setLoading] = useState(true)


    const { data: dataInsertDomain } = useQuery(['whiteBlackListAdd', domainModel], () => {
        if (domainModel) {
            return whiteBlackListAdd(domainModel);
        }
    })
    const { data: dataDeleteDomain } = useQuery(['whiteBlackListDelete', deleteDomain], () => {
        if (deleteDomain) {
            return whiteBlackListDelete(deleteDomain);
        }
    })

    useEffect(() => {
        dispatch(whiteBlackListGet).then((res) => {
            console.log(".res", res);
            setDomainList(res?.data?.data)
            setLoading(false)

        })
    }, [dataInsertDomain, dataDeleteDomain])


    const addDomain = (item) => {
        if (domain && domainType) {
            const model = {
                domain: domain,
                status: domainType
            }
            setDomainModel(model)
            queryClient.invalidateQueries(['whiteBlackListAdd', model]);
            setDomain('')
            setDomainType(null)

        }
    }
    const handleReject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected' });
    };
    const statusDomain = (rowdata) => {
        return (
            <>

                <Tag
                    value={rowdata.status === 'WL' ? 'Whitelist' : 'Blacklist'}
                    style={rowdata.status === 'WL' ? { backgroundColor: '#b7a5ff' } : { backgroundColor: '#4b4848' }}
                />
            </>
        )
    }
    const updateSilBody = (rowdata) => {
        return (
            <>
                {/* <Button icon='pi pi-pencil' className="p-button-rounded p-button-primary p-button-outlined" onClick={() => updateDomainFunc(rowdata)} ></Button> */}
                <Button tooltip='Delete Domain' tooltipOptions={{ position: 'top' }} icon='pi pi-trash' className="p-button-rounded p-button-danger p-button-outlined ml-2" onClick={() => deleteDomainFunc(rowdata)} ></Button>
            </>
        )
    }
    const deleteDomainFunc = (rowdata) => {
        setDeleteId(rowdata.id);
        setDeleteDialog(true);
    }
    const deleteDomainFunction = () => {
        console.log("içeredee");
        setDeleteDomain(deleteId)
        queryClient.invalidateQueries(['whiteBlackListDelete', deleteDomain]);
    }
    return (
        <>
            <Toast ref={toast} />
            <div className="grid p-fluid mt-2">
                <div className="col-12 md:col-3 ml-3">
                    <i className="block ml-1 mb-1" style={{ fontSize: "14px", fontFamily: "sans-serif", fontStyle: "normal" }}>Domain <i style={{ color: "red" }}>*</i> </i>
                    <div className="p-inputgroup">
                        <InputText
                            type='text'
                            placeholder='Domain'
                            value={domain}
                            onChange={(e) => setDomain(e.target.value)}
                            maxLength={200} />
                    </div>
                </div>

                <div className="col-12 md:col-3">
                    <i className="block ml-1 mb-1" style={{ fontSize: "14px", fontFamily: "sans-serif", fontStyle: "normal" }}>Domain Type <i style={{ color: "red" }}>*</i> </i>
                    <div className="p-inputgroup">
                        <Dropdown
                            value={domainType}
                            options={typeDomain}
                            onChange={(e) => setDomainType(e.target.value)}
                            optionLabel="label"
                            optionValue='code'
                            placeholder="Select Type" />

                    </div>
                </div>


                <div className="col-12 md:col-3">
                    <i className='block'>&zwnj;</i>
                    <div className="p-inputgroup ">
                        <Button
                            className='p-button-m p-button-success p-button-outlined'
                            style={{ height: "40px" }}
                            label='Save'
                            icon="pi pi-save"
                            onClick={addDomain}></Button>

                    </div>
                </div>


            </div>
            <hr />
            {
                loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <div className="card">
                        <DataTable
                            // header='Registered Domains'
                            value={domainList}
                            responsiveLayout="scroll"
                            className='p-datatable-gridlines'
                        >
                            <Column style={{ width: '75%' }} field="domain" header="Domain"></Column>
                            <Column align={'center'} body={statusDomain} header="Status"></Column>
                            <Column align={'center'} body={updateSilBody} header="Action Menu"></Column>
                        </DataTable>
                    </div>
                )
            }

            <ConfirmDialog
                visible={deleteDialog}
                onHide={() => setDeleteDialog(false)}
                message="The selected domain will be deleted. Would you like to continue ?"
                header="Confirmation"
                icon="pi pi-exclamation-triangle"
                accept={() => deleteDomainFunction()}
                reject={() => {
                    handleReject();
                }}
            />
        </>
    )
}

export default SenderDomainFilter