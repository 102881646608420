import { defaultActionTypes, eventsActionTypes } from '../actionTypes';

const initialState = { isEventLoading: true, events: [] };

const reducers = (state = { ...initialState }, action) => {
  switch (action.type) {
    case defaultActionTypes.START_LOADING:
      return { ...state, isEventLoading: true };
    case defaultActionTypes.END_LOADING:
      return { ...state, isEventLoading: false };

    case eventsActionTypes.DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter((event) => event.id !== action.payload),
      };

    case eventsActionTypes.GET_EVENTS:
      return {
        ...state,
        events: action.payload,
      };

    case eventsActionTypes.EDIT_EVENT:
      return {
        ...state,
        events: state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event,
        ),
      };

    case eventsActionTypes.CREATE_EVENT:
      return { ...state, events: [...state.events, action.payload] };

    default:
      return state;
  }
};

export default reducers;
