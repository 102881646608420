import { bruteForceApi as api } from "../../services/Api";
import { toast } from "react-toastify";

export const getAllBruteForce = async () => {
  try {
    const {
      data: { data },
    } = await api.getAllBruteForce();

    return data;
  } catch (error) {
    toast.error(error.message);
    return [];
  }
};

export const createBruteForce = async (post) => {
  try {
    const { data } = await api.createBruteForce(post);

    if (data.message === "Success") {
      toast.success("Added Successfully!");
      return data.data;
    }
  } catch (error) {
    toast.error(error.message);
  }
  return null;
};

export const startBruteForce = async (post) => {
  try {
    const { data } = await api.startBruteForce(post);
console.log("data2222",data.data);
    if (data.message === "Success") {
      toast.success("Added Successfully!");
      return data.data;
    }
  } catch (error) {
    toast.error(error.message);
  }
  return null;
};
