import React, { memo } from "react";
import { ButtonBase, Card, Grid, Typography } from "@mui/material";
import useStyles from "../Dashboard/styles";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const Template = memo(({ id, name, html }) => {
  const classes = useStyles();
  const history = useHistory();

  const openTemplateDetailHandler = () => {
    history.push(`/template/edit/${id}`);
  };

  return (
    <Grid item key={id} xs={12} sm={6} md={6} lg={3}>
      <Card raised elevation={6} className={classes.card}>
        <ButtonBase
          component="span"
          name="test"
          className={classes.cardAction}
          onClick={openTemplateDetailHandler}
        >
          <Typography className={classes.title} gutterBottom variant="h5" component="h2">
            {name}
          </Typography>
        </ButtonBase>
      </Card>
    </Grid>
  );
});

Template.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  html: PropTypes.string,
};

export default Template;
