import React, { useState } from 'react';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import { useSelector } from 'react-redux';

const AppRightPanel = (props) => {
  const { authData } = useSelector((state) => state.authData);
  const [date, setDate] = useState(null);
  console.log("rightpanel");

  return (
    <div
      className={classNames('layout-rightmenu', {
        'layout-rightmenu-active': props.rightMenuActive,
      })}
      onClick={props.onRightMenuClick}
    >
      <button
        onClick={() => props.onRightMenuActiveChange(false)}
        className='layout-rightmenu-close p-link'
      >
        <i className='pi pi-times'></i>
      </button>
      <div className='user-detail-wrapper'>
        <div className='user-detail-content'>
          <img
            src='assets/layout/images/dashboard/kaylynn.png'
            alt='Profile'
            className='user-image'
          />
          <span className='user-name'>{authData?.data?.email}</span>
          <span className='user-number'>(111) 111-1111</span>
        </div>
      </div>
      <div>
        <Calendar value={date} onChange={(e) => setDate(e.value)} inline></Calendar>
      </div>
    </div>
  );
};

export default AppRightPanel;
