import { defaultActionTypes, smtpActionTypes } from '../actionTypes';

const initialState = { isSmtpLoading: true, smtps: [] };

const reducers = (state = { ...initialState }, action) => {
  switch (action.type) {
    case defaultActionTypes.START_LOADING:
      return { ...state, isSmtpLoading: true };
    case defaultActionTypes.END_LOADING:
      return { ...state, isSmtpLoading: false };

    case smtpActionTypes.DELETE_SMTP:
      return {
        ...state,
        smtps: state.smtps.filter((smtp) => smtp.id !== action.payload),
      };

    case smtpActionTypes.GET_SMTPS:
      return {
        ...state,
        smtps: action.payload,
      };

    case smtpActionTypes.EDIT_SMTP:
      return {
        ...state,
        smtps: state.smtps.map((smtp) => (smtp.id === action.payload.id ? action.payload : smtp)),
      };

    case smtpActionTypes.CREATE_SMTP:
      return { ...state, smtps: [...state.smtps, action.payload] };

    default:
      return state;
  }
};

export default reducers;
