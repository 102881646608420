import React from 'react';
import './dashboard.css'; // CSS dosyasını import ediyoruz

const DashboardCard = ({ title, total, completion, dailySize, icon }) => {
    return (
        <div className="card card-dashboard widget-overview-box widget-overview-box-1">
            <div className="card-header">
                {title}
                {/* <img style={{ maxHeight: '35px', maxWidth: '35px' }} src="gene-structure-svgrepo-com.svg" alt="rate" /> */}

                <i style={{ fontSize: '30px' }} className={icon} />
                {/* <i style={{ fontSize: '30px' }} className='pi pi-shield' /> */}



            </div>
            <hr />
            <div className="card-body">
                <div className="card-left">
                    <div className="total-number">{total}</div>
                    <div className="total-text">Total</div>
                </div>
                <div className="card-right">
                    {
                        completion !== "" ? (
                            <>

                                <div className="total-number">{total === 0 ? 0 : completion}% </div>

                                <div className="remediated">Blocked</div>
                            </>
                        ) : (
                            <>

                                <div className="total-number">{dailySize} </div>

                                <div className="remediated">Total Detected</div>
                            </>
                        )
                    }


                </div>

            </div>
        </div>
    );
};

export default DashboardCard;
