import React, { useEffect, useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import axios from 'axios';
import { Tooltip } from 'primereact/tooltip';
import Color from '../../components/Colors';

const EarthReactSimple = ({ threatData }) => {
    const [countriesData, setCountriesData] = useState([]);

    useEffect(() => {
        axios.get('/country.json')
            .then((response) => {
                setCountriesData(response.data);
            })
            .catch((error) => {
                console.error("Error fetching the data", error);
            });
    }, []);

    const getColorByVerdict = (verdict) => {
        switch (verdict.toUpperCase()) {
            case 'PHISHING':
                return Color[0].phishing;
            case 'MALICIOUS':
                return Color[0].malicious;
            case 'SUSPICIOUS':
                return Color[0].suspicious;
            case 'SPAM':
                return Color[0].spam;
            case 'SENDER DOMAIN BLACKLIST':
                return Color[0].sdb;
            case 'ATTACHMENT EXTENSION BLACKLIST':
                return Color[0].aeb;
            case 'CONTENT WORDS IN BLACKLIST':
                return Color[0].cwb;
            case 'NO THREAT FOUND':
                return Color[0].ntf;
            default:
                return '#c6fffe'; // Varsayılan renk
        }
    };

    return (

        <div style={{ height: '31rem' }} className="card">
            <small className="flex justify-content-center" style={{ fontSize: "16px", fontWeight: 'bold' }}>International Email Security Threat Map</small>
            <ComposableMap>
                <Geographies geography={countriesData}>
                    {({ geographies }) =>
                        geographies.map((geo) => {
                            const countryData = threatData.find(
                                (countryItem) => countryItem.Country === geo.id
                            );

                            const fillColor = countryData
                                ? getColorByVerdict(countryData.Verdict)
                                : '#e5e5e5';

                            const tooltipContent = countryData ? `
                        ${countryData.Country}
                         ${countryData.Verdict}
                            ${countryData.Count}
                
            ` : '';

                            const geoId = `geo-${geo.id}`;

                            return (
                                <g key={geo.rsmKey}>
                                    <Geography
                                        id={geoId}
                                        geography={geo}
                                        fill={fillColor}
                                        stroke="#FFF"
                                        data-pr-tooltip={tooltipContent}
                                        data-pr-position="top"
                                        data-pr-html="true"
                                        data-pr-mousetrack="true"
                                    />
                                    {countryData && (
                                        <Tooltip target={`#${geoId}`} content={<div dangerouslySetInnerHTML={{ __html: tooltipContent }} />} />
                                    )}
                                </g>
                            );
                        })
                    }
                </Geographies>
            </ComposableMap>
        </div>
    );
};

export default EarthReactSimple;