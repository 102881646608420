import { Badge } from "primereact/badge"
import { Column } from "primereact/column";
import React, { useState,useEffect,useRef } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getAllSenderDomainAnalysis, getAllSenderDomainAnalysisAntiPhishing } from "../../store/actions/senderDomainAnalyzer";
import Loading from "../UI/Loading";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ExcelExport from "./ExcelExport";
import Colors from "../Colors";
import { Tag } from "primereact/tag";
import DomainFilter from "./DomainFilter";
import { Toast } from 'primereact/toast';
import DateFormat from "../DateFormat";
const SenderDomainAnalyzer = () => {

    // const [refetchInterval, setRefetchInterval] = useState(30000);
    const [queryString, setQueryString] = useState('');
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [pageMailAnalysis, setPageMailAnalysis] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [domainWord, setDomainWord] = useState('');
    const [domainFilterAction, setDomainFilterAction] = useState(false);
    const [basarili, setBasarili] = useState(false);
    const toast = useRef(null);

    const { isLoading, data } = useQuery(
        ["senderdomainanalysis", queryString],
        () => getAllSenderDomainAnalysis(queryString),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            // refetchInterval,
            // onerror: () => setRefetchInterval(0),
        }
    );
    const [items, setItems] = useState([]);
    useEffect(() => {
        if (pageMailAnalysis?.page > 0) {
            setQueryString(`?page=${pageMailAnalysis?.page + 1}`)
        } else {
            setQueryString('')
        }
    }, [pageMailAnalysis])

    useEffect(() => {
        let dataSender = data?.results?.data
        setItems(dataSender?.sort(function compare(a, b) {
            var dateA = new Date(a.analyze_time);
            var dateB = new Date(b.analyze_time);
            return dateB - dateA;
        }));
        return () => { };
    }, [data]);

    useEffect(() => {
        if (basarili) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Domain filter added' });

        }

    },[basarili])
    
    if (isLoading) {
        return <Loading />;
    };


    const resultBodyStatusTemplate = (rowData) => {
        let data = rowData?.verdict?.replace(/_/g, ' ');



        return (
            <>
                <Tag
                    value={data?.replace(/\b\w/g, char => char.toUpperCase())}

                    style=
                    {data === "malicious" ? { color: Colors[0].yazi, backgroundColor: Colors[0].malicious, boxShadow: `0 0 10px ${Colors[0].malicious}` }
                        : data === "spam" ? { color: Colors[0].yazi, backgroundColor: Colors[0].spam, boxShadow: `0 0 10px ${Colors[0].spam}` }
                            : data === "suspicious" ? { color: Colors[0].yazi, backgroundColor: Colors[0].suspicious, boxShadow: `0 0 10px ${Colors[0].suspicious}` }


                                : { backgroundColor: Colors[0].ntf, color: Colors[0].yazi, boxShadow: `0 0 10px ${Colors[0].ntf}` }}


                />
            </>
        );
    };


    const analyzeTime = (rowData) => {
        return (
            <>
        <DateFormat dateString={rowData?.analyze_time} />
            </>
        )

    }
    const filtrele = (value) => {
        let filteredData = data?.results?.data;
        console.log("filteredData", filteredData);
        if (value) {
            filteredData = data?.results?.data.filter(item => {
                return Object.keys(item).some(key =>
                    String(item[key]).toLowerCase().includes(value.toLowerCase())
                ) || item.url?.toLowerCase().includes(value?.toLowerCase())
                    || item.verdict?.toLowerCase().includes(value?.toLowerCase());
            });
            setItems(filteredData)
        } else {
            setItems(data?.results?.data)
        }
    }
    const onFilter = (e) => {
        setGlobalFilterValue(e.target.value);
        filtrele(e.target.value);

    };
    const onPageChange = (e) => {
        setCurrentPage(e.page);
        setPageMailAnalysis(e)
    };
    const addDomain = (data) => {
        console.log("dataas", data);
        setDomainWord(data?.domain)
        setDomainFilterAction(true)

    }
    const domainFilterAdd = (rowData) => {
        let data = rowData

        return (
            <>
                {/* <Button icon='pi pi-pencil' className="p-button-rounded p-button-primary p-button-outlined" onClick={() => updateDomainFunc(rowdata)} ></Button> */}
                <Button tooltip='Add Domain Filter' tooltipOptions={{ position: 'top' }} icon='pi pi-plus' className=" p-button-outlined ml-2" onClick={() => addDomain(data)} ></Button>
            </>
        )
    };

    const renderHeader = () => {
        return (
            <>
                <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                    <h5 className="m-0">Sender Domain Analysis</h5>
                    <div>
                        <span className="mt-2 md:mt-0 p-input-icon-left">
                            <i className="pi pi-search" />
                            <InputText
                                tooltip="It searches file name fields. "
                                tooltipOptions={{ position: 'top' }}
                                value={globalFilterValue}
                                onChange={onFilter}
                                placeholder="Global Search"
                            />

                        </span>

                        <span className="mt-2 md:mt-0 ">

                            <ExcelExport data={items} />
                        </span>
                        <span className="mt-2 md:mt-0 ">
                            <Button
                                icon="pi pi-question"
                                className="ml-3 p-button-roundery p-button-outlined"

                                style={{ color: "#44486D", borderColor: "#44486D" }}
                            />
                        </span>
                    </div>
                </div >


            </>
        );
    };
    return (
        <>
            <Toast ref={toast} />

            <DataTable
                value={items}
                data={data}
                setPageMailAnalysis={setPageMailAnalysis}
                dataKey="id"
                responsiveLayout="scroll"
                className='p-datatable-gridlines'
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown TotalRecords"
                emptyMessage="No data found."
                paginator
                stripedRows
                rows={25}
                globalFilter={globalFilterValue}
                header={() => renderHeader()}
                onPage={onPageChange}
                first={currentPage * 25}
                totalRecords={data?.count}
                lazy>


                <Column
                    field="domain"
                    header="Sender Domain"
                    style={{ textAlign: 'center' }}
                    alignHeader={'center'}

                ></Column>
                <Column
                    header="Analyze Time"
                    body={analyzeTime}
                    style={{ textAlign: 'center' }}
                    alignHeader={'center'}

                ></Column>

                <Column
                    field="verdict"
                    header="Verdict"
                    style={{ textAlign: 'center' }}
                    alignHeader={'center'}
                    body={resultBodyStatusTemplate}

                ></Column>
                <Column
                    header="Action"
                    style={{ textAlign: 'center' }}
                    alignHeader={'center'}
                    body={domainFilterAdd}
                ></Column>
            </DataTable>
            {domainFilterAction && <DomainFilter data={domainWord} setDomainFilterAction={setDomainFilterAction} setBasarili={setBasarili} />}

        </>
    );
};

export default SenderDomainAnalyzer;