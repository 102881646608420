import React, { useState, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Badge } from "primereact/badge";
import { getAllMalwareBazaarbyAntiPhishing } from "../../../store/actions/malwareBazaar";
import Colors from '../../Colors';
const Attachment = ({ setCode, data }) => {


    const [malwareBazaarbyAntiPhishing, setMalwareBazaarbyAntiPhishing] = useState(null);
    const [displayHashbox, setDisplayHashbox] = useState(false);
    const [selectAttachment, setSelectAttachment] = useState(null);

    useEffect(() => {
        getAllMalwareBazaarbyAntiPhishing(data?.id).then((res) => {
            console.log("ressss123", res);
            setMalwareBazaarbyAntiPhishing(res?.data)
            if (res) {
                setDisplayHashbox(true)

            }
        })
    }, [data])

    console.log("malwareBazaarbyAntiPhishing", malwareBazaarbyAntiPhishing);

    const onHideHashbox = (e) => {
        setDisplayHashbox(false)
        setSelectAttachment(null)
        setCode(null)

    }
    const fileNameBodyStatusTemplate = (rowData) => {
        return (
            selectAttachment ? (
                <>
                    {rowData.file_name}
                </>
            ) : (
                <>
                    {rowData.file_name.substr(0, 30)}
                </>
            )
        )
    };
    const sha256BodyStatusTemplate = (rowData) => {
        return (
            selectAttachment ? (

                <>
                    {rowData.sha256}
                </>
            ) : (
                <>
                    {rowData.sha256.substr(0, 30) + "..."}
                </>
            )
        )
    };

    const resultBodyVerdict = (rowData) => {
        if (rowData.verdict !== "-") {
            console.log("111rowData24", rowData);
            let data = rowData?.verdict?.replace(/_/g, ' ');
            console.log("111datadata", data);

            return (
                <>
                    <Badge
                        value={data.replace(/\b\w/g, char => char.toUpperCase())}
                        style={{
                            backgroundColor: data === "malicious" ? Colors[0].malicious
                                : data === 'extension blacklist' ? Colors[0].aeb :
                                    data === "clean" ? "#0bd18a" : "#b3e5fc",
                            color: data === "clean" ? "#256029" : Colors[0].yazi,
                            boxShadow: data === 'malicious' ? `0 0 10px ${Colors[0].malicious}` :
                                data === 'extension blacklist' ? `0 0 10px ${Colors[0].aeb}` : ' 0 0 10px white'

                        }}

                    // color: "#c63737", backgroundColor: "#ffcdd2", boxShadow: "0 0 10px red"
                    />
                </>
            );
        } else {
            return ('-');
        }
    };
    return (
        <Dialog
            header="Attachment"
            visible={displayHashbox}
            style={{ width: '80vw', height: "40vw" }}
            onHide={() => onHideHashbox()}
            modal
            dismissableMask
            maximizable
        >
            <div className="card" >
                <DataTable
                    value={malwareBazaarbyAntiPhishing}
                    className="p-datatable gridlines"
                    selection={selectAttachment}
                    onSelectionChange={(e) => setSelectAttachment(e.value)}
                    selectionMode="single"
                    dataKey="id"
                >
                    <Column
                        field="file_name"
                        header="File Name"
                        body={fileNameBodyStatusTemplate}
                        style={{ textAlign: 'left' }}
                        alignHeader={'center'}
                    ></Column>
                    
                    <Column
                        field="verdict"
                        header="Verdict"
                        style={{ textAlign: 'center' }}
                        alignHeader={'center'}
                        body={resultBodyVerdict}
                    ></Column>
                </DataTable>
            </div>
        </Dialog>
    )
}

export default Attachment