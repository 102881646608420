import React from "react";
import { LinearProgress , Container } from "@mui/material";

const Loading = () => {
  return (
    <Container maxWidth="xl" sx={{ margin: 5 }}>
      <center>
        <LinearProgress  color="success" />
      </center>
    </Container>
  );
};

export default Loading;
