import { defaultActionTypes } from "../actionTypes";

const initialState = { isDashboardLoading: true, dashboardData: {} };

const reducers = (state = { ...initialState }, action) => {
  switch (action.type) {
    case defaultActionTypes.START_LOADING:
      return { ...state, isDashboardLoading: true };
    case defaultActionTypes.END_LOADING:
      return { ...state, isDashboardLoading: false };

    case defaultActionTypes.GET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload,
      };

    default:
      return state;
  }
};

export default reducers;
