import React, { useEffect, useState } from 'react';
import { withRouter, useLocation, Route } from 'react-router-dom';
import App from './App';
import SignIn from './components/Auth/SignIn';
import { Error } from './pages/Error';
import { NotFound } from './pages/NotFound';
import { Access } from './pages/Access';
import SignUp from './components/Auth/SignUp';

const AppWrapper = (props) => {
  const [colorScheme, setColorScheme] = useState('light');
  const [theme, setTheme] = useState('blue');
  const [componentTheme, setComponentTheme] = useState('blue');
  console.log("appWrappers");
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(()=> {
    const theme = sessionStorage.getItem('theme');
    console.log('tema',theme);
    if(theme){
      onColorSchemeChange(theme);
      setColorScheme(theme);

    }else{
      sessionStorage.setItem('theme',colorScheme)
    }
  },[])
  const onColorSchemeChange = (scheme) => {
    console.log("scheme",scheme);
    changeStyleSheetUrl('layout-css', 'layout-' + scheme + '.css', 1);
    changeStyleSheetUrl('theme-css', 'theme-' + scheme + '.css', 1);
    setColorScheme(scheme);
    sessionStorage.setItem('theme',scheme)
  };

  const changeStyleSheetUrl = (id, value, from) => {
    console.log("idi", id,value,from);
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');

    if (from === 1) {
      // which function invoked this function - change scheme
      urlTokens[urlTokens.length - 1] = value;
    } else if (from === 2) {
      // which function invoked this function - change color
      urlTokens[urlTokens.length - 2] = value;
    }

    const newURL = urlTokens.join('/');

    replaceLink(element, newURL);
  };

  const onMenuThemeChange = (theme) => {
    console.log("theme",theme);
    const layoutLink = document.getElementById('layout-css');
    const href = 'assets/layout/css/' + theme + '/layout-' + colorScheme + '.css';

    replaceLink(layoutLink, href);
    setTheme(theme);
  };

  const onComponentThemeChange = (theme) => {
    const themeLink = document.getElementById('theme-css');
    const href = 'assets/theme/' + theme + '/theme-' + colorScheme + '.css';

    replaceLink(themeLink, href);
    setComponentTheme(theme);
  };

  const replaceLink = (linkElement, href, callback) => {
    const id = linkElement.getAttribute('id');
    const cloneLinkElement = linkElement.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

    cloneLinkElement.addEventListener('load', () => {
      linkElement.remove();
      cloneLinkElement.setAttribute('id', id);

      if (callback) {
        callback();
      }
    });
  };
  switch (props.location.pathname) {
    case '/signIn':
      return <Route path='/signIn' render={() => <SignIn colorScheme={colorScheme} />} />;
    // case '/signUp':
    //   return <Route path='/signUp' render={() => <SignUp colorScheme={colorScheme} />} />;
    case '/error':
      return <Route path='/error' render={() => <Error colorScheme={colorScheme} />} />;
    case '/notfound':
      return <Route path='/notfound' render={() => <NotFound colorScheme={colorScheme} />} />;
    case '/access':
      return <Route path='/access' render={() => <Access colorScheme={colorScheme} />} />;
    default:
      return (
        <App
          colorScheme={colorScheme}
          onColorSchemeChange={onColorSchemeChange}
          componentTheme={componentTheme}
          onComponentThemeChange={onComponentThemeChange}
          theme={theme}
          onMenuThemeChange={onMenuThemeChange}
        />
      );
  }
};

export default withRouter(AppWrapper);