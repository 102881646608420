import { Badge } from "primereact/badge";
import { Column } from "primereact/column";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getAllUrlAnalysis, getAllUrlAnalysisbyAntiPhishing } from "../../store/actions/urlanalyzer";
import { DataTable } from "primereact/datatable";
import Loading from "../UI/Loading";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ExcelExport from "./ExcelExport";
import Colors from "../Colors";
import { Tag } from "primereact/tag";
import DateFormat from "../DateFormat";
const UrlAnalyzer = () => {

  const { id } = useParams();
  const [refetchInterval, setRefetchInterval] = useState(30000);
  const [queryString, setQueryString] = useState('');
  const [pageMailAnalysis, setPageMailAnalysis] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  const { isLoading, data } = useQuery(
    ["urlanalysis", queryString],

    () => {
      if (id) {
        return getAllUrlAnalysisbyAntiPhishing(id);
      } else {
        return getAllUrlAnalysis(queryString);
      }
    },
    () => getAllUrlAnalysis(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchInterval,
      onError: () => setRefetchInterval(0),
    }
  );
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (pageMailAnalysis?.page > 0) {
      setQueryString(`?page=${pageMailAnalysis?.page + 1}`)
    } else {
      setQueryString('')
    }
  }, [pageMailAnalysis])

  useEffect(() => {
    let dataUrl = data?.results?.data
    setItems(dataUrl?.sort(function compare(a, b) {
      var dateA = new Date(a.analyze_time);
      var dateB = new Date(b.analyze_time);
      return dateB - dateA;
    }));
    // setLoading(false);
    return () => { };
  }, [data]);

  console.log("items sort ", items);
  if (isLoading) {
    return <Loading />;
  }


  // const verdictBodyTemplate = (rowData) => {

  //   console.log("rowDat3a", rowData);
  //   let data = rowData.verdict.replace(/_/g, ' ');

  //   return (
  //     <>
  //       <Badge
  //         value={data.replace(/\b\w/g, char => char.toUpperCase())}
  //         style={data === "malicious" ? { color: "#c63737", backgroundColor: "#ffcdd2", boxShadow: "0 0 10px red" } : data === "suspicious" ? { backgroundColor: "#ffd8b2", color: "#805b36" } : { backgroundColor: "#b3e5fc", color: "#23547b" }}

  //       />
  //     </>
  //   );
  // };
  const verdictBodyTemplate = (rowData) => {
    let data = rowData?.verdict?.replace(/_/g, ' ');
    return (
      <>
        <Tag
          value={data?.replace(/\b\w/g, char => char.toUpperCase())}

          style=
          {data === "malicious" ? { color: Colors[0].yazi, backgroundColor: Colors[0].malicious, boxShadow: `0 0 10px ${Colors[0].malicious}` }
            : data === "no report" ? { color: Colors[0].yazi, backgroundColor: Colors[0].ntf, boxShadow: `0 0 10px ${Colors[0].ntf}` }
              : data === "suspicious" ? { color: Colors[0].yazi, backgroundColor: Colors[0].suspicious, boxShadow: `0 0 10px ${Colors[0].suspicious}` }
                : { backgroundColor: Colors[0].ntf, color: Colors[0].yazi, boxShadow: `0 0 10px ${Colors[0].ntf}` }}
        />
      </>
    );
  };
  const analyzeTime = (rowData) => {
    return (
      <>
        <DateFormat dateString={rowData?.analyze_time} />
      </>
    )
  }
  const urlTemplate = (rowData) => {
    let url = rowData.url.split('/')
    const sonUrl = url.slice(0, 3).join('/');
    return (
      <>
        {sonUrl}

      </>
    )
  }

  const filtrele = (value) => {
    let filteredData = data?.results?.data;
    console.log("filteredData", filteredData);
    if (value) {
      filteredData = data?.results?.data.filter(item => {
        return Object.keys(item).some(key =>
          String(item[key]).toLowerCase().includes(value.toLowerCase())
        ) || item.url?.toLowerCase().includes(value?.toLowerCase())
          || item.verdict?.toLowerCase().includes(value?.toLowerCase());
      });
      setItems(filteredData)
    } else {
      setItems(data?.results?.data)
    }
  }
  const onFilter = (e) => {
    setGlobalFilterValue(e.target.value);
    filtrele(e.target.value);

  };
  const onPageChange = (e) => {
    setCurrentPage(e.page);
    setPageMailAnalysis(e)
  };

  const renderHeader = () => {
    return (
      <>
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 className="m-0">Url Analysis</h5>
          <div>
            <span className="mt-2 md:mt-0 p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                tooltip="It searches file name fields. "
                tooltipOptions={{ position: 'top' }}
                value={globalFilterValue}
                onChange={onFilter}
                placeholder="Global Search"
              />

            </span>

            <span className="mt-2 md:mt-0 ">

              <ExcelExport data={items} />
            </span>
            <span className="mt-2 md:mt-0 ">
              <Button
                icon="pi pi-question"
                className="ml-3 p-button-roundery p-button-outlined"

                style={{ color: "#44486D", borderColor: "#44486D" }}
              />
            </span>
          </div>
        </div >


      </>
    );
  };

  return (
    // <div className="card">
    <DataTable
      dataKey="id"
      value={items}
      setPageMailAnalysis={setPageMailAnalysis}
      responsiveLayout="scroll"
      className='p-datatable-gridlines'
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown TotalRecords"
      emptyMessage="No data found."
      paginator
      stripedRows
      rows={25}
      globalFilter={globalFilterValue}
      // rowsPerPageOptions={[10, 25, 50]}
      header={() => renderHeader()}
      onPage={onPageChange}
      first={currentPage * 25}
      totalRecords={data?.count}
      lazy
    >

      <Column

        header="URL"
        style={{ textAlign: 'center', width: '60%' }}
        alignHeader={'center'}
        body={urlTemplate}
      ></Column>

      <Column

        header="Analyze Time"
        style={{ textAlign: 'center', width: '15%' }}
        alignHeader={'center'}
        body={analyzeTime}

      ></Column>
      <Column
        field="verdict"
        header="Verdict"
        style={{ textAlign: 'center', width: '25%' }}
        alignHeader={'center'}
        body={verdictBodyTemplate}

      ></Column>

    </DataTable>
    // </div>
  );
};

export default UrlAnalyzer;
