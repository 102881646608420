import React from 'react';

const QrKod = ({ svgContent }) => {

    return (

        <div style={{ background: '#FFF' }} className='flex flex-column align-items-center p-4'>
            <h5 className="text-center mb-3">Two Factor Registration</h5>
            <p className='text-center mb-3'>
                To log in to the application, open the, <span style={{ fontWeight: 'bold' }}>Microsoft Authenticator</span>  or <span style={{ fontWeight: 'bold' }}>Google Authenticator</span>  application and scan the QR Code.
            </p>
            <div dangerouslySetInnerHTML={{ __html: svgContent }} className="mb-3" />
            <p> If you have access to the 6-digit code after the process, you can close this screen and return to the login screen.</p>
            <div className='flex justify-content-around w-100'>
                <img src="/assets/data/googleaut.png" alt="Google Authenticator" style={{ width: '100px', height: '100px' }} />
                <img src="/assets/data/micaut.png" alt="Microsoft Authenticator" style={{ width: '100px', height: '100px' }} />

            </div>
        </div>
    );
}

export default QrKod;
