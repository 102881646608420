import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import storage from "redux-persist/lib/storage";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";

const composeEnhancers =
  (typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const persistConfig = {
  key: "authData",
  storage: storage,
  whitelist: ["authData"], // which reducer want to store
};
console.log("storage",storage);
const pReducer = persistReducer(persistConfig, rootReducer);

const middlewareEnhancer = applyMiddleware(thunk, logger);
const store = createStore(pReducer, composeEnhancers(middlewareEnhancer));

const persistor = persistStore(store);
export { persistor, store };
