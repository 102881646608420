import React, { useEffect, useState } from "react";
import {
  getAllMalwareBazaar,
  getAllMalwareBazaarbyAntiPhishing,
} from "../../store/actions/malwareBazaar";
import { Column } from "primereact/column";
import { useQuery } from "react-query";
import Loading from "../UI/Loading";
import { useParams } from "react-router-dom";
import { Sidebar } from 'primereact/sidebar';
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import Colors from "../Colors";
import { Tag } from "primereact/tag";
import ExcelExport from "./ExcelExport";
import DateFormat from "../DateFormat";
const MalwareBazaar = () => {

  const { id } = useParams();
  const [refetchInterval, setRefetchInterval] = useState(30000);
  const [turkce, setTurkce] = useState(true);
  const [visible, setVisible] = useState(false)
  const [queryString, setQueryString] = useState('');
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [pageMailAnalysis, setPageMailAnalysis] = useState(null);

  const { isLoading, data } = useQuery(
    ["malwarebazaar", queryString],

    () => {
      if (id) {
        return getAllMalwareBazaarbyAntiPhishing(id);
      } else {
        return getAllMalwareBazaar(queryString);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchInterval,
      onError: () => setRefetchInterval(0),
    }
  );
  const [items, setItems] = useState([]);
  useEffect(() => {
    let dataUrl = data?.results?.data
    setItems(dataUrl?.sort(function compare(a, b) {
      var dateA = new Date(a.analyze_time);
      var dateB = new Date(b.analyze_time);
      return dateB - dateA;
    }));

  }, [data]);

  useEffect(() => {
    if (pageMailAnalysis?.page > 0) {
      setQueryString(`?page=${pageMailAnalysis?.page + 1}`)
    } else {
      setQueryString('')
    }
  }, [pageMailAnalysis])




  if (isLoading) {
    return <Loading />;
  }



  const verdictBodyTemplate = (rowData) => {
    let data = rowData?.verdict?.replace(/_/g, ' ');



    return (
      <>
        <Tag
          value={data?.replace(/\b\w/g, char => char.toUpperCase())}

          style=
          {data === "malicious" ? { color: Colors[0].yazi, backgroundColor: Colors[0].malicious, boxShadow: `0 0 10px ${Colors[0].malicious}` }
            : data === "no report" ? { color: Colors[0].yazi, backgroundColor: Colors[0].ntf, boxShadow: `0 0 10px ${Colors[0].ntf}` }
              : data === "suspicious" ? { color: Colors[0].yazi, backgroundColor: Colors[0].suspicious, boxShadow: `0 0 10px ${Colors[0].suspicious}` }


                : { backgroundColor: Colors[0].ntf, color: Colors[0].yazi, boxShadow: `0 0 10px ${Colors[0].ntf}` }}


        />
      </>
    );
  };
  const customIcons = (
    <React.Fragment>
      <Button
        className="p-button-rounded p-button-outlined p-button-sm mr-2"
        style={{ fontSize: "10px", color: "#44486D" }}
        label={turkce ? "EN" : "TR"}
        onClick={() => setTurkce(!turkce)}
      />
    </React.Fragment>
  );
  const header = (
    <div className="table-header">
      Products
      <Button icon="pi pi-refresh" />
    </div>
  );


  const analyzeTime = (rowData) => {
    return (
      <>
        <DateFormat dateString={rowData?.analyze_time} />
      </>
    )

  }
  const filtrele = (value) => {
    let filteredData = data?.results?.data;
    console.log("filteredData", filteredData);
    if (value) {
      filteredData = data?.results?.data.filter(item => {
        return Object.keys(item).some(key =>
          String(item[key]).toLowerCase().includes(value.toLowerCase())
        ) || item.file_name?.toLowerCase().includes(value?.toLowerCase())
          || item.verdict?.toLowerCase().includes(value?.toLowerCase());
      });
      setItems(filteredData)
    } else {
      setItems(data?.results?.data)
    }
  }
  const onFilter = (e) => {
    setGlobalFilterValue(e.target.value);
    filtrele(e.target.value);

  };
  const renderHeader = () => {
    return (
      <>
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <h5 className="m-0">File Analysis</h5>
          <div>
            <span className="mt-2 md:mt-0 p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                tooltip="It searches file name fields. "
                tooltipOptions={{ position: 'top' }}
                value={globalFilterValue}
                onChange={onFilter}
                placeholder="Global Search"
              />

            </span>

            <span className="mt-2 md:mt-0 ">

              <ExcelExport data={items} />
            </span>
            <span className="mt-2 md:mt-0 ">
              <Button
                icon="pi pi-question"
                className="ml-3 p-button-roundery p-button-outlined"

                style={{ color: "#44486D", borderColor: "#44486D" }}
              />
            </span>
          </div>
        </div >


      </>
    );
  };

  const onPageChange = (e) => {
    setCurrentPage(e.page);
    setPageMailAnalysis(e)
  };

  return (
    <>

      <>
        <DataTable
          value={items}
          dataKey="id"
          paginator
          globalFilter={globalFilterValue}
          stripedRows
          rows={25}
          // rowsPerPageOptions={[10, 25, 50]}
          className="p-datatable-gridlines"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown TotalRecords"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          emptyMessage="No data found."
          header={() => renderHeader()}
          responsiveLayout="scroll"
          onPage={onPageChange}
          first={currentPage * 25}
          totalRecords={data?.count}
          lazy
        >
          {/* <DataTable
          title="Analyzed Files"
          rows={items}
          data={data}
          setPageMailAnalysis={setPageMailAnalysis}

        > */}

          <Column
            field="file_name"
            header="File Name"
            style={{ textAlign: 'left' }}
            alignHeader={'center'}

          ></Column>
          <Column
            // field="analyze_time"
            header="Analyze Time"
            body={analyzeTime}
            alignHeader={'center'}

            style={{ textAlign: 'center' }}

          ></Column>

          <Column
            field="verdict"
            header="Verdict"
            alignHeader={'center'}
            style={{ textAlign: 'center' }}
            body={verdictBodyTemplate}

          ></Column>


          {/* </CustomDataTable> */}

        </DataTable >
      </>


      <Sidebar
        className="p-sidebar-sm"
        visible={visible}
        position="right"
        onHide={() => setVisible(false)}
        icons={customIcons}>
        {
          !turkce ? (
            <>
              <h3>Hash Box Yardım</h3>
              <ol className="m-0" style={{ lineHeight: "1.6" }}>
                <li><b>API entegrasyonuyla</b> çalışan bu modül zararlı dosyalar için benzersiz olarak atanan imzaları zararlılardan elde ederek panele ekler.</li>
                <li>Ekler vasıtasıyla gelen ya da panelden tarattırılan zararlıların imzalarını toplu halde burada görebilirsiniz</li>
                <li>İmzalar <b>Virtusotal, Hybrid-Analysis ve Malwarebazaar</b> gibi geniş zararlı arşivlerinden API vasıtasıyla elde edilmektedir.</li>
              </ol>
            </>
          ) : (
            <>
              <h3>Hash Box Help</h3>
              <ol className="m-0" style={{ lineHeight: "1.6" }}>
                <li><b>Working with API integration,</b> this module obtains uniquely assigned signatures for malicious files from malicious files and adds them to the panel.</li>
                <li>Here you can see the signatures of the pests that come through attachments or scanned from the panel.</li>
                <li>Signatures are obtained via API from large malware archives such as <b>Virtusotal, Hybrid-Analysis and Malwarebazaar.</b></li>
              </ol>
            </>
          )
        }

      </Sidebar>
    </>
  );
};

export default MalwareBazaar;
