import React from "react";

const AppFooter = (props) => {
  return (
    <div className="layout-footer">
      <div className="footer-logo-container"></div>
      <span className="copyright" style={{color : "black",fontWeight:"bold"}}>&#169; xGuard - v1.0</span>
    </div>
  );
};

export default AppFooter;
