import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Panel } from 'primereact/panel';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { QueryClient, useQuery } from "react-query";
import { getSpf, insertSpf } from '../../services/Api/spf';


const Spf = () => {



    const message = [
        { code: 'block', message: 'Delete entire message' },
        { code: 'pass', message: 'Do not intercept message' },
        { code: 'spam', message: 'Redirect to spam folder' },

    ]

    const [visible, setVisible] = useState(false);
    const [saveButton, setSaveButton] = useState(false);
    const [spfVeri, setSpfVeri] = useState('');
    const [tagSubject, setTagSubject] = useState('');
    const toast = useRef(null);
    const [actions, setActions] = useState({
        id: -1,
        created_at: new Date(),
        deleted_at: null,
        fail_status: {
            is_tag_subject: false,
            message_verdict: "",
            send_notification: false,
            tag_subject: ""

        },
        neutral_status: {
            is_tag_subject: false,
            message_verdict: "",
            send_notification: false,
            tag_subject: ''

        },
        none_status: {
            is_tag_subject: false,
            message_verdict: "",
            send_notification: false,
            tag_subject: ''
        },
        permerror_status: {
            is_tag_subject: false,
            message_verdict: "",
            send_notification: false,
            tag_subject: ''
        },
        softfail_status: {
            is_tag_subject: false,
            message_verdict: "",
            send_notification: false,
            tag_subject: ''
        },
        temperror_status: {
            is_tag_subject: false,
            message_verdict: "",
            send_notification: false,
            tag_subject: ''
        }



    })
    console.log("actions ilk ", actions);
    const dispatch = useDispatch();
    const queryClient = new QueryClient();

    useEffect(() => {
        dispatch(getSpf).then((res) => {
            console.log(".res", res);
            if (res?.data?.data?.length !== 0) {
                console.log("girdik");
                setVisible(true)
                setSpfVeri(res?.data?.data)
                setActions(res?.data?.data)
            }


        })
    }, [])

    const data = useQuery(['insertSpf', actions, saveButton], () => {
        if (saveButton) {
            console.log("actions if ", actions);
            return insertSpf(actions);
        }
    });

    console.log("data2", data);



    const handleChange = (e) => {
        const newValue = e.target.value;
        const updatedData = Object.entries(actions).reduce((acc, [key, value]) => {
            if (typeof value === 'object' && value !== null && ('tag_subject' in value)) {
                acc[key] = { ...value, tag_subject: newValue };
            } else {
                acc[key] = value;
            }
            return acc;
        }, {});
        setActions(updatedData);

    };


    const saveSpf = () => {
        setSaveButton(true)
    }

    const footerButton = (options) => {
        const className = `${options.className} flex flex-wrap align-items-center justify-content-end gap-3`;

        return (
            <div className={className}>
                {/* <div className='col-2 flex align-items-start   '>
                    <span style={{ fontWeight: 'bold' }}>Tag Subject <i className='pi pi-info-circle ml-2'></i></span>
                </div> */}
                <div className='flex align-items-center p-inputgroup' >
                    <span className="p-inputgroup-addon">
                        <label> Tag:</label>
                    </span>
                    <InputText
                        value={actions.fail_status.tag_subject !== '' ? actions.fail_status.tag_subject : null}
                        style={{ width: '100%' }} placeholder="SPF violation" onChange={(e) => handleChange(e)}
                        maxLength={200} />
                    <Button className='ml-2' label='Update' icon="pi pi-save" severity="secondary"  text onClick={() => saveSpf()}></Button>

                </div>
              
            </div>
        );
    };
    return (


        <div className='card'>
            <Toast ref={toast} />

            {/* <div className="grid p-fluid mt-2">
                <div className="col-12 md:col-6 ml-2">
                    <div className="p-inputgroup ">
                        <div className="flex align-items-center">
                            <Checkbox onChange={(e) => setVisible(!visible)} checked={visible} />

                            <label className="ml-2">{visible ? 'Disabled SPF' : 'Enable SPF'}</label>
                        </div>

                    </div>
                </div>
            </div>
            <hr /> */}

            <>
                <Panel header="Actions" toggleable>
                    <div className='p-col-12 flex'>
                        <div className='col-2 flex align-items-center   '>
                            <span style={{ fontWeight: 'bold' }}>Pass :</span>
                        </div>
                        <div className='col-4 flex align-items-center'>
                            <span>Do not intercept messages <i className='pi pi-info-circle'></i></span>
                        </div>

                    </div>
                    <div className='p-col-12 flex'>
                        <div className='col-2 flex align-items-center   '>
                            <span style={{ fontWeight: 'bold' }}>Fail :</span>
                        </div>
                        <div className='col-4 flex align-items-center'>
                            <Dropdown
                                style={{ minWidth: '300px' }}
                                value={actions?.fail_status?.message_verdict}
                                onChange={(e) => setActions({
                                    ...actions,
                                    fail_status: {
                                        ...actions?.fail_status,
                                        message_verdict: e.value
                                    }
                                })}
                                options={message}
                                optionValue='code'
                                optionLabel="message"
                                placeholder="Do not intercept message" />
                        </div>
                        <div className='col-2 p-0 flex align-items-center'>
                            <Checkbox
                                onChange={(e) => setActions({
                                    ...actions,
                                    fail_status: {
                                        ...actions?.fail_status,
                                        is_tag_subject: e.target.checked
                                    }
                                })}
                                checked={actions?.fail_status?.is_tag_subject} />
                            <label className="ml-2">Tag Subject</label>

                        </div>
                        <div className='col-2 flex align-items-center'>
                            <Checkbox
                                onChange={(e) => setActions({
                                    ...actions,
                                    fail_status: {
                                        ...actions?.fail_status,
                                        send_notification: e.target.checked
                                    }
                                })}
                                checked={actions?.fail_status?.send_notification} />
                            <label className="ml-2">Send notification</label>

                        </div>


                    </div>
                    <div className='p-col-12 flex'>
                        <div className='col-2 flex align-items-center   '>
                            <span style={{ fontWeight: 'bold' }}>SoftFail :</span>
                        </div>
                        <div className='col-4 flex align-items-center'>
                            <Dropdown
                                style={{ minWidth: '300px' }}
                                value={actions?.softfail_status?.message_verdict}
                                onChange={(e) => setActions({
                                    ...actions,
                                    softfail_status: {
                                        ...actions?.softfail_status,
                                        message_verdict: e.value
                                    }
                                })}
                                options={message}
                                optionValue='code'
                                optionLabel="message"
                                placeholder="Do not intercept message" />
                        </div>
                        <div className='col-2 p-0 flex align-items-center'>
                            <Checkbox
                                onChange={(e) => setActions({
                                    ...actions,
                                    softfail_status: {
                                        ...actions?.softfail_status,
                                        is_tag_subject: e.target.checked
                                    }
                                })}
                                checked={actions?.softfail_status?.is_tag_subject} />
                            <label className="ml-2">Tag Subject</label>

                        </div>
                        <div className='col-2 flex align-items-center'>
                            <Checkbox
                                onChange={(e) => setActions({
                                    ...actions,
                                    softfail_status: {
                                        ...actions?.softfail_status,
                                        send_notification: e.target.checked
                                    }
                                })}
                                checked={actions?.softfail_status?.send_notification} />
                            <label className="ml-2">Send notification</label>

                        </div>

                    </div>
                    <div className='p-col-12 flex'>
                        <div className='col-2 flex align-items-center   '>
                            <span style={{ fontWeight: 'bold' }}>Neutral :</span>
                        </div>
                        <div className='col-4 flex align-items-center'>
                            <Dropdown
                                style={{ minWidth: '300px' }}
                                value={actions?.neutral_status?.message_verdict}
                                onChange={(e) => setActions({
                                    ...actions,
                                    neutral_status: {
                                        ...actions?.neutral_status,
                                        message_verdict: e.value
                                    }
                                })}
                                options={message}
                                optionValue='code'
                                optionLabel="message"
                                placeholder="Do not intercept message" />
                        </div>
                        <div className='col-2 p-0 flex align-items-center'>
                            <Checkbox
                                onChange={(e) => setActions({
                                    ...actions,
                                    neutral_status: {
                                        ...actions?.neutral_status,
                                        is_tag_subject: e.target.checked
                                    }
                                })}
                                checked={actions?.neutral_status?.is_tag_subject} />
                            <label className="ml-2">Tag Subject</label>

                        </div>
                        <div className='col-2 flex align-items-center'>
                            <Checkbox
                                onChange={(e) => setActions({
                                    ...actions,
                                    neutral_status: {
                                        ...actions?.neutral_status,
                                        send_notification: e.target.checked
                                    }
                                })}
                                checked={actions?.neutral_status?.send_notification} />
                            <label className="ml-2">Send notification</label>

                        </div>

                    </div>
                    <div className='p-col-12 flex'>
                        <div className='col-2 flex align-items-center   '>
                            <span style={{ fontWeight: 'bold' }}>None :</span>
                        </div>
                        <div className='col-4 flex align-items-center'>
                            <Dropdown
                                style={{ minWidth: '300px' }}
                                value={actions?.none_status?.message_verdict}
                                onChange={(e) => setActions({
                                    ...actions,
                                    none_status: {
                                        ...actions?.none_status,
                                        message_verdict: e.value
                                    }
                                })}
                                options={message}
                                optionValue='code'
                                optionLabel="message"
                                placeholder="Do not intercept message" />
                        </div>
                        <div className='col-2 p-0 flex align-items-center'>
                            <Checkbox
                                onChange={(e) => setActions({
                                    ...actions,
                                    none_status: {
                                        ...actions?.none_status,
                                        is_tag_subject: e.target.checked
                                    }
                                })}
                                checked={actions?.none_status?.is_tag_subject} />
                            <label className="ml-2">Tag Subject</label>

                        </div>
                        <div className='col-2 flex align-items-center'>
                            <Checkbox
                                onChange={(e) => setActions({
                                    ...actions,
                                    none_status: {
                                        ...actions?.none_status,
                                        send_notification: e.target.checked
                                    }
                                })}
                                checked={actions?.none_status?.send_notification} />
                            <label className="ml-2">Send notification</label>

                        </div>

                    </div>
                    <div className='p-col-12 flex'>
                        <div className='col-2 flex align-items-center   '>
                            <span style={{ fontWeight: 'bold' }}>PermError :</span>
                        </div>
                        <div className='col-4 flex align-items-center'>
                            <Dropdown
                                style={{ minWidth: '300px' }}
                                value={actions?.permerror_status?.message_verdict}
                                onChange={(e) => setActions({
                                    ...actions,
                                    permerror_status: {
                                        ...actions?.permerror_status,
                                        message_verdict: e.value
                                    }
                                })}
                                options={message}
                                optionValue='code'
                                optionLabel="message"
                                placeholder="Do not intercept message" />
                        </div>
                        <div className='col-2 p-0 flex align-items-center'>
                            <Checkbox
                                onChange={(e) => setActions({
                                    ...actions,
                                    permerror_status: {
                                        ...actions?.permerror_status,
                                        is_tag_subject: e.target.checked
                                    }
                                })}
                                checked={actions?.permerror_status?.is_tag_subject} />
                            <label className="ml-2">Tag Subject</label>

                        </div>
                        <div className='col-2 flex align-items-center'>
                            <Checkbox
                                onChange={(e) => setActions({
                                    ...actions,
                                    permerror_status: {
                                        ...actions?.permerror_status,
                                        send_notification: e.target.checked
                                    }
                                })}
                                checked={actions?.permerror_status?.send_notification} />
                            <label className="ml-2">Send notification</label>

                        </div>

                    </div>
                    <div className='p-col-12 flex'>
                        <div className='col-2 flex align-items-center   '>
                            <span style={{ fontWeight: 'bold' }}>TempError :</span>
                        </div>
                        <div className='col-4 flex align-items-center'>
                            <Dropdown
                                style={{ minWidth: '300px' }}
                                value={actions?.temperror_status?.message_verdict}
                                onChange={(e) => setActions({
                                    ...actions,
                                    temperror_status: {
                                        ...actions?.temperror_status,
                                        message_verdict: e.value
                                    }
                                })}
                                options={message}
                                optionValue='code'
                                optionLabel="message"
                                placeholder="Do not intercept message" />
                        </div>
                        <div className='col-2 p-0 flex align-items-center'>
                            <Checkbox
                                onChange={(e) => setActions({
                                    ...actions,
                                    temperror_status: {
                                        ...actions?.temperror_status,
                                        is_tag_subject: e.target.checked
                                    }
                                })}
                                checked={actions?.temperror_status?.is_tag_subject} />
                            <label className="ml-2">Tag Subject</label>

                        </div>
                        <div className='col-2 flex align-items-center'>
                            <Checkbox
                                onChange={(e) => setActions({
                                    ...actions,
                                    temperror_status: {
                                        ...actions?.temperror_status,
                                        send_notification: e.target.checked
                                    }
                                })}
                                checked={actions?.temperror_status?.send_notification} />
                            <label className="ml-2">Send notification</label>

                        </div>

                    </div>

                </Panel>
                <Panel headerTemplate={footerButton} className='mt-0' header="Tag and Notify" toggleable>
                </Panel>
                
            </>
        </div>

    )
}

export default Spf