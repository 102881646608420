import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';

export const NotFound = (props) => {

	console.log("notfound 2");
	const history = useHistory();

	const goDashboard = () => {
		history.push('/')
	}

	return (
		<div className="exception-body notfound">
			<div className="exception-panel">
				<h1>404</h1>
				<h3>not found</h3>
				<p>The page that you are looking for does not exist</p>

				<Button type="button" className='p-button-outlined p-button-warning' label="Go back to home" onClick={goDashboard}></Button>

			</div>
			<div className="exception-footer">
			<img style={{maxWidth:'200px'}} src={props.colorScheme === 'dark' ? "assets/layout/images/black_xguard.png" : "assets/layout/images/ssx.png"}/>

				{/* <img src={`assets/layout/images/${props.colorScheme === 'light' ? 'ssx' : 'black_xquard'}.png`} className="exception-logo" alt="expection-logo" /> */}
				{/* <img src={`assets/layout/images/appname-${props.colorScheme === 'light' ? 'dark' : 'light'}.png`} className="exception-appname" alt="expection-appname"/> */}
			</div>
		</div>
	)
}