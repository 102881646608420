import React, { useState, useEffect } from "react";

const AttackMailGonderen = ({ veri }) => {
    const [saldiriGonderenMail, setSaldiriGonderenMail] = useState([]);

    useEffect(() => {
        setSaldiriGonderenMail(veri?.top_phishing_sender || []);
    }, [veri]);

    return (
        <div style={{ padding: "5px", maxWidth: "400px", margin: "0 auto",height:'280px' }}>
            {/* Başlık */}
            <h6 className="titleAttack">
                The 5 most attacked user e-mail addresses
            </h6>

            {/* Maillerin Listesi */}
            {saldiriGonderenMail.map((mailItem, index) => {
                const mailAddress = mailItem.sender;
                const attackCount = mailItem.attack_count;
                const firstLetter = mailAddress.charAt(0).toUpperCase(); // İlk harfi büyük harf yap

                return (
                    <div
                        key={index}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "7px",
                            padding: "3px",
                            borderRadius: "8px",
                            backgroundColor: "#f9f9f9",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            width: "100%",
                            transition: "transform 0.3s, box-shadow 0.3s",
                            cursor: "pointer",
                        }}
                    >
                        {/* İlk Harfi Gösteren Yuvarlak */}
                        <div
                            style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                backgroundColor: "red",
                                color: "#fff",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "4px",
                                fontSize: "14px",
                                fontWeight: "bold",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                            }}
                        >
                            {firstLetter}
                        </div>

                        {/* Mail Adresi */}
                        <div style={{ flex: 1 }}>
                            <strong style={{ fontSize: "12px", color: "#333" }}>
                                {mailAddress}
                            </strong>
                        </div>

                        {/* Atak Sayısı */}
                        <div>
                            <span style={{ fontSize: "12px", color: "#666" }}>
                                {attackCount} attack
                            </span>
                        </div>
                    </div>
                );
            })}

            {/* Açıklama */}
            <p style={{ textAlign: "left", marginTop: "10px", color: "#666", fontSize: "11px" }}>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.                         </p>
        </div>
    );
};

export default AttackMailGonderen;
