import { defaultActionTypes, emailActionTypes } from '../actionTypes';

const initialState = { isEmailLoading: true, emails: [] };

const reducers = (state = { ...initialState }, action) => {
  switch (action.type) {
    case defaultActionTypes.START_LOADING:
      return { ...state, isEmailLoading: true };
    case defaultActionTypes.END_LOADING:
      return { ...state, isEmailLoading: false };

    case emailActionTypes.DELETE_EMAIL:
      return { ...state, emails: state.emails.filter((email) => email.id !== action.payload) };

    case emailActionTypes.GET_EMAILS:
      return {
        ...state,
        emails: action.payload,
      };

    case emailActionTypes.EDIT_EMAIL:
      return {
        ...state,
        emails: state.emails.map((email) =>
          email.id === action.payload.id ? action.payload : email,
        ),
      };

    case emailActionTypes.CREATE_EMAIL:
      return { ...state, emails: [...state.emails, action.payload] };

    default:
      return state;
  }
};

export default reducers;
