
import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

const PolarAreaDemo = ({dashboardData}) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    // useEffect(() => {
    //     const documentStyle = getComputedStyle(document.documentElement);
    //     const textColor = documentStyle.getPropertyValue('--text-color');
    //     const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    //     const data = {
    //         datasets: [
    //             {
    //                 data: [
    //                     dashboardData?.file_analysis_count?.total,
    //                     dashboardData?.sender_domain_analysis_count?.total,
    //                     dashboardData?.url_analysis_count?.total,
    //                 ],
    //                 backgroundColor: [
    //                     documentStyle.getPropertyValue('--red-200'),
    //                     documentStyle.getPropertyValue('--green-400'),
    //                     // documentStyle.getPropertyValue('--blue-500'),
    //                     documentStyle.getPropertyValue('--bluegray-500'),
    //                 ],
    //                 label: 'Analysis'
    //             }
    //         ],
    //         labels: ['Domain', 'File', 'Url']
    //     };
    //     const options = {
    //         plugins: {
    //             legend: {
    //                 labels: {
    //                     color: textColor
    //                 }
    //             }
    //         },
    //         scales: {
    //             r: {
    //                 grid: {
    //                     color: surfaceBorder
    //                 }
    //             }
    //         }
    //     };

    //     setChartData(data);
    //     setChartOptions(options);
    // }, [dashboardData]);
    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            datasets: [
                {
                    data: [
                        dashboardData?.file_analysis_count?.total,
                        dashboardData?.sender_domain_analysis_count?.total,
                        dashboardData?.url_analysis_count?.total,
                    ],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--purple-500'), 
                        documentStyle.getPropertyValue('--blue-900'), 
                        documentStyle.getPropertyValue('--green-700')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--purple-500'), 
                        documentStyle.getPropertyValue('--blue-900'), 
                        documentStyle.getPropertyValue('--green-700')
                    ],
                    label: 'Analysis'
                }
            ],
            labels: ['Domain', 'File', 'Url']
        }
        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    return (
        <div style={{height:'31rem'}} className="card flex justify-content-center align-content-center">
            <Chart style={{width:'25rem'}} type="polarArea" data={chartData} options={chartOptions}  />
        </div>
    )
}
export default React.memo(PolarAreaDemo)
        