import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { Badge } from "primereact/badge";
import { getAllSenderDomainAnalysisAntiPhishing } from "../../../store/actions/senderDomainAnalyzer";
import Colors from '../../Colors';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import DomainFilter from '../../SenderDomainAnalyzer/DomainFilter';
import { Toast } from 'primereact/toast';
import DateFormat from '../../DateFormat';

const SenderDomain = ({ setCode, data }) => {
    const [senderDomain, setSenderDomain] = useState(null);
    const [displaySender, setDisplaySender] = useState(false);
    const [domainWord, setDomainWord] = useState('');
    const [domainFilterAction, setDomainFilterAction] = useState(false);
    const [basarili, setBasarili] = useState(false);

    const toast = useRef(null);

    useEffect(() => {
        getAllSenderDomainAnalysisAntiPhishing(data?.id).then((res) => {
            console.log("ressss123", res);
            setSenderDomain(res?.data)
            if (res) {
                setDisplaySender(true)

            }
        })
    }, [data])

    useEffect(() => {
        if (basarili) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Domain filter added' });

        }

    }, [basarili])

    console.log("senderDomain12", senderDomain);

    const onHideSender = (e) => {
        setDisplaySender(false)
        setCode(null)

    }
    const resultBodyStatusTemplate = (rowData) => {
        let data = rowData?.verdict?.replace(/_/g, ' ');

        return (
            <>
                {/* <span className="p-column-title">Status</span> */}

                <Badge
                    value={data?.replace(/\b\w/g, char => char.toUpperCase())}
                    style={data === "spam" ? { color: Colors[0].yazi, backgroundColor: Colors[0].spam, boxShadow: `0 0 10px ${Colors[0].spam}` }
                        : data === "suspicious" ? { color: Colors[0].yazi, backgroundColor: Colors[0].suspicious, boxShadow: `0 0 10px ${Colors[0].suspicious}` }
                            : { backgroundColor: Colors[0].ntf, color: Colors[0].yazi, boxShadow: `0 0 10px ${Colors[0].ntf}` }}


                />
            </>
        )
    };
    const addDomain = (data) => {
        console.log("dataas", data);
        setDomainWord(data?.domain)
        setDomainFilterAction(true)

    }
    const domainFilterAdd = (rowData) => {
        let data = rowData

        return (
            <>
                {/* <Button icon='pi pi-pencil' className="p-button-rounded p-button-primary p-button-outlined" onClick={() => updateDomainFunc(rowdata)} ></Button> */}
                <Button tooltip='Add Domain Filter' tooltipOptions={{ position: 'top' }} icon='pi pi-plus' className=" p-button-outlined ml-2" onClick={() => addDomain(data)} ></Button>
            </>
        )
    };

    const spfBodyStatusTemplate = (rowData) => {
        let data = rowData?.spf?.replace(/\b\w/g, char => char.toUpperCase())
        return (
            <>
                <div className="custom-tooltip-badge" style={{ position: 'relative', cursor: 'pointer' }}>
                    <Badge value={data ? data : '-'} />
                    <Tooltip target=".custom-tooltip-badge" content={
                        data === 'Pass' ? 'The sender IP address is verified as an authorized sender.'
                            : data === 'Fail' ? 'The sender IP address has not been verified as an authorized sender and the message should be rejected.'
                                : data === 'Softfail' ? 'The sender IP address is not verified as the authorized sender, but the message is acceptable (usually marked). '
                                    : data === 'Neutral' ? 'The sender IP address did not match any SPF records.'
                                        : data === 'None' ? 'There is no SPF record in the message.'
                                            : data === 'Permerror' ? 'The SPF record is invalid or contains an error.'
                                                : data === 'Temperror' ? 'A temporary error occurred while validating the SPF record.' : ''
                    } position="bottom" />
                </div>

            </>
        )
    };

    const registerTemplate = (rowData) => {
        let data = rowData?.register_date_verdict?.replace(/_/g, ' ');
        let tarihKontrol = rowData?.domain_register_date
        const formattedDateYil = new Date(rowData?.domain_register_date).toLocaleDateString();




        return (
            <>
                {tarihKontrol ? ((formattedDateYil) + " / " + (data ? data?.replace(/\b\w/g, char => char.toUpperCase()) : '-')) : '-'}

            </>
        )

    };
    const analyzeTimeBody = (rowData) => {


        return (
            <>
                <DateFormat dateString={rowData?.analyze_time} />

            </>
        )

    };

    return (
        <>
            <Dialog
                header="Sender Domain Analyzed"
                visible={displaySender}
                style={{ width: '80vw', height: "40vw" }}
                onHide={() => onHideSender()}
                modal
                dismissableMask
            >
                <Toast ref={toast} />

                <div className="card" >


                    <DataTable
                        value={senderDomain}
                        className="p-datatable"
                    >
                        <Column
                            field="domain"
                            header="Sender Domain"
                            style={{ textAlign: 'center' }}
                            alignHeader={'center'}
                        ></Column>
                        <Column
                            field="register_date_verdict"
                            header="Domain Register Date / Verdict"
                            style={{ textAlign: 'center' }}
                            body={registerTemplate}
                            alignHeader={'center'}

                        ></Column>
                        <Column
                            header="Analyze Time"
                            style={{ textAlign: 'center' }}
                            body={analyzeTimeBody}
                            alignHeader={'center'}

                        ></Column>
                        <Column
                            field="spf"
                            header="SPF"
                            style={{ textAlign: 'center' }}
                            body={spfBodyStatusTemplate}
                            alignHeader={'center'}

                        ></Column>
                        <Column
                            field="verdict"
                            header="Verdict"
                            style={{ textAlign: 'center' }}
                            alignHeader={'center'}
                            body={resultBodyStatusTemplate}
                        ></Column>
                        <Column
                            header="Action"
                            style={{ textAlign: 'center' }}
                            alignHeader={'center'}
                            body={domainFilterAdd}
                        ></Column>

                    </DataTable>
                    {domainFilterAction && <DomainFilter data={domainWord} setDomainFilterAction={setDomainFilterAction} setBasarili={setBasarili} />}

                </div>

            </Dialog>
        </>

    )
}

export default SenderDomain