import { defaultActionTypes, bruteForceActionTypes } from "../actionTypes";

const initialState = { isBruteForceLoading: true, bruteForces: [] };

const reducers = (state = { ...initialState }, action) => {
  switch (action.type) {
    case defaultActionTypes.START_LOADING:
      return { ...state, isBruteForceLoading: true };
    case defaultActionTypes.END_LOADING:
      return { ...state, isBruteForceLoading: false };

    case bruteForceActionTypes.GET_ALL_BRUTE_FORCE_DATA:
      return {
        ...state,
        bruteForces: action.payload,
      };
    default:
      return state;
  }
};

export default reducers;
