import { authActionTypes, defaultActionTypes } from '../actionTypes';
import { authApi as api } from '../../services/Api';
import { toast } from 'react-toastify';

export const signin = (formData, history) => async (dispatch) => {
  try {
    dispatch({ type: defaultActionTypes.START_LOADING });
    const { data } = await api.signin(formData);

    dispatch({ type: authActionTypes.AUTH, data });
    console.log("datas",data);
    if(data?.access){
      toast.success('Login Successful');
      history.replace('/');
    }
    
    return { success: true, data };
  } catch (error) {
    console.log("errrorrr",error);
    const errorMessage = error.response?.data?.message || error.message;
    toast.error(errorMessage);
    return { success: false, error: errorMessage };
  } finally {
    dispatch({
      type: defaultActionTypes.END_LOADING,
    });
  }
};


export const refresh = (refreshToken, history) => async (dispatch) => {
  try {
    dispatch({ type: defaultActionTypes.START_LOADING });
    const { data } = await api.refresh(refreshToken);
    dispatch({ type: authActionTypes.AUTH, data });
  } catch (error) {
    toast.error(error.message);
  } finally {
    dispatch({
      type: defaultActionTypes.END_LOADING,
    });


  }
};

// export const signup = (formData, history) => async (dispatch) => {
//   try {
//     dispatch({ type: defaultActionTypes.START_LOADING });
//     const { data } = await api.signup(formData);

//     dispatch({ type: authActionTypes.AUTH, data });
//     toast.success('Sign Up Successful');
//     history.replace('/');
//   } catch (error) {
//     toast.error(error.message);
//   } finally {
//     dispatch({ type: defaultActionTypes.END_LOADING });
//   }
// };
export const passwordChange = (deger, passwordObj, history) => async (dispatch) => {

  try {
    dispatch({ type: defaultActionTypes.START_LOADING });
    const { data } = await api.passwordChange(deger, passwordObj);
    dispatch({ type: authActionTypes.PASSWORD, data });

    toast.success('Created New Password');
    setTimeout(function () {
      dispatch({ type: authActionTypes.LOGOUT })
      history.push("/signIn")
    }, 1000
    )


    // dispatch({ type: authActionTypes.LOGOUT });
    // history.push('/signIn');
  } catch (error) {
    toast.error(error?.response?.data?.old_password?.old_password);
    toast.error(error?.response?.data?.password[0]);
  } finally {
    dispatch({ type: authActionTypes.END_LOADING });
  }
};
