import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { QueryClient, useQuery } from "react-query";
import { Toast } from 'primereact/toast';
import { extensionGet, extensionAdd, extensionDelete } from '../../services/Api/extensions';
import Loading from '../UI/Loading';

const FileExtensions = () => {
    const [extensions, setExtensions] = useState("");
    const [extensionList, setExtensionList] = useState([]);
    const [deleteExtension, setDeleteExtension] = useState(null)
    const [extensionModel, setExtensionModel] = useState(null)
    const [deleteId, setDeleteId] = useState(null)
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [loading, setLoading] = useState(true)

    const queryClient = new QueryClient();
    const toast = useRef(null);
    const dispatch = useDispatch();


    const { data: dataInsertDomain } = useQuery(['extensionAdd', extensionModel], () => {
        if (extensionModel) {
            return extensionAdd(extensionModel);
        }
    })
    const { data: dataDeleteDomain } = useQuery(['extensionDelete', deleteExtension], () => {
        if (deleteExtension) {
            return extensionDelete(deleteExtension);
        }
    })
    useEffect(() => {
        dispatch(extensionGet).then((res) => {
            console.log(".res", res);
            setExtensionList(res?.data?.data)
            setLoading(false)

        })
    }, [dataInsertDomain, dataDeleteDomain])
    console.log("dataDeleteDomain", dataDeleteDomain);
    const addExtension = (item) => {
        if (extensions) {
            const model = {
                file_extension: extensions,
            }
            setExtensionModel(model)
            queryClient.invalidateQueries(['extensionAdd', model]);
            setExtensions('')

        }
    }

    const updateSilBody = (rowdata) => {
        return (
            <>
                {/* <Button icon='pi pi-pencil' className="p-button-rounded p-button-primary p-button-outlined" onClick={() => updateDomainFunc(rowdata)} ></Button> */}
                <Button tooltip='Delete' tooltipOptions={{ position: 'top' }} icon='pi pi-trash' className="p-button-rounded p-button-danger p-button-outlined ml-2" onClick={() => deleteDomainFunc(rowdata)} ></Button>
            </>
        )
    }
    const deleteDomainFunction = () => {
        console.log("içeredee");
        setDeleteExtension(deleteId)
        queryClient.invalidateQueries(['extensionDelete', deleteExtension]);
    }
    const deleteDomainFunc = (rowdata) => {
        setDeleteId(rowdata.id);
        setDeleteDialog(true);
    }
    const handleReject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected' });
    };

    return (

        <>
            <Toast ref={toast} />

            <div className="grid p-fluid mt-2">
                <div className="col-12 md:col-3 ml-3">
                    <i className="block ml-1 mb-1" style={{ fontSize: "14px", fontFamily: "sans-serif", fontStyle: "normal" }}>Extensions <i style={{ color: "red" }}>*</i> </i>
                    <div className="p-inputgroup">
                        <InputText
                            type='text'
                            placeholder='Extension'
                            maxLength={25}
                            value={extensions}
                            onChange={(e) => setExtensions(e.target.value)}
                        />
                    </div>
                </div>
                <div className="col-12 md:col-3">
                    <i className='block mb-1'>&zwnj; </i>
                    <div className="p-inputgroup ">
                        <Button
                            className='p-button-m p-button-success p-button-outlined'
                            style={{ height: "40px" }}
                            label='Save'
                            icon="pi pi-save"
                            onClick={addExtension}></Button>

                    </div>
                </div>


            </div>
            <hr />
            {
                loading ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        {

                            extensionList?.length > 0 && (
                                <div className="card">
                                    <DataTable
                                        value={extensionList}
                                        responsiveLayout="scroll"
                                        className='p-datatable-gridlines'
                                    >
                                        <Column style={{ width: '75%' }} field="file_extension" header="Extension"></Column>
                                        <Column align={'center'} body={updateSilBody} header="Action Menu"></Column>
                                    </DataTable>
                                </div>
                            )
                        }
                    </>

                )
            }


            <ConfirmDialog
                visible={deleteDialog}
                onHide={() => setDeleteDialog(false)}
                message="The selected extension will be deleted. Would you like to continue ?"
                header="Confirmation"
                icon="pi pi-exclamation-triangle"
                accept={() => deleteDomainFunction()}
                reject={() => {
                    handleReject();
                }}
            />
        </>
    )
}

export default FileExtensions