import { defaultActionTypes } from "../actionTypes";

const initialState = { isAuditLoading: true, auditData: [] };

const reducers = (state = { ...initialState }, action) => {
  switch (action.type) {
    case defaultActionTypes.START_LOADING:
      return { ...state, isAuditLoading: true };
    case defaultActionTypes.END_LOADING:
      return { ...state, isAuditLoading: false };

    case defaultActionTypes.GET_AUDIT_DATA:
      return {
        ...state,
        auditData: action.payload,
      };

    default:
      return state;
  }
};

export default reducers;
