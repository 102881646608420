import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";


const AppBreadcrumb = (props) => {
  const location = useLocation();
  const [showPanel, setShowPanel] = useState(false);
  console.log("props.routes222", props.routes);
  console.log("props.routes location", location);
  console.log("props dashboard", props.dashboardType);
  const activeRoute = props.routes.filter((route) => {
    return (
      location.pathname.toLowerCase().replace(/\s/g, "").includes(route.label.replace(/\s/g, "").toLowerCase())
    );
  });


  let items;
  console.log("props.routes location2", location);
  console.log("props.routes activeRoute2", activeRoute);

  const dashbaordTypeFunc = (param) => {
    props.setDashboardType(param)
  }

  if (location.pathname === "/") {
    items = [{ label: "Dashboard" }];

  } else if (location.pathname === "/setting") {
    items = [{ label: "Settings" }];
  }
  else if (location.pathname === "/url-analyzer") {
    items = [{ label: "URL Analysis" }];
  }
  else if (location.pathname === "/senderdomain-analyzer") {
    items = [{ label: "SD Analysis" }];
  }
  else if (location.pathname === "/mail-analysis") {
    items = [{ label: "Mail Analysis" }];
  }
  else if (location.pathname === "/file-analysis") {
    items = [{ label: "File Analysis" }];
  }
  else if (!activeRoute.length) {
    items = [{ label: "" }, { label: "" }];
  } else {
    items = [{ label: activeRoute[0].label }];
    // items = [{ label: activeRoute[0].parent }, { label: activeRoute[0].label }]; //eski hali bu.
  }

  const isStatic = () => {
    return props.menuMode === "static";
  };

  console.log("items", items);

  return (
    <>

      <div className="layout-breadcrumb-container">
        <div className="layout-breadcrumb-left-items">
          {isStatic() && (
            <button
              className="menu-button p-link"
              onClick={props.onMenuButtonClick}
            >
              <i className="pi pi-bars"></i>
            </button>
          )}

          <BreadCrumb model={items} className="layout-breadcrumb" />

        </div>
        <div className="layout-breadcrumb-right-items">
          {/* <button
            tabIndex="0"
            className="search-icon p-link"
            onClick={props.breadcrumbClick}
          >
          <i className="pi pi-search"></i>
          </button> */}
          {
            items[0].label === "Dashboard" &&
            <Button style={{borderRadius:'4px'}} icon='pi pi-calendar' iconPos="left" label={props.dashboardType === 0 ? "Daily" : props.dashboardType === 1 ? 'Weekly' : 'Monthly'} className="layout-rightmenu-button mr-2" onClick={() => props.setVisible(true)} />

          }

        </div>
      </div>


    </>
  );
};

export default AppBreadcrumb;
