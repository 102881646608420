// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-checkbox .p-checkbox-box {
  width: 40px !important;
  height: 40px !important;
}



.settings-menu {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.card-general {
  /* margin:20px; */
  margin-top: 20px;
  flex: 1 1 300px;
  flex-wrap: wrap;
  /* max-width: 250px; */
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 10px;
}

.card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}


`, "",{"version":3,"sources":["webpack://./src/components/Setting/settingStyle.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;;;;AAIA;EACE,aAAa;EACb,eAAe;EACf,SAAS;EACT,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,sBAAsB;EACtB,qCAAqC;EACrC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".custom-checkbox .p-checkbox-box {\r\n  width: 40px !important;\r\n  height: 40px !important;\r\n}\r\n\r\n\r\n\r\n.settings-menu {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  gap: 20px;\r\n  padding: 20px;\r\n}\r\n\r\n.card-general {\r\n  /* margin:20px; */\r\n  margin-top: 20px;\r\n  flex: 1 1 300px;\r\n  flex-wrap: wrap;\r\n  /* max-width: 250px; */\r\n  box-shadow: 0 2px 8px rgba(0,0,0,0.1);\r\n  padding: 10px;\r\n  border: 1px solid #fff;\r\n  border-radius: 10px;\r\n}\r\n\r\n.card-title {\r\n  font-size: 18px;\r\n  font-weight: bold;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
