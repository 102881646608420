import { defaultActionTypes, leakActionTypes } from '../actionTypes';

const initialState = { isLeakLoading: true, leaks: [] };

const reducers = (state = { ...initialState }, action) => {
  switch (action.type) {
    case defaultActionTypes.START_LOADING:
      return { ...state, isLeakLoading: true };
    case defaultActionTypes.END_LOADING:
      return { ...state, isLeakLoading: false };

    case leakActionTypes.GET_ALL_LEAK_DATA:
      return {
        ...state,
        leaks: action.payload,
      };
    case leakActionTypes.CREATE_SEARCH_LEAK_DATA:
      return {
        ...state,
        leaks: [...state.leaks, action.payload],
      };
    default:
      return state;
  }
};

export default reducers;
