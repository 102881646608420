import { authActionTypes } from "../actionTypes";

const initialState = {
  authData: null,
  passwords: null,
  errors: null
};

const reducers = (state = { ...initialState }, action) => {
   switch (action.type) {
    case authActionTypes.AUTH:
      localStorage.setItem("profile", JSON.stringify({ ...action?.data }));

      return { ...state, authData: action?.data, errors: null };

    case authActionTypes.REFRESH:
      localStorage.setItem("profile", JSON.stringify({ ...action?.data }))
      return { ...state, authData: action?.data, errors: null };

    case authActionTypes.PASSWORD:
      return {
        ...state,
        passwords: action?.data, errors: action.errors
      };

    case authActionTypes.LOGOUT:
      localStorage.clear();
      return { ...state, authData: null, errors: null };

    default:
      return state;
  }
};

export default reducers;
