const DateFormat = (dateString) => {
    const date = new Date(dateString?.dateString);
    const year = date.getUTCFullYear(); // Yılı al
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Ayı al, 0'dan başladığı için 1 ekleyip, iki basamak yap
    const day = date.getUTCDate().toString().padStart(2, '0'); // Günü iki basamak yap
    const hours = date.getUTCHours().toString().padStart(2, '0'); // Saati iki basamak yap
    const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // Dakikayı iki basamak yap
    const seconds = date.getUTCSeconds().toString().padStart(2, '0'); // Saniyeyi iki basamak yap

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;


}

export default DateFormat;

