import React, { useState } from 'react';
import { classNames } from 'primereact/utils';
import { useDispatch, useSelector } from 'react-redux';
import { authActionTypes } from './store/actionTypes';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const AppInlineMenu = (props) => {
  const { authData } = useSelector((state) => state.authData);
  const dispatch = useDispatch();
  const history = useHistory();
  const [confirm, setConfirm] = useState(false);

  const confirmFunc = () => {
    setConfirm(true)
  }

  const logout = () => {
    dispatch({ type: authActionTypes.LOGOUT });
    history.push('/signIn');
  };

  const isSlim = () => {
    return props.menuMode === 'slim';
  };

  const isStatic = () => {
    return props.menuMode === 'static';
  };

  const isSidebar = () => {
    return props.menuMode === 'sidebar';
  };

  const isMobile = () => {
    return window.innerWidth <= 991;
  };
  const setting = () => {
    history.push('/profile');
  }
  return (
    <>
      {!isMobile() && (isStatic() || isSlim() || isSidebar()) && (
        <div
          className={classNames('layout-inline-menu', {
            'layout-inline-menu-active': props.activeInlineProfile,
          })}
        >

          <button
            className='layout-inline-menu-action p-link'
            onClick={confirmFunc}          >

            <span className='layout-inline-menu-text'>{authData?.data?.email}</span>
            <i style={{ fontSize: "24px", alignHeader: "center" }} className='pi pi-fw pi-power-off '> </i>

          </button>
          <Dialog header="Confirmation" visible={confirm} style={{ width: '30vw' }} onHide={() => setConfirm(false)}>
            <p>Are you sure you want to quit ?</p>
            <div className="flex justify-content-end ">
              <Button label="No" icon="pi pi-times" onClick={() => setConfirm(false)} className="p-button-outlined p-button-danger mr-2" />
              <Button label="Yes" icon="pi pi-check" onClick={logout} className="p-button-outlined p-button-danger" />
            </div>
          </Dialog>

        </div>

      )}
    </>
  );
};

export default AppInlineMenu;
