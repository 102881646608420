// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newBruteForceClass{
    background-color:rgb(133, 192, 147) !important
}`, "",{"version":3,"sources":["webpack://./src/components/BruteForce/BruteForce.css"],"names":[],"mappings":"AAAA;IACI;AACJ","sourcesContent":[".newBruteForceClass{\r\n    background-color:rgb(133, 192, 147) !important\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
