import { defaultActionTypes, inventoryActionTypes } from '../actionTypes';

const initialState = { isInventoryLoading: true, inventories: [] };

const reducers = (state = { ...initialState }, action) => {
  switch (action.type) {
    case defaultActionTypes.START_LOADING:
      return { ...state, isInventoryLoading: true };
    case defaultActionTypes.END_LOADING:
      return { ...state, isInventoryLoading: false };

    case inventoryActionTypes.DELETE_INVENTORY:
      return {
        ...state,
        inventories: state.inventories.filter((smtp) => smtp.id !== action.payload),
      };

    case inventoryActionTypes.GET_INVENTORY:
      return {
        ...state,
        inventories: action.payload,
      };

    case inventoryActionTypes.EDIT_INVENTORY:
      return {
        ...state,
        inventories: state.inventories.map((smtp) => (smtp.id === action.payload.id ? action.payload : smtp)),
      };

    case inventoryActionTypes.CREATE_INVENTORY:
      return { ...state, inventories: [...state.inventories, action.payload] };

    default:
      return state;
  }
};

export default reducers;
