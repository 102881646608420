import React, { useState, useEffect, useRef } from 'react';
import { contentFilterGet, contentFilterInsert, contentFilterDelete } from '../../services/Api/contentFilter';
import { QueryClient, useQuery } from "react-query";
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chips } from "primereact/chips";
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import Loading from '../UI/Loading';

const ContentWords = () => {
    const dispatch = useDispatch();
    const toast = useRef(null);
    const queryClient = new QueryClient();

    const [words, setWords] = useState([]);
    const [deleteWords, setDeleteWords] = useState([]);
    const [clickedTags, setClickedTags] = useState([]);
    const [contentModel, setContentModel] = useState(null);
    const [deleteContentModel, setDeleteContentModel] = useState([]);
    const [contentFilterList, setContentFilterList] = useState([]);
    const [loading, setLoading] = useState(true)

    const chipsRef = useRef(null);
    console.log("deleteContentModel", deleteContentModel);
    const { data } = useQuery(['contentFilterInsert', contentModel], () => contentModel && contentFilterInsert(contentModel));
    const { deleteWord } = useQuery(['contentFilterDelete', deleteContentModel], () => {
        if (deleteContentModel?.blacklist_words?.length > 0) {
            console.log("içerde", deleteContentModel);
            return contentFilterDelete(deleteContentModel)
        }
    });
    console.log("deleteWord", deleteWord);
    useEffect(() => {
        dispatch(contentFilterGet).then((res) => {
            setContentFilterList(res?.data?.data)
            setLoading(false)

        });

    }, [data, deleteWord]);
    console.log("contentFilterList", contentFilterList);
    const handlePaste = (event) => {
        const paste = (event.clipboardData || window.clipboardData).getData('text');
        if (paste.includes(" ") || paste.includes(",")) {
            event.preventDefault();
            const items = paste.split(/[\s,]+/).filter(item => item.trim() !== "");
            setWords([...words, ...items]);
        }
    };
    const insertContentFilter = () => {
        if (words.length > 0) {
            const model = {
                blacklist_words: words,
                insert: true
            };
            setContentModel(model);
            setWords([]);
        } else {
            toast.current.show({ severity: 'warn', summary: 'Warn', detail: 'Enter word and press enter or comma' });
        }
    };

    const deleteContentFilter = () => {
        if (clickedTags.length > 0) {
            console.log("içerde", clickedTags);
            const model = {
                blacklist_words: clickedTags,
                insert: false
            };
            console.log("modell", model);
            setDeleteContentModel(model);
            queryClient.invalidateQueries(['contentFilterDelete', model]);

        }
        setClickedTags([]);

    };

    const handleTagClick = (word) => {
        const updatedTags = clickedTags.includes(word) ? clickedTags.filter(tag => tag !== word) : [...clickedTags, word];
        setClickedTags(updatedTags);
    };

    const contentTemplate = (rowData) => (
        <React.Fragment>
            {rowData.blacklist_words.map((word, index) => (
                <Tag key={index} value={word}
                    onClick={() => handleTagClick(word)} style={{ backgroundColor: clickedTags.includes(word) ? '#4b4848' : '#b7a5ff', cursor: 'pointer', marginRight: '5px', marginTop: '5px' }} />
            ))}
        </React.Fragment>
    );

    return (
        <>
            <Toast ref={toast} />
            <div className="grid mt-2 ">
                <div className="col-12 md:col-12 ml-3 ">
                    <i className="block ml-1 mb-1" style={{ fontSize: "14px", fontFamily: "sans-serif", fontStyle: "normal" }}>Words <i style={{ color: "red" }}>*</i> </i>
                    <div className="p-inputgroup ">
                        <Chips minLength={3} ref={chipsRef} value={words} onChange={(e) => setWords(e.value)} separator=","
                            onPaste={handlePaste} tooltip='You can paste a text with spaces or commas, or enter multiple texts using the enter and comma keys'
                            tooltipOptions={{ position: 'top' }} />
                        <Button visible={words.length > 1} label='Clear All' icon='pi pi-delete' className='ml-1 p-button-m p-button-success p-button-outlined'
                            onClick={() => setWords([])} />
                        <Button className='ml-1 mr-3 p-button-m p-button-success p-button-outlined' style={{ height: "40px" }} label='Save'
                            icon="pi pi-save" onClick={insertContentFilter} />
                    </div>

                </div>
            </div>
            <div className="card">
                {
                    loading ? (
                        <>
                            <Loading />
                        </>
                    ) : (
                        <>

                            {
                                contentFilterList[0]?.blacklist_words?.length > 0 &&
                                <DataTable value={contentFilterList} responsiveLayout="scroll" className='p-datatable-gridlines'>
                                    <Column style={{ width: '75%' }} field="blacklist_words" body={contentTemplate} header="Words"></Column>
                                </DataTable>
                            }

                            {
                                clickedTags.length > 0 && (
                                    <div className='mt-2'>
                                        <i className='mt-2'>Word list to delete</i>
                                        <div style={{ border: '2px dashed', borderRadius: '2px' }} className="card mt-1">

                                            {clickedTags.map((word, index) => (
                                                <Tag key={index} value={word}
                                                    style={{ background: '#4b4848', marginRight: '5px', marginTop: '5px' }} />

                                            ))}
                                        </div>
                                        <div className="flex justify-content-end">
                                            <Button className='ml-1 p-button-m p-button-success p-button-outlined' style={{ height: "40px" }} label='Delete'
                                                icon="pi pi-trash" onClick={deleteContentFilter} />
                                        </div>
                                    </div>



                                )
                            }
                        </>
                    )
                }


            </div >
        </>
    );
};

export default ContentWords;
