import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { emailPattern, passwordPattern } from '../../constants/regex';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

const SignUp = (props) => {
  const [formValues, setFormValues] = useState({ email: '', password: '', confirmPassword: '' });
  const history = useHistory();
  const toast = useRef(null);

  const onChange = (e) => {
    const { name, value, checked, type } = e.target;
    const newVal = type === 'checkbox' ? checked : value;

    setFormValues({
      ...formValues,
      [name]: newVal,
    });
  };

  const onSubmitHandler = () => {
    console.table(formValues);
    if (formValues.email.trim().length === 0 || formValues.password.trim().length === 0) {
      toast.current.show({ severity: 'error', summary: 'Fill the form', life: 3000 });
    } else if (!emailPattern.test(formValues.email)) {
      toast.current.show({ severity: 'error', summary: 'Invalid email', life: 3000 });
    } else if (!passwordPattern.test(formValues.password)) {
      toast.current.show({ severity: 'error', summary: 'Invalid password', life: 3000 });
    } else if (formValues.password !== formValues.confirmPassword) {
      toast.current.show({ severity: 'error', summary: 'Password does not match', life: 3000 });
    }
    // dispatch(signup({ email: formValues.email, password: formValues.password }, history));
  };

  return (
    <div className='login-body'>
      <Toast ref={toast} />
      <div className='login-image'>
        <img
          src={`assets/layout/images/pages/login-${
            props.colorScheme === 'dark' ? 'ondark1' : 'onlight1'
          }.jpg`}
          alt='xGUARD'
        />
      </div>
      <div className='login-panel p-fluid'>
        <div className='flex flex-column'>
          <div className='flex align-items-center mb-6 logo-container'>
            <img
              src={`assets/layout/images/logo-${
                props.colorScheme === 'dark' ? 'dark' : 'light'
              }.png`}
              className='login-logo'
              alt='login-logo'
            />
            <img
              src={`assets/layout/images/appname-${
                props.colorScheme === 'dark' ? 'dark' : 'light'
              }.png`}
              className='login-appname'
              alt='login-appname'
            />
          </div>
          <div className='form-container'>
            <span className='p-input-icon-left'>
              <i className='pi pi-envelope'></i>
              <InputText
                name='email'
                value={formValues?.email}
                type='text'
                placeholder='Email*'
                onChange={onChange}
              />
            </span>
            <span className='p-input-icon-left'>
              <i className='pi pi-key'></i>
              <InputText
                name='password'
                value={formValues?.password}
                type='password'
                placeholder='Password*'
                onChange={onChange}
              />
            </span>
            <span className='p-input-icon-left'>
              <i className='pi pi-key'></i>
              <InputText
                name='confirmPassword'
                value={formValues?.confirmPassword}
                type='password'
                placeholder='Confirm Password*'
                onChange={onChange}
              />
            </span>
          </div>
          <div className='button-container'>
            <Button type='button' label='Register' onClick={onSubmitHandler}></Button>
            <span>
              Do you have an account?
              <button className='p-link' onClick={() => history.replace('/signIn')}>
                Sign-in here
              </button>
            </span>
          </div>
        </div>

        <div className='login-footer flex align-items-center'>
          <div className='flex align-items-center login-footer-logo-container'>
            <img
              src={`assets/layout/images/logo-${props.colorScheme === 'dark' ? 'dark' : 'light'}.png`}
              className='login-footer-logo'
              alt='login-footer-logo'
            />
            <img
              src={`assets/layout/images/appname-${props.colorScheme === 'dark' ? 'dark' : 'light'}.png`}
              className='login-footer-appname'
              alt='login-footer-appname'
            />
          </div>
          <span>Copyright 2023</span>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
