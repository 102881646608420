import axios from "axios";
import config from "../config";
import decode from "jwt-decode";

const api = axios.create({ baseURL: config.service.BASE_URL });

api.interceptors.request.use(async (req) => {
  if (localStorage.getItem("profile")) {
    const profile = JSON.parse(localStorage.getItem("profile"));
    const decodedToken = await decode(profile?.access);
    const expiresAt = await decodedToken.exp;
    const currentTime = Math.floor(Date.now() / 1000);
    if (expiresAt && expiresAt < currentTime) {
      try {
        let ref = {
          refresh : profile.refresh
        }
        const newAccessToken = await axios.post(`${process.env.REACT_APP_SERVICE_BASE_URL}/token/refresh`, ref);
        req.headers.Authorization = `Bearer ${newAccessToken.data.access}`;
        localStorage.setItem("profile", JSON.stringify({ access: newAccessToken.data.access, refresh: profile.refresh }));
      } catch (error) {
        throw error;
      }
    } else  {
      req.headers.Authorization  =  `Bearer ${JSON.parse(localStorage.getItem("profile")).access}`;
    }
  }
  return req;

  
});

export default api;