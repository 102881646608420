import React, { useState } from 'react';
import { Menubar } from 'primereact/menubar';
import SenderDomainFilter from './SenderDomainFilter';
import ContentWords from './ContentWords';
import FileExtensions from './FileExtensions';

const Filtretion = () => {
    console.log('geldik1');
    const changeTabs = {
        senderDomainFilter: false,
        contentWords: false,
        fileExtensions: false,
        spamFilter: false,
        attachmentFilter: false
    }
    const [tabsValue, setTabsValue] = useState(changeTabs);

    const fileSettingItem = [
        {
            label: 'Sender Domain ',
            icon: 'pi pi-link',
            command: () => setTabsValue({
                ...tabsValue,
                senderDomainFilter: true,
                contentWords: false,
                fileExtensions: false,
                spamFilter: false,
                attachmentFilter: false
            })

        },
        {
            label: 'Content ',
            icon: 'pi pi-inbox',
            command: () => setTabsValue({
                ...tabsValue,
                senderDomainFilter: false,
                contentWords: true,
                fileExtensions: false,
                spamFilter: false,
                attachmentFilter: false
            })


        }, {
            label: 'File Extension ',
            icon: 'pi pi-file',
            command: () => setTabsValue({
                ...tabsValue,
                senderDomainFilter: false,
                contentWords: false,
                fileExtensions: true,
                spamFilter: false,
                attachmentFilter: false
            })


        },
        // {
        //     label: 'Spam ',
        //     icon: 'pi pi-ban',
        //     command: () => setTabsValue({
        //         ...tabsValue,
        //         senderDomainFilter: false,
        //         contentWords: false,
        //         fileExtensions: false,
        //         spamFilter: true,
        //         attachmentFilter: false
        //     })


        // },
        // {
        //     label: 'Attachment ',
        //     icon: 'pi pi-folder-open',
        //     command: () => setTabsValue({
        //         ...tabsValue,
        //         senderDomainFilter: false,
        //         contentWords: false,
        //         fileExtensions: false,
        //         spamFilter: false,
        //         attachmentFilter: true
        //     })


        // }
    ]
    console.log("tabsValue", tabsValue);
    return (
        <div className='mt-2'>
            <Menubar model={fileSettingItem} >



            </Menubar>
            {
                tabsValue.senderDomainFilter ? (
                    <>
                        <SenderDomainFilter />
                    </>
                ) : tabsValue.contentWords ? (
                    <>
                        <ContentWords />
                    </>
                ) : tabsValue.fileExtensions ? (
                    <>
                        <FileExtensions />
                    </>
                ) : (
                    <>
                    </>
                )
            }

        </div>
    )
}

export default Filtretion