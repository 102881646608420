import React from "react";
import { Chart } from "react-google-charts";
import Colors from "../Colors";

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const Eart = ({ threatData }) => {




    const transformData = (threatData) => {
        const header = [["Country", "Verdict"]];
        const threatLevels = {
            "PHISHING": 1,
            "MALICIOS": 2,
            "SUSPICIOUS": 3,
            "SPAM": 4,
            "SENDER DOMAIN BLACKLIST": 5,
            "ATTACHMENT EXTENSION BLACKLIST": 6,
            "CONTENT WORDS IN BLACKLIST": 7,
            "NO THREAT FOUND": 8
        };
        const body = threatData.map(item => [item.Country, threatLevels[item.Verdict.toUpperCase()]]);
        return header.concat(body);
    };

    const processThreatData = (threatData) => {
        const countryThreats = {};

        threatData.forEach(item => {
            const country = item.Country;
            const verdict = item.Verdict.toUpperCase();

            if (!countryThreats[country]) {
                countryThreats[country] = [];
            }

            if (!countryThreats[country].includes(verdict)) {
                countryThreats[country].push(verdict);
            }
        });

        const priority = {
            "PHISHING": 1,
            "MALICIOS": 2,
            "SUSPICIOUS": 3,
            "SPAM": 4,
            "SENDER DOMAIN BLACKLIST": 5,
            "ATTACHMENT EXTENSION BLACKLIST": 6,
            "CONTENT WORDS IN BLACKLIST": 7,
            "NO THREAT FOUND": 8

        };

        const result = [];
        Object.keys(countryThreats).forEach(country => {
            const threats = countryThreats[country];
            threats.sort((a, b) => priority[a] - priority[b]); // Öncelik sırasına göre sırala
            const highestPriorityThreat = threats[0]; // En yüksek öncelikli tehdit
            result.push({ Country: country, Verdict: highestPriorityThreat });
        });

        return result;
    };

    const transformTooltipData = (threatData) => {
        const header = [["Country", { role: 'tooltip', type: 'string', p: { html: true } }]];
        const body = threatData.map(item => {
            // Tooltip içeriğini oluştur
            const tooltipContent = [
                `<div style="padding:10px;">`,
                `<strong style="color:red;"> ${capitalizeFirstLetter(item.Verdict)}</strong>`,
                item.count ? `Count: ${item.count}<br/>` : ""
            ];

            Object.keys(item).filter(key => key !== 'Country' && key !== 'Verdict' && key !== 'count').forEach(key => {
                tooltipContent.push(`${capitalizeFirstLetter(key.replace(/_/g, ' '))}: ${capitalizeFirstLetter(item[key].toString())}`);
            });

            tooltipContent.push(`</div>`);

            return [item.Country, tooltipContent.join('<br/>')];
        });
        return header.concat(body);
    };



    const data = React.useMemo(() => transformData(processThreatData(threatData)), [threatData]);
    const tooltipData = React.useMemo(() => transformTooltipData(threatData), [threatData]);

    const combinedData = data.map((row, index) => {
        if (index === 0) {
            return ["Country", "Threat Level", { role: 'tooltip', type: 'string', p: { html: true } }];
        }
        const tooltipRow = tooltipData.find(tooltipRow => tooltipRow[0] === row[0]);
        return [...row, tooltipRow ? tooltipRow[1] : ''];
    });

    return (
        <div style={{ height: '31rem' }} className="card">

            <small className="flex justify-content-center" style={{ fontSize: "16px", fontWeight: 'bold' }}>International Email Security Threat Map</small>

            <Chart
                chartType="GeoChart"
                width="100%"
                height="420px"
                data={combinedData}
                options={{
                    colorAxis: {
                        colors: [Colors[0].phishing,
                        Colors[0].malicious,
                        Colors[0].suspicious,
                        Colors[0].spam,
                        Colors[0].sdb,
                        Colors[0].aeb,
                        Colors[0].cwb,
                        Colors[0].ntf]
                    },
                    backgroundColor: 'transparent',
                    tooltip: { isHtml: true },
                    legend: 'none'
                }}
                chartEvents={[
                    {
                        eventName: "select",
                        callback: ({ chartWrapper }) => {
                            const chart = chartWrapper.getChart();
                            const selection = chart.getSelection();
                            if (selection?.length === 0) return;
                            const region = combinedData[selection[0].row + 1];
                            console.log("Selected : " + region);
                        },
                    },
                ]}
            />



        </div>
    );
};

export default React.memo(Eart);
