import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { classNames } from "primereact/utils";
import decode from "jwt-decode";
import { Ripple } from "primereact/ripple";
import { Badge } from "primereact/badge";
import AppInlineMenu from "./AppInlineMenu";
import { useDispatch, useSelector } from "react-redux";
import { authActionTypes } from "./store/actionTypes";
import { refresh } from "./store/actions/auth"

const AppSubmenu = (props) => {
  console.log("props.items", props.items);
  const [activeIndex, setActiveIndex] = useState(null);

  const onMenuItemClick = (event, item, index) => {
    console.log("even23t", event, item, index);
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
      event.preventDefault();
    }

    if (item.items) {
      event.preventDefault();
    }

    if (props.root) {
      props.onRootMenuItemClick({
        originalEvent: event,
      });
    }

    if (item.items) {
      setActiveIndex(index === activeIndex ? null : index);
    } else {
      if (props.menuMode !== "sidebar") {
        const ink = getInk(event.currentTarget);
        if (ink) {
          removeClass(ink, "p-ink-active");
        }
      }
    }
    
    props.onMenuItemClick({
      originalEvent: event,
      item: item,
    });
  };

  const onKeyDown = (event, item, index) => {
    if (event.key === "Enter") {
      onMenuItemClick(event, item, index);
    }
  };

  const getInk = (el) => {
    for (let i = 0; i < el.children.length; i++) {
      if (
        typeof el.children[i].className === "string" &&
        el.children[i].className.indexOf("p-ink") !== -1
      ) {
        return el.children[i];
      }
    }
    return null;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const onMenuItemMouseEnter = (index) => {
    if (props.root && props.menuActive && isHorizontalOrSlim() && !isMobile()) {
      setActiveIndex(index);
    }
  };

  const isMobile = () => {
    return window.innerWidth <= 991;
  };
  const isStatic = () => {
    return props.menuMode === "static";
  };

  const isHorizontalOrSlim = useCallback(() => {
    return props.menuMode === "horizontal" || props.menuMode === "slim";
  }, [props.menuMode]);

  const visible = (item) => {
    return typeof item.visible === "function"
      ? item.visible()
      : item.visible !== false;
  };

  const getLink = (item, index) => {
    const menuitemIconClassName = classNames("layout-menuitem-icon", item.icon);
    const content = (
      <>
        <i className={menuitemIconClassName}></i>
        <span className="layout-menuitem-text">{item.label}</span>
        {item.items && (
          <i className="pi pi-fw pi-chevron-down  layout-submenu-toggler"></i>
        )}
        {item.badge && <Badge value={item.badge} severity="success" />}

        <Ripple />
      </>
    );
    const commonLinkProps = {
      style: item.style,
      className: classNames(item.className, "p-ripple", {
        "p-disabled": item.disabled,
      }),
      target: item.target,
      onClick: (e) => onMenuItemClick(e, item, index),
      onMouseEnter: () => onMenuItemMouseEnter(index),
      onKeyDown: (e) => onKeyDown(e, item, index),
    };

    if (item.to) {
      console.log("itemsfa", item.to);
      return (
        <NavLink
          to={item.to}
          exact
          activeClassName="active-route"
          {...commonLinkProps}
        >
          {content}
        </NavLink>
      );
    } else {
      return (
        <a
          href={item.url}
          rel="noopener noreferrer"
          tabIndex={item.url ? "" : 0}
          {...commonLinkProps}
        >
          {content}
        </a>
      );
    }
  };

  const getItems = () => {
    const transitionTimeout =
      isHorizontalOrSlim() && !props.root
        ? { enter: 1000, exit: 450 }
        : isHorizontalOrSlim() && !isMobile()
          ? 0
          : { enter: 1000, exit: 450 };
    return props.items.map((item, i) => {
      console.log("item", item);
      if (visible(item)) {
        const active = activeIndex === i;
        const menuitemClassName = classNames({
          "layout-root-menuitem": props.root,
          "active-menuitem": active && !item.disabled,
        });
        const link = getLink(item, i);
        console.log("linkk", link);
        return (
          <li
            key={item.label || i}
            className={menuitemClassName}
            role="menuitem"
          >
            {props.root && isStatic() && (
              <div className="layout-menuitem-text">{item.label}</div>
            )}
            {link}
            <CSSTransition
              classNames="p-toggleable-content"
              timeout={transitionTimeout}
              in={item.items && props.root && isStatic() ? true : active}
              unmountOnExit
            >
              <AppSubmenu
                items={visible(item) && item.items}
                menuActive={props.menuActive}
                menuMode={props.menuMode}
                parentMenuItemActive={active}
                onMenuItemClick={props.onMenuItemClick}
              ></AppSubmenu>
            </CSSTransition>
          </li>
        );
      }

      return null;
    });
  };

  useEffect(() => {
    if (props.resetActiveIndex && isHorizontalOrSlim()) {
      setActiveIndex(null);
    }
  }, [props.resetActiveIndex, isHorizontalOrSlim]);

  useEffect(() => {
    if (!props.menuActive && isHorizontalOrSlim() && !isMobile()) {
      setActiveIndex(null);
    }
  }, [props.menuActive, isHorizontalOrSlim]);

  if (!props.items) {
    return null;
  }

  const items = getItems();

  return (
    <ul className={props.className} role="menu">
      {items}
    </ul>
  );
};

const AppMenu = (props) => {
  const [mode, setMode] = useState(false)
  const { authData } = useSelector((state) => state.authData);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  console.log("colorScheme", props.colorScheme);
  useEffect(() => {
    let isValid = true;
    const token = authData?.access;
    const refreshToken = {
      refresh: authData?.refresh
    }
    console.log("refreshToken", refreshToken);

    if (token) {
      const decodedToken = decode(token);
      console.log("decodedTokenNE", decodedToken);
      console.log("decodedToken", (decodedToken.exp * 1000));
      console.log("decodedToken232", new Date().getTime() + 3460000);
      if (decodedToken.exp * 1000 < new Date().getTime()) {


        if (refreshToken?.refresh !== undefined) {
          dispatch(refresh(refreshToken, history)).then((response) => {
            console.log("response11", response);
          })
        } else {
          isValid = false;
          console.log("isvalid false oldu çünkü refresh token yok");

        }
        console.log("isvalid öncesi");

      }
    } else {
      isValid = false;
      console.log("isvalid içindeyiz");
    }

    if (!isValid) {
      dispatch({ type: authActionTypes.LOGOUT });
      history.push("/signIn");
      console.log("isvalid çıkış");

    }
  }, [location.pathname, dispatch, history, authData]);

  const isOverlay = () => {
    return props.menuMode === "overlay";
  };

  const isSidebar = () => {
    return props.menuMode === "sidebar";
  };
  const modeChange = () => {
    if (!mode) {
      props.changeColorScheme("light")
      setMode(true)
    } else {
      props.changeColorScheme("dark")
      setMode(false)

    }

  }
  return (
    <div
      className={classNames("layout-menu-wrapper", {
        "layout-sidebar-active": props.sidebarActive,
      })}
      onClick={props.onMenuClick}
      onMouseOver={props.onSidebarMouseOver}
      onMouseLeave={props.onSidebarMouseLeave}
    >
      <div className="menu-logo">
        <button className="logo p-link">
          {
            props.sidebarActive ? (
              <>
                <img

                  src={props.colorScheme === 'dark' ? "assets/layout/images/black_xguard.png" : "assets/layout/images/ssx.png"}
                  alt="logo"
                  style={{ maxWidth: "150px", }}
                  onClick={() => history.push("/")}
                />
              </>
            ) : (
              <>
                <img

                  src={props.colorScheme === 'dark' ? "assets/layout/images/logoSade.png" : "assets/layout/images/logoSade.png"}
                  alt="logo"
                  onClick={() => history.push("/")}
                />
              </>
            )
          }

        </button>

        <button
          href="#"
          className="menu-pin p-link"
          onClick={() => modeChange()}
        >

          <span className="pi pi-moon"></span>

        </button>
        <button
          href="#"
          className="menu-pin p-link ml-4"
          onClick={props.onToggleMenu}
        >

          {isOverlay() && <span className="pi pi-times"></span>}
          {isSidebar() && !props.sidebarStatic && props.pinActive && (
            <span className="pi pi-unlock"></span>
          )}
          {isSidebar() && props.sidebarStatic && props.pinActive && (
            <span className="pi pi-lock"></span>
          )}
        </button>
      </div>

      <div className="layout-menu-container">
        <AppSubmenu
          items={props.model}
          className="layout-menu"
          menuMode={props.menuMode}
          menuActive={props.menuActive}
          root
          parentMenuItemActive
          onMenuClick={props.onMenuClick}
          onMenuItemClick={props.onMenuItemClick}
          onRootMenuItemClick={props.onRootMenuItemClick}
        />
      </div>

      <AppInlineMenu
        menuMode={props.menuMode}
        activeInlineProfile={props.activeInlineProfile}
        onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}

      />
    </div>
  );
};

export default AppMenu;
