import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { Button } from "primereact/button";

const ExcelExport = ({ data }) => {
  console.log("data2424", data);

  const [brute, setBrute] = useState(false)
  const [anti, setAnti] = useState(false)
  const [senderDomain, setSenderDomain] = useState(false)
  const [urlAnalyze, setUrlAnalyze] = useState(false)
  const [hashbox, setHashbox] = useState(false)
  const [leak, setLeak] = useState(false)

  useEffect(() => {
    if (data?.[0]?.email_address) {
      setBrute(true)
    } else if (data?.[0]?.receiver) {
      setAnti(true)
    } else if (data?.[0]?.domain) {
      setSenderDomain(true)
    } else if (data?.[0]?.url_name) {
      setUrlAnalyze(true)
    } else if (data?.[0]?.file_name) {
      setHashbox(true)
    }
  }, [data])

  console.log("antianti", brute, anti, senderDomain, urlAnalyze, hashbox);

  const createDownLoadData = () => {
    handleExport().then((url) => {
      console.log("urlneurl", url);
      console.log(url);
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", `${brute ? "BruteForceList.xlsx" : anti ? "MailAnalysis.xlsx" : senderDomain ? "SenderDomainList.xlsx" : urlAnalyze ? "Urls.xlsx" : "HashboxList.xlsx"}`)
      // anti ? downloadAnchorNode.setAttribute("download", "AntiPhishingList.xlsx") :
      //   senderDomain ? downloadAnchorNode.setAttribute("download", "SenderDomainList.xlsx") :
      //     urlAnalyze ? downloadAnchorNode.setAttribute("download", "AnalyzedURLs.xlsx") :
      //       hashbox ? downloadAnchorNode.setAttribute("download", "HashboxList.xlsx") : null;
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    // The application/octet-stream MIME type is used for unknown binary files.
    // It preserves the file contents, but requires the receiver to determine file type,
    // for example, from the filename extension.
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const s2ab = (s) => {
    // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
    // create an ArrayBuffer with a size in bytes
    const buf = new ArrayBuffer(s.length);

    console.log(buf);

    //create a 8 bit integer array
    const view = new Uint8Array(buf);

    //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
    for (let i = 0; i !== s.length; ++i) {
      console.log(s.charCodeAt(i));
      view[i] = s.charCodeAt(i);
    }

    return buf;
  };

  const handleExport = () => {
    const title = [{
      A: brute ? `BruteForce Details / Report Date : ${new Date().toLocaleDateString()}` :
        anti ? `Mail Analysis Details / Report Date : ${new Date().toLocaleDateString()}` :
          senderDomain ? `SenderDomain-Analyzer Details / Report Date : ${new Date().toLocaleDateString()}` :
            urlAnalyze ? `Url-Analyzer Details / Report Date : ${new Date().toLocaleDateString()}` :
              `Hashbox Details / Report Date : ${new Date().toLocaleDateString()}`
    }, {}];



    let table1 = [
      {
        A: brute ? "Mail Address" : anti ? "Analyze Time" : senderDomain ? "Sender Domain" : urlAnalyze ? "URL" : "File Name",
        B: brute ? "Start Time" : anti ? "From" : senderDomain ? "Virustotal Status" : urlAnalyze ? "Virustotal Status" : "SHA256",
        C: brute ? "Finish Time" : anti ? "To" : senderDomain ? "Urlscanio Status" : urlAnalyze ? "Urlscanio Status" : "Falcon Status",
        D: brute ? "Result" : anti ? "Attachment" : senderDomain ? "Register Time Status" : urlAnalyze ? "Register Time Status" : "Metadefender Status",
        E: brute ? "Status" : anti ? "Status" : senderDomain ? "Status" : urlAnalyze ? "Status" : "Virustotal Status",

      },
    ];

    console.log("table1", table1);
    console.log("data99data", data);
    data.forEach((row) => {

      table1.push({
        A: brute ? row.email_address : anti ? new Date(row.analyze_time).toLocaleString() : senderDomain ? row.domain : urlAnalyze ? row.url_name : row.file_name,
        B: brute ? row.start_time : anti ? row.sender : senderDomain ? row.virustotal_status : urlAnalyze ? row.virustotal_status : row.sha256,
        C: brute ? row.finish_time : anti ? row.receiver : senderDomain ? row.urlscanio_status : urlAnalyze ? row.urlscanio_status : row.falcon_status,
        D: brute ? row.result : anti ? row.has_attachment : senderDomain ? row.register_time_status : urlAnalyze ? row.register_time_status : row.metadefender_status,
        E: brute ? row.status : anti ? row.status : senderDomain ? row.status : urlAnalyze ? row.status : row.virustotal_status,
      });
    });

    table1 = []
      .concat(table1)
      .concat([""])

    const finalData = [...title, ...table1];

    console.log("finaldatane", finalData);

    //create a new workbook
    const wb = XLSX.utils.book_new();

    const sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(wb, sheet, `${brute ? "BruteForceList" : anti ? "AntiPhishingList" : senderDomain ? "SenderDomainList" : urlAnalyze ? "AnalyzedURLs" : "HashboxList"}`)

    // binary large object
    // Since blobs can store binary data, they can be used to store images or other multimedia files.

    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "Mail Address" ? headerIndexes.push(index) :
        data["A"] === "Analyze Time" ? headerIndexes.push(index) :
          data["A"] === "Sender Domain" ? headerIndexes.push(index) :
            data["A"] === "URL" ? headerIndexes.push(index) :
              data["A"] === "File Name" ? headerIndexes.push(index) : null
    );
    console.log("headerIndexesheaderIndexes", headerIndexes);
    const totalRecords = data.length;

    const dataInfo = {
      titleCell: "A2",
      titleRange: "A1:E2",
      tbodyRange: `A3:E${finalData.length}`,
      theadRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:E${headerIndexes[0] + 1}`
          : null,
      theadRange1:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 1}:E${headerIndexes[1] + 1}`
          : null,
      tFirstColumnRange:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:A${totalRecords + headerIndexes[0] + 1}`
          : null,
      tLastColumnRange:
        headerIndexes?.length >= 1
          ? `G${headerIndexes[0] + 1}:E${totalRecords + headerIndexes[0] + 1}`
          : null,

      tFirstColumnRange1:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[1] + 1}:A${totalRecords + headerIndexes[1] + 1}`
          : null,
      tLastColumnRange1:
        headerIndexes?.length >= 1
          ? `E${headerIndexes[0] + 1}:E${totalRecords + headerIndexes[1] + 1}`
          : null,
    };
    console.log("dataInfodataInfo", dataInfo);
    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    console.log("workbookBlobworkbookBlob", workbookBlob);
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      console.log("workbook55", workbook);
      workbook.sheets().forEach((sheet) => {

        sheet.usedRange().style({
          fontFamily: "Arial",
          verticalAlignment: "center",


        });
        console.log("sheet4545", sheet);

        sheet.column("A").width(hashbox ? 85 : urlAnalyze ? 85 : 35);
        sheet.column("B").width(hashbox ? 85 : 35);
        sheet.column("C").width(anti ? 35 : 25);
        sheet.column("D").width(30);
        sheet.column("E").width(20);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",

        });

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: "center",
          });
        }

        sheet.range(dataInfo.theadRange).style({
          fill: "6A5ACD",
          bold: true,
          horizontalAlignment: "center",
        });

        if (dataInfo.theadRange1) {
          sheet.range(dataInfo.theadRange1).style({
            fill: "808080",
            bold: true,
            horizontalAlignment: "center",
            fontColor: "ffffff",
          });
        }

        if (dataInfo.tFirstColumnRange) {
          sheet.range(dataInfo.tFirstColumnRange).style({
            bold: true,
            horizontalAlignment: urlAnalyze ? "left" : hashbox ? "left" : "center",
          });
        }

        if (dataInfo.tLastColumnRange) {
          sheet.range(dataInfo.tLastColumnRange).style({
            bold: true,
          });
        }

        // if (dataInfo.tFirstColumnRange1) {
        //   sheet.range(dataInfo.tFirstColumnRange1).style({
        //     bold: true,
        //   });
        // }

        // if (dataInfo.tLastColumnRange1) {
        //   sheet.range(dataInfo.tLastColumnRange1).style({
        //     bold: true,
        //   });
        // }

      });

      console.log("workbookworkbook2", workbook);
      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <Button
      label="Export"
      icon="pi pi-upload"
      className="ml-3 button-export"
      style={{backgroundColor:"transparent"}}
      onClick={() => {
        createDownLoadData();
      }}

    />
  );
};

export default ExcelExport;