import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signin } from '../../store/actions/auth';
import { emailPattern } from '../../constants/regex';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';

import './Login.css'
import QrKod from './QrKod'

const SignIn = () => {
  const [formValues, setFormValues] = useState({ email: '', password: '', token: '' });
  const [enterLogin, setEnterLogin] = useState(false);
  const [resLogin, setResLogin] = useState(null);
  const [loading, setLoading] = useState(false)
  const [rememberMe, setRememberMe] = useState(false);
  const [svgContent, setSvgContent] = useState('');
  const [visibleQr, setVisibleQr] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const toast = useRef(null);


  useEffect(() => {
    if (resLogin?.error) {

      setLoading(false)
      toast.current.show({ severity: 'error', summary: resLogin?.error.toUpperCase(), life: 3000 });
    }

  }, [enterLogin, resLogin])
  const star = <i style={{ color: 'red' }} >*</i>

  const onChange = (e) => {
    const { name, value, checked, type } = e.target;
    const newVal = type === 'checkbox' ? checked : value;

    setFormValues({
      ...formValues,
      [name]: newVal,
    });
  };


  const onSubmitHandler = async () => {
    setResLogin(null)
    console.table(formValues);
    if (formValues.email.trim().length === 0 || formValues.password.trim().length === 0) {
      toast.current.show({ severity: 'error', summary: 'Fill the form', life: 3000 });
    } else if (!emailPattern.test(formValues.email)) {
      toast.current.show({ severity: 'error', summary: 'Invalid email', life: 3000 });
    }
    setEnterLogin(!enterLogin)
    setLoading(true)



    let response = await dispatch(signin(formValues, history));
    if (response.success) {
      setSvgContent(response.data);
      setResLogin(response.data);
      setVisibleQr(true)
      setLoading(false)
    } else {
      setResLogin({ error: response.error });
    }

  };

  //enter tuşu ile formun submit edilmesi.
  const handleKeyDown = (e) => {
    if (e.key === "Enter") onSubmitHandler()
  }


  return (
    <>
      <Toast ref={toast} />
      <Tooltip style={{width:'400px'}} position="bottom" target=".tooltip-target" 
      content='If you are logging into the application for the first time and do not have a 6-digit verification code, try leaving this field blank. You can get the 6-digit code by following the instructions on the screen that will open.'
       />

      <div className="login-container">
        <div className="login-form">
          <div className="login-header">
            <img src="assets/layout/images/ssx.png" alt="Logo" className="login-logo" />
          </div>
          {/* <div className="login-tabs">
            <div className="tab active">Password Login</div>
            <div className="tab">OTP Login</div>
          </div> */}

          <div className="form-group">
            <label>Email{star} </label>
            <InputText
              name='email'
              value={formValues?.email}
              type='text'
              placeholder='Email'
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <label>Password{star} </label>
            <InputText
              name='password'
              value={formValues?.password}
              type='password'
              placeholder='Password'
              onChange={onChange}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="form-group">
            <label>Please enter the 6-digit code in the Authenticator app <i className="tooltip-target pi pi-info-circle" style={{ position: 'relative', cursor: 'pointer' }}></i> </label>
            <InputText
              name='token'
              value={formValues?.token}
              keyfilter="num"
              placeholder='Authenticator Code'
              maxLength='6'
              onChange={onChange}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="form-options">
            <div className="remember-me">
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label>Remember me</label>
            </div>
            <span className="forgot-password">Forgot password?</span>
          </div>
          <Button
            className='login-button'
            icon='pi pi-chevron-right'
            label={loading ? 'Checking Account' : 'Login'}
            iconPos='right'
            loading={loading}
            onClick={onSubmitHandler}></Button>

        </div>
        <Dialog
          visible={visibleQr}
          style={{ background: '#FFFF', width: '40vw', height: "40vw" }}
          onHide={() => (setVisibleQr(false))}

        >
          <QrKod svgContent={svgContent} />
        </Dialog>
      </div>
    </>

  );
}

export default SignIn;
