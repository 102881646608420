import { defaultActionTypes } from "../actionTypes";
import { dashboardApi as api } from "../../services/Api";
import { toast } from "react-toastify";
export const getData = (model) => async (dispatch) => {
  try {
    dispatch({ type: defaultActionTypes.START_LOADING });

    const response = await api.getData(model);

    dispatch({
      type: defaultActionTypes.GET_DASHBOARD_DATA,
      payload: { ...response.data.data },
    });
  } catch (error) {
    toast.error(error.message);
  } finally {
    dispatch({ type: defaultActionTypes.END_LOADING });
  }
};
