import { antiphishingApi as api } from "../../services/Api";
import { toast } from "react-toastify";

export const getAllAntiPhishing = async (page) => {
  try {
    const {
      data:data,
    } = await api.getAllAntiPhishing(page);
    console.log("results",data);
    return data;
    
  } catch (error) {
    toast.error(error.message);
    return [];
  }
};

export const analysisEmlFile = async (formData,activeKeys) => {
  console.log("formDataformData",formData);
  try {


    const {
      data: { data },
    } = await api.analysisEmlFile(formData,activeKeys);
    console.log("datat",data);
    return data;
  } catch (error) {
    toast.error(error.message);
    return null;
  }
};
export const getEmlViewer = async (fileName) => {
  try {
    const {
      data: { data },
    } = await api.getEmlViewer(fileName);
console.log("dataas",fileName);
    return data;
  } catch (error) {
    toast.error(error.message);
    return [];
  }
};