import React, { useEffect, useRef, useState } from "react";
import {
  analysisEmlFile,
  getAllAntiPhishing,
} from "../../store/actions/antiphishing";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { QueryClient, useQuery } from "react-query";
import Loading from "../UI/Loading";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";

import './MailEditor.css';
import { Toast } from "primereact/toast";
import { Tag } from "primereact/tag";
import AntiPhishingTimeLine from "./AntiPhishingTimeLine";
// import { Tooltip } from 'primereact/tooltip';
import Colors from "../Colors";
import ExcelExport from "./ExcelExport";
import DateFormat from "../DateFormat"
import { Badge } from 'primereact/badge';
import { resendMail } from "../../services/Api/resendMail";
import { Tooltip } from 'primereact/tooltip';

const AntiPhishing = (props) => {
  const lacivert = "#44486D"

  const [refetchInterval, setRefetchInterval] = useState(30000);
  const [displayHashbox, setDisplayHashbox] = useState(false);
  const [turkce, setTurkce] = useState(true);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false)
  const [visibleDetails, setVisibleDetails] = useState(false)
  const [selectItem, setSelectItem] = useState()
  const [emlKontrol, setEmlKontrol] = useState()
  const [activeKeys, setActiveKeys] = useState(null)
  const [islem, setIslem] = useState(false)
  const [listCount, setListCount] = useState(0)
  const [items, setItems] = useState([]);
  const [statuses] = useState(['Phishing', 'Malicious', 'Suspicious', 'Spam', 'Content Filter', 'Extensions Filter']);
  const [selectedVerdict, setSelectedVerdict] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showResendDialog, setShowResendDialog] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [mailData, setMailData] = useState([]);
  const [queryString, setQueryString] = useState('');
  const [pageMailAnalysis, setPageMailAnalysis] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const rows = 50; // Sayfa başına satır sayısı
  const toast = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [selected, setSelected] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [param, setParam] = useState(null)


  console.log("selectItem", selectItem);
  useEffect(() => {
    if (selectedVerdict) {

      let filter = data?.results?.data?.filter((item) => item.verdict?.toUpperCase() === selectedVerdict?.toUpperCase());
      setItems(filter)

    } else {
      setItems(data?.results?.data)

    }
  }, [selectedVerdict])


  useEffect(() => {
    setEmlKontrol(selectItem?.eml_file_name)
  }, [selectItem])

  const { data: resendPost } = useQuery(['resendMail', param], () => {
    if (param) {
      return resendMail(param);
    }
  });




  const { isLoading, data } = useQuery(
    ["antiphishing", queryString],
    () => getAllAntiPhishing(queryString),
    {

      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchInterval,
      onError: () => setRefetchInterval(0),
    }

  );

  useEffect(() => {
    if (pageMailAnalysis?.page > 0) {
      setQueryString(`?page=${pageMailAnalysis?.page + 1}`)
    } else {
      setQueryString('')
    }
  }, [pageMailAnalysis])

  useEffect(() => {
    if (resendPost?.status === 201) {
      setShowResendDialog(false);
      if (data?.previous === null) {
        getAllAntiPhishing('').then((res) => {
          setItems(res?.results?.data);
      
          setTimeout(() => {
            getAllAntiPhishing('').then((res) => {
              setItems(res?.results?.data);
            });
          }, 60000);  
        });
      } else {
        let string = `?page=${pageMailAnalysis?.page + 1}`;
        getAllAntiPhishing(string).then((res) => {
          setItems(res?.results?.data);
      
          setTimeout(() => {
            getAllAntiPhishing(string).then((res) => {
              setItems(res?.results?.data);
            });
          }, 60000);  
        });
      }
      
    }
  }, [resendPost])

  const filtrele = (value) => {
    let filteredData = data?.results?.data;
    if (value) {
      filteredData = data?.results?.data.filter(item => {
        return Object.keys(item).some(key =>
          String(item[key]).toLowerCase().includes(value.toLowerCase())
        )

      });
      setItems(filteredData)
    } else {
      setItems(data?.results?.data)
    }
  }

  useEffect(() => {
    let dataMail = data?.results?.data

    setItems(dataMail?.sort(function compare(a, b) {
      var dateA = new Date(a.analyze_time);
      var dateB = new Date(b.analyze_time);
      return dateB - dateA;
    }));
    setTotalRecords(data?.Count);
    filtrele(globalFilterValue)
    return () => { };
  }, [data]);



  let intervalId = setInterval(() => {
    if (listCount === mailData?.length && refetchInterval !== 0) {
      setLoading(false);
      setRefetchInterval(0);
      clearInterval(intervalId);
    }
  }, refetchInterval);



  const inputRef = useRef(null);

  const handleOpenChooseFileClick = (e) => {
    inputRef.current.click();
    // accept="image/*"
  };

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    let _items = [...items];
    const formData = new FormData();

    for (let i = 0; i < activeKeys?.length; i++) {
      formData.append('activeKeyNames[]', activeKeys[i]);
    }

    formData.append("file", fileObj, fileObj.name);
    const result = await analysisEmlFile(formData);
    if (result !== null) {
      if (_items.find(element => typeof element === 'object')) {
      }
      _items.push({ ...result });
    }

    setItems(_items);
    if (_items) {
      let eleman = _items[_items?.length - 1]
      if (typeof eleman === 'object') {
        console.log("içerdeeeee");
        setLoading(true)
        setIslem(!islem)
        setListCount(_items?.length)
        setRefetchInterval(1000)


      }
    }


    // reset file input
    event.target.value = null;
  };

  const resultBodyTemplate = (rowData) => {
    let data = rowData?.verdict?.replace(/_/g, ' ');



    return (
      <>
        <Tag
          value={data === 'content words in blacklist' ? 'Content Filter' : data === 'attachment extension in blacklist' ? 'Extensions Filter' : data === "attachment bigger than max size" ? 'Attachment Max Size' : data?.replace(/\b\w/g, char => char.toUpperCase())}

          style={data === "phishing" ? { color: Colors[0].yazi, backgroundColor: Colors[0].phishing, boxShadow: `0 0 10px ${Colors[0].phishing}` }
            : data === "malicious" ? { color: Colors[0].yazi, backgroundColor: Colors[0].malicious, boxShadow: `0 0 10px ${Colors[0].malicious}` }
              : data === "spam" ? { color: Colors[0].yazi, backgroundColor: Colors[0].spam, boxShadow: `0 0 10px ${Colors[0].spam}` }
                : data === "suspicious" ? { color: Colors[0].yazi, backgroundColor: Colors[0].suspicious, boxShadow: `0 0 10px ${Colors[0].suspicious}` }
                  : data === "sender domain in blacklist" ? { color: Colors[0].yazi, backgroundColor: Colors[0].sdb, boxShadow: `0 0 10px ${Colors[0].sdb}` }
                    : data === "attachment extension in blacklist" ? { backgroundColor: Colors[0].aeb, color: Colors[0].yazi, boxShadow: `0 0 10px ${Colors[0].aeb}` }
                      : data === "content words in blacklist" ? { backgroundColor: Colors[0].cwb, color: Colors[0].yazi, boxShadow: `0 0 10px ${Colors[0].cwb}` }
                        : data === "attachment bigger than max size" ? { backgroundColor: Colors[0].ams, color: Colors[0].yazi, boxShadow: `0 0 10px ${Colors[0].ams}` }

                          : { backgroundColor: Colors[0].ntf, color: Colors[0].yazi, boxShadow: `0 0 10px ${Colors[0].ntf}` }}


        />
      </>
    );
  };
  const detailsTemplate = (rowData) => {
    const tooltipContent = rowData.resend === 0 ? 'Resend Mail' : rowData.resend === 1 ? 'Processing' : 'Email has been sent';
    return (
      <>
        <Button icon="pi pi-search" className={props.themeMode === "dark" ? "detailsButton" : "detailsButtonLight"} onClick={() => (setVisibleDetails(true), setSelectItem(rowData), onDialogHashbox(rowData.id))} />
        <div id={`tooltip_${rowData.id}`} style={{ display: 'inline-block' }}>
          <Button
            icon={rowData.resend === 1 ? "pi pi-spin pi-sync" : rowData.resend === 2 ? 'pi pi-check-circle' : "pi pi-undo"}
            disabled={rowData.resend === 0 ? false : true}
            className={rowData.resend === 2 ? 'resendIslem ml-2' : rowData.resend === 1 ? 'resendIslemAktif ml-2' : 'ml-2'}
            onClick={() => (setShowResendDialog(true), setSelectItem(rowData))}
          />
        </div>
        <Tooltip target={`#tooltip_${rowData.id}`} content={tooltipContent} position="top" />


      </>
    );
  };






  if (isLoading) {
    return <Loading />;
  }
  const onDialogHashbox = (id) => {
    setDisplayHashbox(true)
    // getAllMalwareBazaarbyAntiPhishing(id).then((res) => {
    //   console.log("ressss123", res);
    //   setMalwareBazaarbyAntiPhishing(res.virustotal
    //   )
    // })
  }

  const analyzeTime = (rowData) => {

    return (
      <>
        <DateFormat dateString={rowData?.analyze_time} />
      </>
    )
  }
  const idBody = (rowData) => {


    return (rowData.id);
  }


  const handleClick = (emails) => {
    console.log("tıklandı", emails);
    setEmailList(emails)
    setShowDialog(true);
  };

  const toBody = (rowData) => {
    const emails = rowData?.receiver?.split(',');
    const firstEmail = emails?.[0] !== "" ? emails?.[0] : '-';
    if (emails?.length > 1) {
      return (
        <span>
          {firstEmail} <i onClick={() => handleClick(emails)} style={{ cursor: 'pointer', color: '#48ee40' }}>{('(+' + (emails?.length - 1) + ')')}</i>
        </span>
      )
    } else {
      return (
        <span>
          {firstEmail}
        </span>
      );
    }

  };

  const customIcons = (
    <React.Fragment>
      <Button
        className="p-button-rounded p-button-outlined p-button-sm mr-2"
        style={{ fontSize: "10px", color: lacivert }}
        label={turkce ? "EN" : "TR"}
        onClick={() => setTurkce(!turkce)}
      />
    </React.Fragment>
  );



  const verdictHeader = () => {

    return (
      <>

        <span className="mr-4">Verdict</span>
        <Dropdown
          value={selectedVerdict}
          options={statuses}
          onChange={(e) => setSelectedVerdict(e.value)}
          placeholder="Select Verdict"
          showClear
        />
      </>
    );
  };
  const renderHeader = () => {
    return (
      <>
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
          <div>
            {/* <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              accept='.eml'
              onChange={handleFileChange}
            /> */}

            {/* <Button
              label="Upload Eml"
              icon="pi pi-plus"
              className="mr-2 button-upload"
              style={{ backgroundColor: 'transparent' }}
              onClick={handleOpenChooseFileClick}
            /> */}
          </div>
          <div>

            <span className="mt-2 md:mt-0 p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                tooltip="It searches the sender, receiver,id and verdict fields. "
                tooltipOptions={{ position: 'top' }}
                value={globalFilterValue}
                onChange={onFilter}
                placeholder="Global Search"
              />

            </span>

            <span className="mt-2 md:mt-0 ">

              <ExcelExport data={items?.length > 0 ? items : rows} />
            </span>
            <span className="mt-2 md:mt-0 ">
              <Button
                icon="pi pi-question"
                className="ml-3 p-button-roundery p-button-outlined"

                style={{ color: "#44486D", borderColor: "#44486D" }}
              />
            </span>
          </div>
        </div >


      </>
    );
  };

  const onPageChange = (e) => {
    setCurrentPage(e.page);
    setPageMailAnalysis(e)
    setSelectedVerdict(null)
    // setGlobalFilterValue('')
  };

  const onFilter = (e) => {
    setGlobalFilterValue(e.target.value);
    filtrele(e.target.value);

  };
  console.log("itemsss", items);

  const handleConfirm = () => {

    var resendObje = {
      eml_file_name: selectItem.eml_file_name,
      mail_id: selectItem.id
    }
    setParam(resendObje)

  }

  return (
    <>
      <Toast ref={toast} position="top-center" />

      <Dialog
        header="Details"
        visible={visibleDetails}
        style={{ width: '80vw', height: "50vw" }}
        onHide={() => (setVisibleDetails(false))}
        modal
        dismissableMask
        maximizable>
        <AntiPhishingTimeLine data={selectItem} />



      </Dialog>

      {
        loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <DataTable
              value={items}
              dataKey="id"
              globalFilter={globalFilterValue}
              header={() => renderHeader()}
              onPage={onPageChange}
              first={currentPage * 25}
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              className="p-datatable-gridlines"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown TotalRecords"
              emptyMessage="No data found."
              responsiveLayout="scroll"
              paginator
              stripedRows
              rows={25}
              totalRecords={data?.count}
              lazy
              selectionMode="single"
              selection={selected}
              onSelectionChange={(e) => setSelected(e.value)}
            >
              <Column
                field="id"
                header="Mail ID"
                body={idBody}
                style={{ textAlign: 'center', maxWidth: '40px' }}
                alignHeader={'center'}


              ></Column>
              <Column
                field="analyze_time"
                header="Analyze Time"
                body={analyzeTime}
                style={{ textAlign: 'center' }}
                alignHeader={'center'}

              ></Column>
              <Column
                header="From"
                style={{ textAlign: 'center' }}
                alignHeader={'center'}
                field="sender"

              ></Column>
              <Column
                body={toBody}
                header="To"
                style={{ textAlign: 'center' }}
                alignHeader={'center'}
                field="receiver"
              ></Column>
              <Column
                field="verdict"
                header={verdictHeader}
                style={{ textAlign: 'center', maxWidth: '150px' }}
                alignHeader={'center'}
                body={resultBodyTemplate}

              ></Column>
              <Column
                header="Actions"
                style={{ textAlign: 'center' }}
                alignHeader={'center'}
                body={detailsTemplate}
              ></Column>
            </DataTable>
          </>

        )
      }
      <Dialog
        header="E-Mail List"
        visible={showDialog}
        style={{ width: '50vw', height: "40vw" }}
        onHide={() => (setShowDialog(false))}
        modal
        dismissableMask
      >
        {
          <ul>
            {emailList.map((email, index) => (
              <span>
                <Badge size="large" style={{ minHeight: '2rem', background: '#0696f0', color: 'white' }} className="mt-2 mr-2" key={index} value={(index + 1) + '-' + email}></Badge>

              </span>
            ))}
          </ul>
        }

      </Dialog>
      <Dialog
        header="Resend Mail Confirmation"
        visible={showResendDialog}
        style={{ width: '30vw', height: "20vw" }}
        onHide={() => (setShowResendDialog(false))}
        modal
        dismissableMask
      >
        <i style={{ fontSize: '50px' }} className='pi pi-exclamation-triangle flex justify-content-center p-2'></i>

        <p style={{ fontSize: '18px' }}>Are you sure that the selected e-mail is safe?
          The e-mail will be sent to your e-mail address.</p>
        <div className="flex justify-content-center p-2">
          <Button label="No" icon="pi pi-times"
            onClick={() => setShowResendDialog(false)}
            className="p-button-outlined  mr-2" />
          <Button label="Yes" icon="pi pi-check"
            onClick={handleConfirm}
            className="p-button-outlined " />
        </div>
      </Dialog>


      <Sidebar
        className="p-sidebar-sm"
        visible={visible}
        position="right"
        icons={customIcons}
        onHide={() => setVisible(false)}>
        {
          !turkce ? (
            <>
              <h3>Anti Phishing Yardım</h3>
              <ol className="m-0" style={{ lineHeight: "1.6" }}>
                <li>Sistem kullanıcılarının tümü yahut seçili kullancılar için bu fonksiyonu kullanabilirsiniz. Gelen maillerdeki <b>ekler, url ve içeriklerini</b> kontrol ederek <b>60+ Anti-Virus</b> yeteneğiyle analiz yapar ve sizin için üst düzey bir koruma sağlar.</li>
                <li>Yükle butonuyla <Button className="p-button-raised p-button-sm" style={{ backgroundColor: lacivert, borderColor: lacivert }}
                  icon="pi pi-plus" label="Upload" /> tehlikeli mail içeriklerini taratabilir ve sonrasında çıkan sonuca göre hareket kabiliyet oluşturabilirsiniz.</li>
                <li>Birden fazla platform desteğiyle başlıca tehdit analiz araçları ve yazılımlar kullanabilirsiniz, bunun için ayarlar bölümünden gerekli düzenlemeyi yapmanız yeterli.</li>
              </ol>
            </>
          ) : (
            <>
              <h3>Anti-Phishing Help</h3>
              <ol className="m-0" style={{ lineHeight: "1.6" }}>
                <li>You can use this function for all the users or selected users, also by checking <b>the attachments, urls and contents of incoming mails</b>, it analyzes with <b>60+ Anti-Virus</b> capability and provides a high level of protection for you.</li>
                <li>With the Upload button,<Button className="p-button-raised p-button-sm" style={{ backgroundColor: lacivert, borderColor: lacivert }}
                  icon="pi pi-plus" label="Upload" /> you can scan dangerous e-mail contents and then create action ability according to the result.</li>
                <li>You can use threat analysis tools and software with support for multiple platforms, just make the necessary adjustments in the settings section.</li>
              </ol>
            </>
          )
        }
      </Sidebar>
    </>
  );
};

export default AntiPhishing;
