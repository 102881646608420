import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { StyledEngineProvider } from '@mui/material/styles';
import './index.css';
import { HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

import 'react-toastify/dist/ReactToastify.css';
import AppWrapper from './AppWrapper';

const MOUNT_NODE = document.getElementById('root');

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./AppWrapper'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
  });
}

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <HashRouter>
          <AppWrapper></AppWrapper>
        </HashRouter>
      </Provider>
    </PersistGate>
  </StyledEngineProvider>,
  MOUNT_NODE,
);
