import { defaultActionTypes, templateActionTypes } from '../actionTypes';

const initialState = { isTemplateLoading: true, templates: [] };

const reducers = (state = { ...initialState }, action) => {
  switch (action.type) {
    case defaultActionTypes.START_LOADING:
      return { ...state, isTemplateLoading: true };
    case defaultActionTypes.END_LOADING:
      return { ...state, isTemplateLoading: false };

    case templateActionTypes.GET_TEMPLATES:
      return {
        ...state,
        templates: action.payload,
      };
    case templateActionTypes.GET_TEMPLATE:
      return {
        ...state,
        template: action.payload.template.data,
      };
    case templateActionTypes.CREATE_TEMPLATE:
      return { ...state, templates: [...state.templates, action.payload] };

    case templateActionTypes.DELETE_TEMPLATE:
      return {
        ...state,
        templates: state.templates.filter((template) => template.id !== action.payload),
      };

    case templateActionTypes.EDIT_TEMPLATE:
      return {
        ...state,
        templates: state.templates.map((template) =>
          template.id === action.payload.id ? action.payload : template,
        ),
      };

    default:
      return state;
  }
};

export default reducers;
