import api from "../../api";

export const getAllAntiPhishing = (page) => api.get(`/antiphishing${page}`);
export const analysisEmlFile = (formData, activeKeys) =>
  api.post("/antiphishing/start", formData, activeKeys, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
// export const getEmlViewer = (fileName) => api.get("/antiphishing/email_viewer",fileName);

export const getEmlViewer = (id) =>
  api.get(`/antiphishing/email_viewer/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });


// /hashbox/antiphishing / ${ id } `