import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  mainContainer: {
    borderRadius: 15,
    margin: '30px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 50px',
  },
  templateContainer: {
    borderRadius: 15,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px 50px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backgroundBlendMode: 'darken',
  },
  card: {
    display: 'flex',
    // backgroundColor: 'rgba(255, 255, 255, 0.05)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '15px',
    height: '100%',
    position: 'relative',
    transition: 'transform 0.2s ease-in',
    '&:hover': {
      transform: 'translateY(-15px)',
    },
  },
  title: {
    margin: '30px 0',
    padding: '10px 16px',
    textAlign: 'left',
  },
  subtitle: {
    margin: '30px 0',
    padding: '0 16px',
    textAlign: 'center',
  },
  cardAction: {
    display: 'block',
    textAlign: 'initial',
    

    '&:hover': {
      // backgroundColor: '#1976d2',
      color: '#fff',
    },
  },
});
