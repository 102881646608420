import { Chart } from 'primereact/chart';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import CountryList from './CountryList';
import AttackMailAlici from './AttackMailAlici';
import AttackMailGonderen from './AttackMailGonderen';
import SpamMailAlici from './SpamMailAlici';
import SpamMailGonderen from './SpamMailGonderen';
import DataCards from './DataCards';

const RaporDeneme = ({ veri, dashboardVeri, reportData }) => {
    const reportRef1 = useRef(); // İlk sayfa referansı
    const reportRef2 = useRef(); // İkinci sayfa referansı
    const [score, setScore] = useState('A')


    console.log("dasb", dashboardVeri);
    const downloadPDF = () => {
        const input1 = reportRef1.current; // İlk sayfa
        const input2 = reportRef2.current; // İkinci sayfa

        const pdf = new jsPDF('p', 'mm', 'a4');

        html2canvas(input1).then(canvas1 => {
            const imgData1 = canvas1.toDataURL('image/png');
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas1.height * imgWidth) / canvas1.width;

            pdf.addImage(imgData1, 'PNG', 0, 0, imgWidth, imgHeight); // İlk sayfa ekleniyor

            // İkinci sayfa için yeni canvas
            html2canvas(input2).then(canvas2 => {
                const imgData2 = canvas2.toDataURL('image/png');
                pdf.addPage();
                pdf.addImage(imgData2, 'PNG', 0, 0, imgWidth, imgHeight); // İkinci sayfa ekleniyor
                pdf.save('report.pdf'); // PDF indirilir
            });
        });
    };

    return (
        <div>
            <button onClick={downloadPDF}>Download as PDF</button>

            {/* İlk Sayfa */}
            <div ref={reportRef1} style={{
                width: '210mm',
                height: '297mm',
                margin: 'auto',
                backgroundColor: 'white',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                zIndex: 9999,
                padding: '20px',
                overflow: 'hidden'
            }}
                className='card'>

                {/* Logo ve Tarih - Ortada */}
                <div
                    style={{
                        textAlign: 'center',
                        marginBottom: '20px',
                        padding: '10px',
                        background: 'linear-gradient(135deg, white, lightgray, lightgreen)', // Gradyan renk
                        borderRadius: '10px', // Köşeleri yuvarlamak için
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Hafif gölge efekti
                    }}
                >
                    <img
                        src='/assets/layout/images/ssx.png'
                        alt="Logo"
                        style={{ width: '300px', height: 'auto' }}
                    />
                    <p style={{ fontSize: '11px', fontWeight: 'bold' }}>
                        {/* Report Date: 06.10.2024 - 10.10.2024 */}
                        Threat Intelligence Report
                    </p>

                    <div
                        style={{
                            position: 'relative',
                            width: '100%', // Tam genişlik verelim
                            height: '130px',
                            backgroundImage: 'url("assets/layout/images/shield1.png")', // Kalkan ikonu
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', // Ortalamak için
                            flexDirection: 'column', // Small etiketlerini alt alta koymak için
                            color: '#fff', // Yazı rengi
                            fontWeight: 'bold',
                            fontSize: '16px',
                            margin: '0 auto', // Div'i yatay ortalamak için
                            textAlign: 'center', // İçerikleri ortalamak için
                        }}
                    >
                        <div className='mb-3' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                            <small
                                className='mb-0 p-0 mr-1'
                                style={{
                                    fontSize: '40px',
                                    color: score === 'A' ? '#45de4a' :
                                        score === 'B' ? 'yellow' :
                                            score === 'C' ? 'orange' :
                                                score === 'D' ? 'red' : 'black', // Diğer durumlar için siyah renk
                                }}
                            >
                                {score}
                            </small>

                            <small className='p-0 m-0'
                                style={{
                                    fontSize: '15px', // Küçük font boyutu
                                    color: 'black', // Beyaz yazı rengi
                                }}
                            >
                                Score
                            </small>
                        </div>

                    </div>



                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center', // İkisini dikey eksende ortalamak için
                            justifyContent: 'center',
                            // backgroundColor: '#f0f0f0', // Arka plan rengi
                            padding: '10px 15px',
                            // borderRadius: '8px', // Yuvarlatılmış köşeler
                            // boxShadow: '0 2px 6px rgba(0, 0, 0, 0.15)', // Hafif gölge efekti
                            marginTop: '10px',
                        }}

                    >

                        <small

                            style={{
                                fontSize: '14px',
                                color: '#006400', // Açık yeşil tonlarında renk
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                transition: 'color 0.3s ease', // Hover efekti için geçiş
                            }}

                        >
                            https://www.secunnix.com
                        </small>

                    </div>
                    <p style={{ fontSize: '11px', fontWeight: 'bold' }}>
                        {/* Report Date: 06.10.2024 - 10.10.2024 */}
                        {/* Report date range ({reportData.start_date}-{reportData.end_date}) */}
                        Report Created Date: {new Date().toLocaleString()}
                    </p>
                </div>


                {/* İçerik Bölümü */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    position: 'relative',
                    padding: 0
                }}>
                    {/* Sol Taraf (Grafikler ve CountryList) */}
                    <div style={{ width: '50%', paddingRight: '10px', textAlign: 'left' }}>
                        <CountryList veri={veri} />
                        <AttackMailAlici veri={veri} />
                    </div>

                    {/* Dikey Çizgi */}
                    <div style={{
                        height: '100%',
                        borderLeft: '2px solid black',
                        position: 'absolute',
                        left: '50%',
                        top: 0,
                    }}></div>

                    {/* Sağ Taraf (Rapor İçeriği) */}
                    <div style={{ width: '50%', paddingLeft: '10px', textAlign: 'left' }}>
                        <DataCards dashboardVeri={dashboardVeri} />
                        <SpamMailAlici veri={veri} />
                    </div>
                </div>
            </div>

            {/* İkinci Sayfa */}
            <div ref={reportRef2} style={{
                width: '210mm',
                height: '297mm',
                margin: 'auto',
                backgroundColor: 'white',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                zIndex: 9999,
                padding: '20px',
                overflow: 'hidden',
                marginTop: '20px' // İkinci sayfa üstüne boşluk ekleriz
            }}
                className='card'>

                {/* Logo ve Tarih - Ortada */}
                <div
                    style={{
                        textAlign: 'center',
                        marginBottom: '20px',
                        padding: '2px',
                        background: 'linear-gradient(135deg, white, lightgray, lightgreen)', // Gradyan renk
                        borderRadius: '10px', // Köşeleri yuvarlamak için
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Hafif gölge efekti
                    }}
                >
                    <img
                        src='/assets/layout/images/ssx.png'
                        alt="Logo"
                        style={{ width: '300px', height: 'auto' }}
                    />
                    <p style={{ fontSize: '11px', fontWeight: 'bold' }}>
                        Report Date: 06.10.2024 - 10.10.2024
                    </p>
                </div>


                {/* İçerik Bölümü */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    position: 'relative',
                }}>
                    {/* Sol Taraf (Grafikler ve CountryList) */}
                    <div style={{ width: '50%', paddingRight: '10px', textAlign: 'left' }}>
                        <AttackMailGonderen veri={veri} />

                    </div>

                    {/* Dikey Çizgi */}
                    <div style={{
                        height: '100%',
                        borderLeft: '2px solid black',
                        position: 'absolute',
                        left: '50%',
                        top: 0,
                    }}></div>

                    {/* Sağ Taraf (Rapor İçeriği) */}
                    <div style={{ width: '50%', paddingLeft: '10px', textAlign: 'left' }}>
                        <SpamMailGonderen veri={veri} />

                    </div>
                </div>
            </div>
        </div>
    );
}

export default RaporDeneme;
