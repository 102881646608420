

const Colors = [
    {
        //maviden yeşile
        phishing: "#F06058",
        malicious: "#E85D04",
        suspicious:"#F2C073",
        spam: "#80CED7",
        sdb: "#9A8C98", //Sender domain blacklist
        aeb: "#B39EC9", // Attachment extension blacklist
        cwb: "#56A2DC", //Content words blaclist
        ntf:"gray",
        ams:"#a483cd",
        yazi: "white"

    },
    {
        //maviden yeşile
        phishing: "#22577A",
        malicious: "#38A3A5",
        suspicious:"#E85D04",
        spam: "#48B89F",
        sdb: "#57CC99", //Sender domain blacklist
        aeb: "#57CC99", // Attachment extension blacklist
        cwb: "#80ED99", //Content words blaclist
        ntf:"#80ED99", 
        ntf:"#80ED99",
        yazi: "#370617"
    },
    {
        //maviden yeşile
        phishing: "#22577A",
        malicious: "#38A3A5",
        suspicious:"#E85D04",
        spam: "#48B89F",
        sdb: "#57CC99", //Sender domain blacklist
        aeb: "#57CC99", // Attachment extension blacklist
        cwb: "#80ED99", //Content words blaclist
        ntf:"#80ED99",
        yazi: "#370617"
    },
    {
        //maviden yeşile
        phishing: "#22577A",
        malicious: "#38A3A5",
        suspicious:"#E85D04",
        spam: "#48B89F",
        sdb: "#57CC99", //Sender domain blacklist
        aeb: "#57CC99", // Attachment extension blacklist
        cwb: "#80ED99", //Content words blaclist
        ntf:"#80ED99",
        yazi: "#370617"
    }
]

export default Colors;