import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { passwordChange } from '../../store/actions/auth';
import { TabMenu } from 'primereact/tabmenu';
import { Toast } from 'primereact/toast';
        
const Profile = () => {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);

    const toast = useRef(null);
    const dispatch = useDispatch();


    const items = [
        { label: 'Password', icon: 'pi pi-fw pi-check-circle', code: 0 },
        // { label: 'Mail Connection', icon: 'pi pi-server', code: 1},


    ];



    let deger = 1
    const onSubmitHandler = () => {
        if (newPassword.length >= 8 && newPasswordConfirm.length >= 8) {
            let passwordObj = {
                password: newPassword,
                password2: newPasswordConfirm,
                old_password: oldPassword
            }
            if (newPassword === newPasswordConfirm) {
                dispatch(passwordChange(deger, passwordObj)).then((response) => {
                    // history.push("/signin")
                    // logout()
                    setNewPassword(null)
                    setNewPasswordConfirm(null)
                    setOldPassword(null)
                });

            }
            else {
                toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'The new passwords do not match', life: 3000 });
            }



        } else {
            toast?.current?.show({ severity: 'warn', summary: 'Warning', detail: 'New password must be at least 8 characters', life: 3000 });

        }

        

        // if (formValues.email.trim().length === 0 || formValues.password.trim().length === 0) {
        //     toast.current.show({ severity: 'error', summary: 'Fill the form', life: 3000 });
        // } else if (!emailPattern.test(formValues.email)) {
        //     toast.current.show({ severity: 'error', summary: 'Invalid email', life: 3000 });
        // } else if (!passwordPattern.test(formValues.password)) {
        //     toast.current.show({ severity: 'error', summary: 'Invalid password', life: 3000 });
        // } else if (formValues.password !== formValues.confirmPassword) {
        //     toast.current.show({ severity: 'error', summary: 'Password does not match', life: 3000 });
        // }
        // dispatch(signup({ email: formValues.email, password: formValues.password }, history));
    };
    const tabModelSelect = (e) => {
        setActiveIndex(e?.value?.code)
    }
    return (
        <div className="card">
            <Toast ref={toast}/>
            <h5>Profile Settings</h5>
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => tabModelSelect(e)} />
            <div className="grid p-fluid mt-2">
                <div className="col-8 md:col-8">
                    <i className="block ml-3" style={{ fontSize: "14px", fontFamily: "sans-serif", fontStyle: "normal" }}>Password <i style={{ color: "red" }}>*</i> </i>
                    <div className="p-inputgroup">
                        <InputText
                            type='password'
                            placeholder='Password*'
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            maxLength={50} />
                    </div>
                </div>

                <div className="col-8 md:col-8">
                    <i className="block ml-3" style={{ fontSize: "14px", fontFamily: "sans-serif", fontStyle: "normal" }}>New Password <i style={{ color: "red" }}>*</i> </i>
                    <div className="p-inputgroup">
                        <InputText
                            type='password'
                            placeholder='Password*'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)} maxLength={50} />
                    </div>
                </div>

                <div className="col-8 md:col-8">
                    <i className="block ml-3" style={{ fontSize: "14px", fontFamily: "sans-serif", fontStyle: "normal" }}>Confirm New Password <i style={{ color: "red" }}>*</i> </i>
                    <div className="p-inputgroup">
                        <InputText
                            type='password'
                            placeholder='Password*'
                            value={newPasswordConfirm}
                            onChange={(e) => setNewPasswordConfirm(e.target.value)} maxLength={50} />

                    </div>
                </div>
                <div className="col-6 md:col-6">
                    <i className='block'>&zwnj;</i>
                    <div className="p-inputgroup ">
                        <Button
                            className='p-button-m p-button-success p-button-outlined'
                            style={{ height: "40px" }}
                            label='Change Password'
                            onClick={onSubmitHandler}></Button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile