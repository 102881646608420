
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import './settingStyle.css'
import { Toast } from 'primereact/toast';
import { fileSizeGet, fileSizeUpdate } from '../../services/Api/extensions';
import { QueryClient, useQuery } from "react-query";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const GeneralSettings = () => {

    const [fileSize, setFileSize] = useState(null);
    const [fileSizeInput, setFileSizeInput] = useState('');
    const [availableSize, setAvailableSize] = useState([]);

    const toast = useRef(null);

    const dispatch = useDispatch();
    const queryClient = new QueryClient();


    const { data: dataInsertSize } = useQuery(['fileSizeUpdate', fileSize], () => {
        if (fileSize) {
            return fileSizeUpdate(fileSize);
        }
    })

    useEffect(() => {
        dispatch(fileSizeGet).then((res) => {
            setAvailableSize(res?.data?.data)
            setFileSizeInput('')
        })
    }, [dataInsertSize])

    useEffect(() => {
        console.log("sonuc",fileSize?.max_size === availableSize[0]?.max_size);
        if (fileSize && (fileSize?.max_size === availableSize[0]?.max_size)) {
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'The new file size has been saved.', life: 3000 });
        }
    }, [fileSize, availableSize])



    const addFileSize = () => {
        if (fileSizeInput) {
            console.log("fileSizeInput", parseInt(fileSizeInput));
            let size = parseInt(fileSizeInput)
            const model = {
                max_size: size,
            }
            setFileSize(model)
            console.log("modelll", model);
            queryClient.invalidateQueries(['fileSizeUpdate', model]);


        }else{
            toast.current.show({ severity: 'info', summary: 'Error', detail: 'Enter file size', life: 3000 });

        }
    }

    return (
        <div className="flex col-12 p-0">
            <div className="card-general col-6">
                <div className="card-title">File Size Control </div>
                <p>To control the size of the files in the e-mails you receive, you can make a high size control.</p>
                <p>Your current file size :  {availableSize[0]?.max_size ? <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{availableSize[0]?.max_size + ' MB'}</span> : 'Size not specified.'} </p>
            </div>
            <div className='card-general col-6 '>
                <i className="block mb-2" style={{ fontSize: "14px", fontFamily: "sans-serif", fontStyle: "normal" }}>File Size <i style={{ color: "red" }}>*</i> </i>
                <div className=''>
                    <InputText
                        keyfilter="num"
                        placeholder='Enter megabyte size'
                        maxLength='2'
                        value={fileSizeInput}
                        onChange={(e) => setFileSizeInput(e.target.value)}
                        name='size'
                        style={{ width: '100%' }}
                    />
                </div>
                <div className='flex justify-content-end'>
                    <Button icon='pi pi-save' className='mt-1' label='Save' onClick={addFileSize} />

                </div>
            </div>

            <Toast ref={toast} />
            <ConfirmDialog />
        </div>
    );
}

export default GeneralSettings


