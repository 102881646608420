import React, { useState, useEffect } from 'react'
import { TabMenu } from 'primereact/tabmenu';
import RoleBasedAccessControl from './RoleBasedAccessControl';
import Profile from './Profile';

const SystemSetting = ({ labels }) => {

    const [activeIndexUst, setActiveIndexUst] = useState(2);


    const itemsUst = [

        // { label: 'System Updates', icon: 'pi pi-home', code: 0 },
        // { label: 'Backup and Restore', icon: 'pi pi-directions-alt', code: 1 },
        { label: 'Security', icon: 'pi pi-key', code: 2 },
        // { label: 'Performance Monitoring', icon: 'pi pi-desktop', code: 3 },
        // { label: 'User Management', icon: 'pi pi-users', code: 4 },


    ];

    useEffect(() => {
        if (labels === 'System Updates') {
            setActiveIndexUst(0)


        } else if (labels === 'Backup and Restore') {
            setActiveIndexUst(1)

        }
        else if (labels === 'Security') {
            setActiveIndexUst(2)

        }
        else if (labels === 'Performance Monitoring') {
            setActiveIndexUst(3)

        }
        else if (labels === 'User Management') {
            setActiveIndexUst(4)

        }

    }, [labels])

    const ustTabModelSelect = (e) => {
        setActiveIndexUst(e?.value?.code)
    }
    return (
        <div>
            {/* <Toast ref={toast} /> */}
            <div style={{ height: 'auto' }} className="card-settings">
                <h5>System Settings</h5>
                <TabMenu model={itemsUst} activeIndex={activeIndexUst} onTabChange={(e) => ustTabModelSelect(e)} />
                {
                    activeIndexUst === 0 ? (
                        <>
                        </>
                    ) : activeIndexUst === 1 ? (
                        <>
                        </>


                    ) : activeIndexUst === 2 ? (
                        <>
                            <Profile />

                        </>
                    ) : activeIndexUst === 3 ? (
                        <>

                        </>
                    ) : activeIndexUst === 4 ? (
                        <>
                            <RoleBasedAccessControl />

                        </>
                    ) : (
                        <>
                        </>
                    )
                }

            </div>
        </div>
    )
}

export default SystemSetting