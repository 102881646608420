export const defaultActionTypes = {
  START_LOADING: 'START_LOADING',
  END_LOADING: 'END_LOADING',
  GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
  GET_TIMELINE_DATA: 'GET_TIMELINE_DATA',
  GET_AUDIT_DATA: 'GET_AUDIT_DATA',
};

export const authActionTypes = {
  AUTH: 'AUTH',
  LOGOUT: 'LOGOUT',
  REFRESH:'REFRESH',
  PASSWORD:"PASSWORD"
};

export const emailActionTypes = {
  GET_EMAILS: 'GET_EMAILS',
  CREATE_EMAIL: 'CREATE_EMAIL',
  EDIT_EMAIL: 'EDIT_EMAIL',
  DELETE_EMAIL: 'DELETE_EMAIL',
};

export const landingActionTypes = {
  GET_LANDINGS: 'GET_LANDINGS',
  CREATE_LANDING: 'CREATE_LANDING',
  EDIT_LANDING: 'EDIT_LANDING',
  DELETE_LANDING: 'DELETE_LANDING',
};

export const smtpActionTypes = {
  GET_SMTPS: 'GET_SMTPS',
  CREATE_SMTP: 'CREATE_SMTP',
  EDIT_SMTP: 'EDIT_SMTP',
  DELETE_SMTP: 'DELETE_SMTP',
};

export const eventsActionTypes = {
  GET_EVENTS: 'GET_EVENTS',
  CREATE_EVENT: 'CREATE_EVENT',
  EDIT_EVENT: 'EDIT_EVENT',
  DELETE_EVENT: 'DELETE_EVENT',
};

export const templateActionTypes = {
  GET_TEMPLATES: 'GET_TEMPLATES',
  GET_TEMPLATE: 'GET_TEMPLATE',
  CREATE_TEMPLATE: 'CREATE_TEMPLATE',
  EDIT_TEMPLATE: 'EDIT_TEMPLATE',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',
};

export const leakActionTypes = {
  GET_ALL_LEAK_DATA: 'GET_ALL_LEAK_DATA',
  CREATE_SEARCH_LEAK_DATA: 'CREATE_SEARCH_LEAK_DATA',
};

export const malwareBazaarActionTypes = {
  GET_ALL_MALWARE_BAZAAR_DATA: 'GET_ALL_MALWARE_BAZAAR_DATA',
};

export const bruteForceActionTypes = {
  GET_ALL_BRUTE_FORCE_DATA: 'GET_ALL_BRUTE_FORCE_DATA',
};

export const attachmentActionTypes = {
  GET_ALL_ATTACHMENT_DATA: 'GET_ALL_ATTACHMENT_DATA',
};

export const inventoryActionTypes = {
  GET_INVENTORY: 'GET_INVENTORY',
  CREATE_INVENTORY: 'CREATE_INVENTORY',
  EDIT_INVENTORY: 'EDIT_INVENTORY',
  DELETE_INVENTORY: 'DELETE_INVENTORY',
};