import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { whiteBlackListAdd } from '../../services/Api/auth'
import { QueryClient, useQuery } from "react-query";
import { useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
const DomainFilter = ({ data, setDomainFilterAction,setBasarili}) => {

    console.log("12321312ewc", data);
    const typeDomain = [
        {
            label: 'Blacklist', code: 'BL'
        },
        {
            label: 'Whitelist', code: 'WL'
        }
    ]
    const queryClient = new QueryClient();

    const [domainType, setDomainType] = useState(null);
    const [domainModel, setDomainModel] = useState(null)
    const [displaySender, setDisplaySender] = useState(false)

    useEffect(() => {
        if (data) {
            setDisplaySender(true)
        }

    }, [data])

    const { data: dataInsertDomain } = useQuery(['whiteBlackListAdd', domainModel], () => {
        if (domainModel) {
            whiteBlackListAdd(domainModel)
            setDomainFilterAction(false)
            setBasarili(true)

        }
    })




    const addDomain = () => {
        if (data && domainType) {
            const model = {
                domain: data,
                status: domainType
            }
            setDomainModel(model)
            queryClient.invalidateQueries(['whiteBlackListAdd', model]);
            setDomainType(null)
            setDisplaySender(false)
        }
    }

    const onHideSender = (e) => {
        setDisplaySender(false)
        setDomainFilterAction(false)
    }

    return (
        <>
            <Dialog
                header="Domain Filter"
                visible={displaySender}
                style={{ width: '50vw', height: "20vw" }}
                onHide={() => onHideSender()}
                modal
                dismissableMask
            >
                <div className="flex justify-content-center  mt-2">
                    <div className="col-12 md:col-5">
                        <i className="block ml-1 mb-1" style={{ fontSize: "14px", fontFamily: "sans-serif", fontStyle: "normal" }}>Domain  </i>
                        <div className="p-inputgroup">
                            <InputText
                                value={data}
                                disabled
                            />

                        </div>
                    </div>
                    <div className="col-12 md:col-5">
                        <i className="block ml-1 mb-1" style={{ fontSize: "14px", fontFamily: "sans-serif", fontStyle: "normal" }}>Domain Type <i style={{ color: "red" }}>*</i> </i>
                        <div className="p-inputgroup">
                            <Dropdown
                                value={domainType}
                                options={typeDomain}
                                onChange={(e) => setDomainType(e.target.value)}
                                optionLabel="label"
                                optionValue='code'
                                placeholder="Select Type"
                                className={!domainType ? 'p-invalid' : ''} />

                        </div>
                    </div>
                    <div className="col-12 md:col-2">
                        <i className='block'>&zwnj;</i>
                        <div className="p-inputgroup ">
                            <Button
                                className='mt-1 p-button-m p-button-success p-button-outlined'
                                label='Save'
                                disabled={!domainType}
                                icon="pi pi-save"
                                onClick={addDomain}></Button>

                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default DomainFilter